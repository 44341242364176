import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { QuestionSubSection } from '@app/modules/test/models/note/question-sub-section.model';
import { INoteQuestionData } from '@app/modules/test/types';

/**
 * Class represent one question, which is used in the question section of the test notes.
 */
export abstract class Question {
  protected constructor(
    public id: number,
    public uuid: string,
    public title: string,
    public visibleTitle: boolean,
    public imageUrl: string | null,
    public order: number,
    public questionSubSection: QuestionSubSection
  ) {}

  /**
   * Return question object serialized to basic javascript types.
   */
  public abstract serialize(): INoteQuestionData;

  /**
   * Clear question data, i.e. it removes all results/data from question.
   */
  public abstract clearData(): void;

  /**
   * Restore all question data, i.e. it sets all results/data from data storage into question.
   */
  public abstract restoreData(dataStorage: DataStorage): void;

  /**
   * Save all question data into data storage.
   */
  public abstract storeData(dataStorage: DataStorage): void;
}
