import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { TaskItem } from '@app/modules/test/models/task/task-item.model';
import { Test } from '@app/modules/test/models/test/test.model';
import { TestModule } from '@app/modules/test/models/test/test-module.model';
import { TestPassage } from '@app/modules/test/models/test/test-passage.model';
import { ITaskModuleData } from '@app/modules/test/types';

export class TaskTestModule extends TestModule {
  public items: Array<TaskItem> = [];

  /**
   * Return obtained score of all tasks.
   */
  public get score(): number {
    return this.items.reduce((acc, item) => acc + item.score, 0);
  }

  /**
   * Return maximum possible score of all tasks.
   */
  public get maxScore(): number {
    return this.items.reduce((acc, item) => acc + item.maxScore, 0);
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param test
   */
  public static deserialize(data: ITaskModuleData, test: Test): TaskTestModule {
    return new TaskTestModule(data.id, data.uuid, test, data.order);
  }

  /**
   * Return task test module object serialized to basic javascript types.
   */
  public serialize(): ITaskModuleData {
    return {
      id: this.id,
      uuid: this.uuid,
      order: this.order,
      module_type: 'task',
      test_items: this.items.map((i) => i.serialize())
    };
  }

  /**
   * Add task item into task module.
   *
   * @param item
   */
  public addItem(item: TaskItem): void {
    this.items.push(item);
  }

  /**
   * Return task item by index.
   *
   * @param index
   */
  public getItemByIndex(index: number): TaskItem | null {
    const item = this.items[index];
    return item === undefined ? null : item;
  }

  /**
   * Return task item by id.
   *
   * @param id
   */
  public getItemById(id: number): TaskItem | null {
    const item = this.items.find((i) => i.id === id);
    return item === undefined ? null : item;
  }

  /**
   * Register task items.
   *
   * @param testPassage
   */
  public registerTestSteps(testPassage: TestPassage): void {
    for (const item of this.items) {
      item.registerTestSteps(testPassage);
    }
  }

  /**
   * Clear module data, ie. it removes all results/data from module.
   */
  public clearData(): void {
    for (const taskItem of this.items) {
      taskItem.clearData();
    }
  }

  /**
   * Restore all module data, ie. it sets all results/data from data storage into module.
   */
  public restoreData(dataStorage: DataStorage): void {
    for (const taskItem of this.items) {
      taskItem.restoreData(dataStorage);
    }
  }

  /**
   * Save all module data (results, notes, ...) into data storage.
   */
  public storeData(dataStorage: DataStorage): void {
    for (const taskItem of this.items) {
      taskItem.storeData(dataStorage);
    }
  }
}
