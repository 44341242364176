import { Child } from '@app/modules/children/models/child.model';
import { DataSetStatus } from '@app/modules/test/enums/data-set-status.enum';
import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { Test } from '@app/modules/test/models/test/test.model';
import { IDataSetData } from '@app/modules/test/types';
import { DateHelper } from 'isophi-core';

/**
 * Class to represent data (data set) from test.
 *
 * It can be data from completed or uncompleted test.
 */
export class DataSet {
  public test: Test | null = null;

  public child: Child | null = null;

  public synced = false;

  public constructor(
    public id: number | null,
    public uuid: string,
    public testId: number,
    public startedAt: Date,
    public finishedAt: Date,
    public teacherId: number,
    public childId: number,
    public childUuid: string,
    public status: DataSetStatus,
    public dataStorage: DataStorage
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param dataStorage
   */
  public static deserialize(data: IDataSetData, dataStorage: DataStorage): DataSet {
    return new DataSet(
      data.id,
      data.uuid,
      data.test_id,
      DateHelper.parse(data.started_at),
      DateHelper.parse(data.finished_at),
      data.teacher_id,
      data.child_id,
      data.child_uuid,
      data.status as DataSetStatus,
      dataStorage
    );
  }

  /**
   * Serialize typescript object into basic javascript types.
   */
  public serialize(): IDataSetData {
    return {
      id: this.id,
      uuid: this.uuid,
      test_id: this.testId,
      started_at: this.startedAt.toISOString(),
      finished_at: this.finishedAt.toISOString(),
      teacher_id: this.teacherId,
      child_id: this.childId,
      child_uuid: this.childUuid,
      status: this.status as DataSetStatus,
      items: this.dataStorage.serialize(),
      synced: this.synced
    };
  }

  /**
   * It updates data set data with data of other data set.
   *
   * @param other
   */
  public updateData(other: DataSet): void {
    this.id = other.id;
    this.uuid = other.uuid;
    this.testId = other.testId;
    this.startedAt = other.startedAt;
    this.finishedAt = other.finishedAt;
    this.teacherId = other.teacherId;
    this.childId = other.childId;
    this.childUuid = other.childUuid;
    this.status = other.status;
    this.dataStorage = other.dataStorage;

    this.child = other.child;
    this.test = other.test;
    this.synced = other.synced;
  }

  /**
   * @return True/False if data are already synced to server
   */
  public isSyncedToServer(): boolean {
    return this.id !== null;
  }
}
