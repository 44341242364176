import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@app/core/services/auth-guard.service';
import { ForcedRestartGuardService } from '@app/core/services/forced-restart-guard.service';

import { BrowserTabGuardService } from './core/services/browser-tab-guard.service';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [BrowserTabGuardService],
    loadChildren: () => import('@app/modules/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'test',
    canActivate: [AuthGuardService, ForcedRestartGuardService, BrowserTabGuardService],
    loadChildren: () => import('@app/modules/test/test.module').then((m) => m.TestModule)
  },
  {
    path: 'test/:mode/:uuid/:childUuid/:step',
    canActivate: [AuthGuardService, ForcedRestartGuardService, BrowserTabGuardService],
    loadChildren: () => import('@app/modules/test/test.module').then((m) => m.TestModule)
  },
  {
    path: 'help',
    canActivate: [AuthGuardService, ForcedRestartGuardService, BrowserTabGuardService],
    loadChildren: () => import('@app/modules/help/help.module').then((m) => m.HelpModule)
  },
  {
    path: 'children',
    canActivate: [AuthGuardService, ForcedRestartGuardService, BrowserTabGuardService],
    loadChildren: () => import('@app/modules/children/children.module').then((m) => m.ChildrenModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService, ForcedRestartGuardService, BrowserTabGuardService],
    loadChildren: () => import('@app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: 'application',
    canActivate: [AuthGuardService, ForcedRestartGuardService, BrowserTabGuardService],
    loadChildren: () => import('@app/modules/application/application.module').then((m) => m.ApplicationModule)
  },
  {
    path: 'teacher',
    canActivate: [AuthGuardService, ForcedRestartGuardService, BrowserTabGuardService],
    loadChildren: () => import('@app/modules/teacher/teacher.module').then((m) => m.TeacherModule)
  },
  {
    path: 'file',
    canActivate: [AuthGuardService, ForcedRestartGuardService, BrowserTabGuardService],
    loadChildren: () => import('@app/modules/file/file.module').then((m) => m.FileModule)
  },
  {
    path: 'kindergarten',
    canActivate: [AuthGuardService, ForcedRestartGuardService, BrowserTabGuardService],
    loadChildren: () => import('@app/modules/kindergarten/kindergarten.module').then((m) => m.KindergartenModule)
  },
  {
    path: 'sync',
    loadChildren: () => import('@app/modules/sync/sync.module').then((m) => m.SyncModule)
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  // imports: [ RouterModule.forRoot(routes,   {enableTracing: true}) ],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
