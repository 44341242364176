<ng-container *transloco="let t">
  <div class="modal-header">
    <h5 class="modal-title">{{ modalHeadline }}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">
        <img src="assets/img/closeSmallCropped.svg" [alt]="t('shared/close')" />
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="alert alert-primary" role="alert">
      {{ t('login/deviceNotConnected') }}
    </div>

    <form #passwordForm="ngForm">
      <div class="form-group">
        <label for="usernameInput">{{ t('login/yourEmail') }}</label>
        <input type="text" class="form-control" name="username" id="usernameInput" [(ngModel)]="username" readonly />
      </div>
      <div class="form-group">
        <label for="passwordInput">{{ t('login/yourPassword') }}</label>
        <input type="password" class="form-control" name="password" id="passwordInput" [(ngModel)]="password" required ngbAutofocus />
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="submit" class="ibtn ibtn-primary ibtn-small" (click)="authenticate()" [disabled]="!passwordForm.valid">
      {{ t('login/authenticate') }}
    </button>
  </div>
</ng-container>
