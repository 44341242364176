import { NoteQuestionType } from '@app/modules/test/enums/note-question-type.enum';
import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { Question } from '@app/modules/test/models/note/question.model';
import { QuestionSubSection } from '@app/modules/test/models/note/question-sub-section.model';
import { SelectOption } from '@app/modules/test/models/note/select-option.model';
import { INoteQuestionData } from '@app/modules/test/types';

/**
 * Class represent one select question (select input) in the test notes.
 */
export class SelectQuestion extends Question {
  public static readonly DATA_TYPE = 'note$option_question';

  public options: Array<SelectOption> = [];

  public constructor(
    public id: number,
    public uuid: string,
    public title: string,
    public visibleTitle: boolean,
    public imageUrl: string | null,
    public order: number,
    public questionSubSection: QuestionSubSection,
    public value: number | null = null
  ) {
    super(id, uuid, title, visibleTitle, imageUrl, order, questionSubSection);
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param questionSubSection
   */
  public static deserialize(data: INoteQuestionData, questionSubSection: QuestionSubSection): SelectQuestion {
    return new SelectQuestion(data.id, data.uuid, data.title, data.visible_title, data.image_url, data.order, questionSubSection);
  }

  /**
   * Return select question object serialized to basic javascript types.
   */
  public serialize(): INoteQuestionData {
    return {
      id: this.id,
      uuid: this.uuid,
      title: this.title,
      visible_title: this.visibleTitle,
      image_url: this.imageUrl,
      order: this.order,
      question_type: NoteQuestionType.SELECT,
      options: this.options.map((o) => o.serialize())
    };
  }

  /**
   * Add option into select question.
   *
   * @param option
   */
  public addOption(option: SelectOption): void {
    this.options.push(option);
  }

  /**
   * Clear question data, i.e. it removes all results/data from question.
   */
  public clearData(): void {
    this.value = null;
  }

  /**
   * Restore all question data, i.e. it sets all results/data from data storage into question.
   */
  public restoreData(dataStorage: DataStorage): void {
    this.value = dataStorage.getDataValue(this.uuid) as number | null;
  }

  /**
   * Save all question data into data storage.
   */
  public storeData(dataStorage: DataStorage): void {
    // it is unnecessary to save null into storage.
    if (this.value !== null) dataStorage.setData(SelectQuestion.DATA_TYPE, this.uuid, this.value);
  }
}
