import { NoteQuestionType } from '@app/modules/test/enums/note-question-type.enum';
import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { Question } from '@app/modules/test/models/note/question.model';
import { QuestionSubSection } from '@app/modules/test/models/note/question-sub-section.model';
import { INoteQuestionData } from '@app/modules/test/types';

/**
 * Class represent one bool question (checkbox) in the test notes.
 */
export class BoolQuestion extends Question {
  public static readonly DATA_TYPE = 'note$bool_question';

  public constructor(
    public id: number,
    public uuid: string,
    public title: string,
    public visibleTitle: boolean,
    public imageUrl: string | null,
    public order: number,
    public questionSubSection: QuestionSubSection,
    public value: boolean = false
  ) {
    super(id, uuid, title, visibleTitle, imageUrl, order, questionSubSection);
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param questionSubSection
   */
  public static deserialize(data: INoteQuestionData, questionSubSection: QuestionSubSection): BoolQuestion {
    return new BoolQuestion(data.id, data.uuid, data.title, data.visible_title, data.image_url, data.order, questionSubSection);
  }

  /**
   * Return bool question object serialized to basic javascript types.
   */
  public serialize(): INoteQuestionData {
    return {
      id: this.id,
      uuid: this.uuid,
      title: this.title,
      visible_title: this.visibleTitle,
      image_url: this.imageUrl,
      order: this.order,
      question_type: NoteQuestionType.BOOL
    };
  }

  /**
   * Clear question data, i.e. it removes all results/data from question.
   */
  public clearData(): void {
    this.value = false;
  }

  /**
   * Restore all question data, i.e. it sets all results/data from data storage into question.
   */
  public restoreData(dataStorage: DataStorage): void {
    const storageValue = dataStorage.getDataValue(this.uuid);
    this.value = storageValue === null || storageValue === false ? false : true;
  }

  /**
   * Save all question data into data storage.
   */
  public storeData(dataStorage: DataStorage): void {
    // it is unnecessary to save false into storage.
    if (this.value === true) dataStorage.setData(BoolQuestion.DATA_TYPE, this.uuid, this.value);
  }
}
