import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { Group } from '@app/modules/children/models/group.model';
import { IChildrenInGroupData } from '@app/modules/children/types';

@Injectable({
  providedIn: 'root'
})
export class ChildrenInGroupDeserializer {
  /**
   * Deserialize data and associate children groups with children.
   *
   * @param app
   * @param childrenInGroupData
   */
  public deserialize(app: Application, childrenInGroupData: IChildrenInGroupData): Group | undefined {
    const group = app.getChildrenGroup(childrenInGroupData.group_uuid);
    if (group === undefined) return;

    // clear old data
    group.children.forEach((child) => child.removeGroup(group));
    group.children.length = 0;

    let childrenUuids = [];
    if (Object.prototype.hasOwnProperty.call(childrenInGroupData, 'children_uuids')) {
      childrenUuids = childrenInGroupData.children_uuids;
    }

    // @deprecated since version 0.9.34; use new field children_uuids
    if (Object.prototype.hasOwnProperty.call(childrenInGroupData, 'children')) {
      childrenUuids = childrenInGroupData.children;
    }

    // Apply new data
    for (const childUuid of childrenUuids) {
      const child = app.getChild(childUuid);
      if (child) {
        group.addChild(child);
        child.addGroup(group);
      }
    }

    const hasSync = Object.prototype.hasOwnProperty.call(childrenInGroupData, 'synced');
    group.childrenSynced = hasSync ? childrenInGroupData.synced : true;
    return group;
  }
}
