import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { DataItemValueConverter } from '@app/modules/test/converters/data-item-value.converter';
import { DataSetStatus } from '@app/modules/test/enums/data-set-status.enum';
import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { DataSet } from '@app/modules/test/models/test/data-set.model';
import { IDataSetData } from '@app/modules/test/types';

@Injectable({
  providedIn: 'root'
})
export class DataSetDeserializer {
  /**
   * Deserializer data into DataSet class.
   *
   * It also associate data set with child.
   *
   * @param app
   * @param data
   */
  public deserialize(app: Application, data: IDataSetData): DataSet {
    const dataItemConverter = new DataItemValueConverter();

    const dataStorage: DataStorage = new DataStorage();
    for (const itemData of data.items) {
      dataStorage.setData(itemData.data_type, itemData.source_uuid, dataItemConverter.toValue(itemData.data_type, itemData.value));
    }

    const dataSet = DataSet.deserialize(data, dataStorage);
    dataSet.synced = Object.prototype.hasOwnProperty.call(data, 'synced') ? data.synced : true;
    dataSet.test = app.getTest(data.test_id);

    const child = app.getChild(data.child_uuid);
    if (child && dataSet.status !== DataSetStatus.ARCHIVED) {
      child.addDataSet(dataSet);
      dataSet.child = child;
    }

    return dataSet;
  }
}
