import { ITestTaskInstructionData } from '@app/modules/test/types';

export abstract class Instruction {
  protected constructor(public id: number) {}

  /**
   * Return instruction object serialized to basic javascript types.
   */
  public abstract serialize(): ITestTaskInstructionData;
}
