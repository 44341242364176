import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { ChildrenGroupDeserializer } from '@app/modules/children/deserializers/children-group.deserializer';
import { ChildrenInGroupDeserializer } from '@app/modules/children/deserializers/children-in-group.deserializer';
import { Child } from '@app/modules/children/models/child.model';
import { Group } from '@app/modules/children/models/group.model';
import { IChildrenInGroupData, IGroupData } from '@app/modules/children/types';
import { IAppDownloadData, IBasicResponse, IMaterialData, IUuidResponse } from '@app/modules/sync/types';
import { CategoryDeserializer } from '@app/modules/test/deserializers/category-deserializer';
import { DataSetDeserializer } from '@app/modules/test/deserializers/data-set-deserializer';
import { MaterialDeserializer } from '@app/modules/test/deserializers/material-deserializer';
import { TestDeserializer } from '@app/modules/test/deserializers/test-deserializer';
import { IDataSetData, ITestAllData } from '@app/modules/test/types';
import {
  ICategoryData,
  IChildData,
  IKindergartenData,
  IUserData,
  Kindergarten,
  LicenseService,
  SubjectLicensesContract,
  User
} from 'isophi-core';

@Injectable({
  providedIn: 'root'
})
export class DownloadWorkerService {
  public constructor(
    private categoryDeserializer: CategoryDeserializer,
    private materialDeserializer: MaterialDeserializer,
    private testDeserializer: TestDeserializer,
    private dataSetDeserializer: DataSetDeserializer,
    private childrenGroupDeserializer: ChildrenGroupDeserializer,
    private childrenInGroupDeserializer: ChildrenInGroupDeserializer,
    private licenseService: LicenseService
  ) {}

  /**
   * Process teachers data.
   *
   * Refactored to convert old 'teachers' data to 'users' data.
   *
   * @deprecated 0.9.34
   * @param app
   * @param data
   */
  public processTeachersData(app: Application, data: any): void {
    if (!this.isValid(data)) return;

    for (const teacherData of data.results) {
      const userData = teacherData.user;
      let isTeacherActive = teacherData.is_active;
      if (typeof isTeacherActive === 'undefined') {
        isTeacherActive = teacherData.active;
        if (typeof isTeacherActive === 'undefined') {
          isTeacherActive = false;
        }
      }
      userData.teacher = {
        id: teacherData.id,
        is_active: isTeacherActive,
        groups: teacherData.groups
      };
      const user = User.deserialize(userData);
      app.updateUser(user);
    }
  }

  /**
   * Process users data.
   *
   * @param app
   * @param usersData
   * @param teachersData
   */
  public processUsersData(app: Application, usersData: IBasicResponse<IUserData>, teachersData: any = undefined): void {
    if (!this.isValid(usersData)) {
      if (!this.isValid(teachersData)) {
        return;
      }
      // @deprecated 0.9.34
      this.processTeachersData(app, teachersData);
      return;
    }

    for (const userData of usersData.results) {
      const user = User.deserialize(userData);
      app.updateUser(user);
    }
  }

  /**
   * Process children data.
   *
   * @param app
   * @param data
   */
  public processChildrenData(app: Application, data: IBasicResponse<IChildData>): void {
    if (!this.isValid(data)) return;

    for (const childData of data.results) {
      const child = Child.deserialize(childData);
      child.synced = Object.prototype.hasOwnProperty.call(childData, 'synced') ? childData.synced : true;
      app.updateChild(child);
    }
    app.childChanged(false);
  }

  /**
   * Process children groups data.
   *
   * @param app
   * @param data
   */
  public processChildrenGroupsData(app: Application, data: IBasicResponse<IGroupData>): void {
    if (!this.isValid(data)) return;

    for (const childrenGroupData of data.results) {
      const group = this.childrenGroupDeserializer.deserialize(app, childrenGroupData);
      app.updateChildrenGroup(group);
    }
  }

  /**
   * Process children groups data.
   *
   * @param app
   * @param data
   */
  public processChildrenInGroupsData(app: Application, data: IBasicResponse<IChildrenInGroupData>): void {
    if (!this.isValid(data)) return;

    for (const childrenInGroupData of data.results) {
      const group = this.childrenInGroupDeserializer.deserialize(app, childrenInGroupData);
      if (group) app.updateChildrenGroup(group);
    }
  }

  /**
   * Process data sets.
   *
   * @param app
   * @param data
   */
  public processDataSetsData(app: Application, data: IBasicResponse<IDataSetData>): void {
    if (!this.isValid(data)) return;

    for (const dataSetData of data.results) {
      const dataSet = this.dataSetDeserializer.deserialize(app, dataSetData);
      app.updateDataSet(dataSet);
    }
  }

  /**
   * Process data sets archive.
   *
   * @param app
   * @param data
   */
  public processDataSetsArchiveData(app: Application, data: IBasicResponse<IDataSetData>): void {
    if (!this.isValid(data)) return;

    for (const dataSetData of data.results) {
      const dataSet = this.dataSetDeserializer.deserialize(app, dataSetData);
      app.dataSetArchive.push(dataSet);
    }
  }

  /**
   * Process child archive.
   *
   * @param app
   * @param data
   */
  public processChildArchiveData(app: Application, data: IBasicResponse<IChildData>): void {
    if (!this.isValid(data)) return;

    for (const childData of data.results) {
      const child = Child.deserialize(childData);
      app.childArchiveList.push(child);
    }
  }

  /**
   * Process children group archive.
   *
   * @param app
   * @param data
   */
  public processChildrenGroupArchiveData(app: Application, data: IBasicResponse<IGroupData>): void {
    if (!this.isValid(data)) return;

    for (const groupData of data.results) {
      const group = Group.deserialize(groupData);
      app.childrenGroupArchive.push(group);
    }
  }

  /**
   * Process tests data.
   *
   * @param app
   * @param data
   */
  public processTestsData(app: Application, data: IBasicResponse<ITestAllData>): void {
    if (!this.isValid(data)) return;

    for (const testData of data.results) {
      const test = this.testDeserializer.deserialize(app, testData);
      app.updateTest(test);
    }
  }

  /**
   * Process materials data.
   *
   * @param app
   * @param data
   */
  public processMaterialsData(app: Application, data: IBasicResponse<IMaterialData>): void {
    if (!this.isValid(data)) return;

    for (const materialData of data.results) {
      const material = this.materialDeserializer.deserialize(app, materialData);
      app.updateMaterial(material);
    }
  }

  /**
   * Process files data.
   *
   * @param app
   * @param data
   */
  public processFilesData(app: Application, data: IBasicResponse<string>): void {
    if (!this.isValid(data)) return;

    for (const file of data.results) {
      // todo(doubravskytomas): Download file into app.
      //   Create some mapping between URL and downloaded file.
      app.fileList.add(file);
    }
  }

  public processCategoriesData(app: Application, data: IBasicResponse<ICategoryData>): void {
    if (!this.isValid(data)) return;

    for (const categoryData of data.results) {
      const category = this.categoryDeserializer.deserialize(categoryData);
      app.updateCategory(category);
    }
  }

  /**
   * Process licenses - set them into app and license service.
   *
   * @param app
   * @param data
   */
  public processLicensesData(app: Application, data: SubjectLicensesContract): void {
    if (!data) return;

    app.licensesData = data;
    this.licenseService.data = data;
  }

  /**
   * Process data of logged kindergarten.
   *
   * @param app
   * @param data
   */
  public processLoggedKindergartenData(app: Application, data: IKindergartenData): void {
    if (!data) return;

    app.loggedKindergarten = Kindergarten.deserialize(data);
  }

  /**
   * Process data of logged user.
   *
   * @param app
   * @param data
   */
  public processLoggedUserData(app: Application, data: IUserData): void {
    if (!data) return;

    app.loggedUser = User.deserialize(data);
  }

  /**
   * Process data of deleted children.
   *
   * @param app
   * @param data
   */
  public processDeletedChildrenData(app: Application, data: IUuidResponse): void {
    if (!data) return;

    for (const child of data.results) {
      app.removeChild(child.uuid, true);
    }
  }

  /**
   * Process data of archived children.
   *
   * @param app
   * @param data
   */
  public processArchivedChildrenData(app: Application, data: IUuidResponse): void {
    if (!data) return;

    for (const child of data.results) {
      app.removeChild(child.uuid, true, true);
    }
  }

  /**
   * Process data of deleted children groups.
   *
   * @param app
   * @param data
   */
  public processDeletedGroupsData(app: Application, data: IUuidResponse): void {
    if (!data) return;

    for (const group of data.results) {
      app.removeChildrenGroup(group.uuid, true);
    }
  }

  /**
   * Process data of deleted data sets.
   *
   * @param app
   * @param data
   */
  public processDeletedDataSetsData(app: Application, data: IUuidResponse): void {
    if (!data) return;

    for (const dataSet of data.results) {
      app.removeDataSet(dataSet.uuid, true);
    }
  }

  /**
   * Process data of deleted tests.
   *
   * @param app
   * @param data
   */
  public processDeletedTestsData(app: Application, data: IUuidResponse): void {
    if (!data) return;

    // not supported yet
  }

  /**
   * Process pack of all data.
   *
   * @param app
   * @param data
   */
  public processAllData(app: Application, data: IAppDownloadData): void {
    this.processLicensesData(app, data.licenses);
    this.processCategoriesData(app, data.categories);
    this.processMaterialsData(app, data.materials);
    this.processTestsData(app, data.tests);
    this.processUsersData(app, data.users, data.teachers);
    this.processFilesData(app, data.files);
    this.processChildrenData(app, data.children);
    this.processDataSetsData(app, data.data_sets);
    this.processChildrenGroupsData(app, data.children_groups);
    this.processChildrenInGroupsData(app, data.children_in_groups);
    this.processLoggedKindergartenData(app, data.logged_kindergarten);
    this.processLoggedUserData(app, data.logged_user);
    this.processDeletedChildrenData(app, data.deleted_children);
    this.processArchivedChildrenData(app, data.archived_children);
    this.processDeletedGroupsData(app, data.deleted_groups);
    this.processDeletedDataSetsData(app, data.deleted_data_sets);
    this.processDeletedTestsData(app, data.deleted_tests);
  }

  /**
   * Check if data are valid.
   *
   * @param data
   * @protected
   */
  protected isValid(data: IBasicResponse<unknown>): boolean {
    return typeof data !== 'undefined' && Object.prototype.hasOwnProperty.call(data, 'results');
  }
}
