import { Injectable } from '@angular/core';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { ApplicationRepository } from '@app/modules/application/services/application-repository.service';
import { StorageFactory } from '@app/modules/application/services/storage-factory.service';
import { Logger } from 'isophi-core';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  public constructor(
    protected applicationService: ApplicationService,
    protected appRepository: ApplicationRepository,
    protected storageFactory: StorageFactory
  ) {}

  /**
   * It will logout kindergarten.
   *
   * It will REMOVE all data associated with kindergarten.
   * Next login must be done online with kindergarten sync.
   */
  public kindergartenLogout(): void {
    const app = this.applicationService.getApplication();
    const storage = this.storageFactory.getStorage();

    if (app) {
      app.clear();
      Logger.debug('LogoutService :: App cleared.');
    }

    if (storage) {
      storage.clear();
      Logger.debug('LogoutService :: Storage cleared.');
    }

    Logger.debug('LogoutService :: Kindergarten logged out.');
  }

  /**
   * It will logout user.
   *
   * It will KEEP all data associated with kindergarten.
   * Next login can be done offline and without sync.
   */
  public userLogout(): void {
    const app = this.applicationService.getApplication();
    if (app) {
      app.clearLoggedUser();
      app.accessToken = null;
      this.appRepository.persistApp(app);
      Logger.debug('LogoutService :: User logged out.');
    }
  }
}
