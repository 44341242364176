import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { Question } from '@app/modules/test/models/note/question.model';
import { QuestionNoteSection } from '@app/modules/test/models/note/question-note-section.model';
import { INoteSubSectionData } from '@app/modules/test/types';

/**
 * Class represent one sub section of question section in the test notes.
 */
export class QuestionSubSection {
  public questions: Array<Question> = [];

  public constructor(
    public id: number,
    public uuid: string,
    public title: string,
    public order: number,
    public questionNoteSection: QuestionNoteSection,
    public infoBlock: string | null
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param questionNoteSection
   */
  public static deserialize(data: INoteSubSectionData, questionNoteSection: QuestionNoteSection): QuestionSubSection {
    return new QuestionSubSection(data.id, data.uuid, data.title, data.order, questionNoteSection, data.info_block);
  }

  /**
   * Return test module object serialized to basic javascript types.
   */
  public serialize(): INoteSubSectionData {
    return {
      id: this.id,
      uuid: this.uuid,
      title: this.title,
      order: this.order,
      questions: this.questions.map((q) => q.serialize()),
      info_block: this.infoBlock
    };
  }

  /**
   * Insert question into question sub section.
   *
   * @param question
   */
  public addQuestion(question: Question): void {
    this.questions.push(question);
  }

  /**
   * Clear sub section data, i.e. it removes all results/data from sub section.
   */
  public clearData(): void {
    for (const question of this.questions) {
      question.clearData();
    }
  }

  /**
   * Restore all sub section data, i.e. it sets all results/data from data storage into sub section.
   */
  public restoreData(dataStorage: DataStorage): void {
    for (const question of this.questions) {
      question.restoreData(dataStorage);
    }
  }

  /**
   * Save all sub section data into data storage.
   */
  public storeData(dataStorage: DataStorage): void {
    for (const question of this.questions) {
      question.storeData(dataStorage);
    }
  }
}
