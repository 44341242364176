import { TestResult } from '@app/modules/test/models/test-result/test-result.model';

import { BasicEvaluationCategoryRule } from './basic/basic-evaluation-category-rule.model';
import { BasicEvaluationRule } from './basic/basic-evaluation-rule.model';

export abstract class TestEvaluation {
  public categoryRules: Map<number, BasicEvaluationCategoryRule>;

  // For using in result page
  public generalRule: BasicEvaluationRule | null;

  /**
   * It gets testResult with all scores in the test and evaluate the test.
   *
   * @param testResult
   */
  public abstract evaluate(testResult: TestResult): void;

  /**
   * Return user object serialized to basic javascript types.
   */
  public abstract serialize(): any;
}
