import { Injectable } from '@angular/core';
import { Category } from '@app/modules/test/models/general/category.model';
import { ICategoryData } from 'isophi-core';

@Injectable({
  providedIn: 'root'
})
export class CategoryDeserializer {
  /**
   * Deserializer data into Category class.
   *
   * @param data
   */
  public deserialize(data: ICategoryData): Category {
    return Category.deserialize(data);
  }
}
