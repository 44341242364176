/**
 * A list of navigation actions of a test
 */
export enum NavigationActions {
  REVIEW = 'review',
  PREVIEW = 'preview',
  EDIT = 'edit',
  START = 'start',
  CONTINUE = 'continue'
}
