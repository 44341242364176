/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AutoSyncService {
  /**
   * Path to redirect after auto sync.
   * If value is null, it uses defaultDestinationPath.
   */
  protected _destinationPath: string | null = null;

  /**
   * Return destination path after auto sync.
   */
  public get destinationPath(): string {
    return this._destinationPath === null ? this.defaultDestinationPath : this._destinationPath;
  }

  /**
   * Set destination path after auto sync.
   *
   * @param value
   */
  public set destinationPath(value: string | null) {
    this._destinationPath = value;
  }

  /**
   * Default destination path after auto sync.
   * This value is used when this.destinationPath is null.
   */
  public get defaultDestinationPath(): string {
    return '/dashboard';
  }
}
