import { Child } from '@app/modules/children/models/child.model';
import { IGroupData } from '@app/modules/children/types';

/**
 * Class to represent children group.
 */
export class Group {
  public children: Array<Child> = [];

  /**
   * Use for group data.
   */
  public synced = false;

  /**
   * Use when changed children in group.
   */
  public childrenSynced = false;

  public constructor(public id: number | null, public uuid: string, public name: string, public revision: number = 0) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: IGroupData): Group {
    const revision = Object.prototype.hasOwnProperty.call(data, 'revision') ? data.revision : 0;
    return new Group(data.id, data.uuid, data.name, revision);
  }

  /**
   * Serialize typescript object into basic javascript types.
   */
  public serialize(): IGroupData {
    return {
      id: this.id,
      uuid: this.uuid,
      name: this.name,
      revision: this.revision,
      synced: this.synced
    };
  }

  /**
   * Add new children into group.
   *
   * @param child
   */
  public addChild(child: Child): void {
    const index = this.children.findIndex((ch) => ch.uuid === child.uuid);
    if (index !== -1) return;

    this.children.push(child);
    this.childrenSynced = false;
  }

  /**
   * Remove child from group.
   *
   * @param child
   */
  public removeChild(child: Child): void {
    const index = this.children.findIndex((ch) => ch.uuid === child.uuid);
    if (index === -1) return;

    this.children.splice(index, 1);
    this.childrenSynced = false;
  }

  /**
   * Check if children is in group.
   *
   * @param child
   */
  public hasChild(child: Child): boolean {
    const index = this.children.findIndex((ch) => ch.uuid === child.uuid);
    return index !== -1;
  }

  /**
   * Set new ID to Group.
   *
   * @param id
   */
  public setId(id: number): void {
    this.id = id;
  }

  /**
   * Replace data from other children group to this one.
   *
   * If skipIdentity is true, it skip data identifying group, that is ID, UUID, synced, childrenSynced.
   *
   * @param otherChildrenGroup
   * @param skipIdentity
   */
  public replaceData(otherChildrenGroup: Group, skipIdentity: boolean = true): void {
    this.name = otherChildrenGroup.name;
    // this.children = otherChildrenGroup.children;

    if (!skipIdentity) {
      this.id = otherChildrenGroup.id;
      this.revision = otherChildrenGroup.revision;
      this.uuid = otherChildrenGroup.uuid;
      this.synced = otherChildrenGroup.synced;
      this.childrenSynced = otherChildrenGroup.childrenSynced;
    }
  }

  /**
   * @return True/False if data are already synced to server
   */
  public isSyncedToServer(): boolean {
    return this.id !== null;
  }
}
