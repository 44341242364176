import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { NoteSection } from '@app/modules/test/models/note/note-section.model';
import { Test } from '@app/modules/test/models/test/test.model';
import { TestModule } from '@app/modules/test/models/test/test-module.model';
import { TestPassage } from '@app/modules/test/models/test/test-passage.model';
import { INoteModuleData } from '@app/modules/test/types';

/**
 * Test module for test notes.
 */
export class NoteTestModule extends TestModule {
  public sections: Array<NoteSection> = [];

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param test
   */
  public static deserialize(data: INoteModuleData, test: Test): NoteTestModule {
    return new NoteTestModule(data.id, data.uuid, test, data.order);
  }

  /**
   * Return test module object serialized to basic javascript types.
   */
  public serialize(): INoteModuleData {
    return {
      id: this.id,
      uuid: this.uuid,
      order: this.order,
      module_type: 'note',
      test_sections: this.sections.map((s) => s.serialize())
    };
  }

  /**
   * Add note section into note module.
   *
   * @param section
   */
  public addSection(section: NoteSection): void {
    this.sections.push(section);
  }

  /**
   * Register task items.
   *
   * @param testPassage
   */
  public registerTestSteps(testPassage: TestPassage): void {
    for (const section of this.sections) {
      section.registerTestSteps(testPassage);
    }
  }

  /**
   * Clear note section data, ie. it removes all results/data from section.
   */
  public clearData(): void {
    for (const section of this.sections) {
      section.clearData();
    }
  }

  /**
   * Restore all section data, ie. it sets all results/data from data storage into section.
   */
  public restoreData(dataStorage: DataStorage): void {
    for (const section of this.sections) {
      section.restoreData(dataStorage);
    }
  }

  /**
   * Save all section data into data storage.
   */
  public storeData(dataStorage: DataStorage): void {
    for (const section of this.sections) {
      section.storeData(dataStorage);
    }
  }
}
