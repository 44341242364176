import { NoteSectionType } from '@app/modules/test/enums/note-section-type.enum';
import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { Category } from '@app/modules/test/models/general/category.model';
import { NoteSection } from '@app/modules/test/models/note/note-section.model';
import { QuestionSubSection } from '@app/modules/test/models/note/question-sub-section.model';
import { NoteTestModule } from '@app/modules/test/models/test/note-test-module.model';
import { TestPassage } from '@app/modules/test/models/test/test-passage.model';
import { TestStep } from '@app/modules/test/models/test/test-step.interface';
import { INoteSectionData, INoteSectionWrapperData } from '@app/modules/test/types';

/**
 * Class represent one section with questions in the test notes.
 */
export class QuestionNoteSection extends NoteSection implements TestStep {
  public subSections: Array<QuestionSubSection> = [];

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param noteTestModule
   * @param category
   * @param order
   * @param testSectionId
   */
  public static deserialize(
    data: INoteSectionData,
    noteTestModule: NoteTestModule,
    category: Category,
    order: number,
    testSectionId: number
  ): QuestionNoteSection {
    return new QuestionNoteSection(data.id, testSectionId, data.uuid, noteTestModule, data.title, category, order);
  }

  /**
   * Return test module object serialized to basic javascript types.
   */
  public serialize(): INoteSectionWrapperData {
    return {
      id: this.testSectionId,
      order: this.order,
      section: {
        id: this.id,
        uuid: this.uuid,
        title: this.title,
        category_id: this.category ? this.category.id : null,
        section_type: NoteSectionType.QUESTION,
        sub_sections: this.subSections.map((s) => s.serialize())
      }
    };
  }

  /**
   * Add question sub section into question note section.
   *
   * @param subSection
   */
  public addSubSection(subSection: QuestionSubSection): void {
    this.subSections.push(subSection);
  }

  /**
   * Register test steps.
   *
   * @param testPassage
   */
  public registerTestSteps(testPassage: TestPassage): void {
    testPassage.registerStep(this);
  }

  /**
   * Activate this note section.
   *
   * @param testPassage
   */
  public activateStep(testPassage: TestPassage): void {
    // disable active category, test note does not have category.
    testPassage.test.activeCategory = null;
  }

  /**
   * Return route to component displaying this note section.
   *
   * @return string
   */
  public getRoute(uuid: string, mode: string, childUuid: string, step: number): string {
    return `/test/${mode}/${uuid}/${childUuid}/${step}/question-note`;
  }

  /**
   * Return ID representing this step.
   *
   * @return string
   */
  public getStepId(): string {
    return 'test#question-note#' + this.id;
  }

  /**
   * Init this step.
   *
   * @param testPassage
   */
  public initStep(_testPassage: TestPassage): void {
    // nothing to do
  }

  /**
   * Return if this step is visible in test passage.
   *
   * @return boolean
   */
  public isDisplayable(): boolean {
    return true;
  }

  /**
   * Clear section data, i.e. it removes all results/data from section.
   */
  public clearData(): void {
    for (const subSection of this.subSections) {
      subSection.clearData();
    }
  }

  /**
   * Restore all section data, i.e. it sets all results/data from data storage into section.
   */
  public restoreData(dataStorage: DataStorage): void {
    for (const subSection of this.subSections) {
      subSection.restoreData(dataStorage);
    }
  }

  /**
   * Save all section data into data storage.
   */
  public storeData(dataStorage: DataStorage): void {
    for (const subSection of this.subSections) {
      subSection.storeData(dataStorage);
    }
  }
}
