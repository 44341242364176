/**
 * Call in serialize() method, when new field is added into model class.
 *
 * @param obj
 * @param field
 * @param defaultValue
 */
export const serializeNewField = (obj: any, field: string, defaultValue: string | boolean | number): string | boolean | number => {
  return field in obj ? obj[field] : defaultValue;
};

/**
 * Call in deserialize() method, when new field is added into model class.
 *
 * @param data
 * @param field
 * @param defaultValue
 */
export const deserializeNewField = (data: any, field: string, defaultValue: string | boolean | number): string | boolean | number => {
  return field in data ? data[field] : defaultValue;
};
