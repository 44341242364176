<ng-container *transloco="let t">
  <div class="row header-row" [ngStyle]="{ background: color }">
    <div class="header-info">
      <h1>{{ corner }}</h1>
    </div>
    <img src="{{ convertURL(icon) }}" alt="" class="header-icon ml-3" *ngIf="icon" />
    <h2 class="header-title ml-3 uppercase">
      <span class="uppercase">{{ title }}</span>
      <ng-container *ngIf="subtitle"> <span class="mx-4">-</span>{{ subtitle }} </ng-container>
    </h2>
    <div class="header-right" [ngStyle]="{ 'border-color': color }">
      <div *ngIf="testingChild" class="user">
        <img *ngIf="testingChild?.gender === 'female'" src="assets/img/iconsDefault/girl.svg" class="child-icon" alt="icon" />
        <img *ngIf="testingChild?.gender === 'male'" src="assets/img/iconsDefault/boy.svg" class="child-icon" alt="icon" />
        <h3>{{ testingChild?.fullName }}</h3>
      </div>
      <div class="info-text" *ngIf="secondTitle">
        <h3>{{ t(secondTitle) }}</h3>
      </div>
      <img *ngIf="menu" src="assets/img/iconsDefault/menu.svg" class="menu cursor-pointer mr-3" (click)="toggleSidenav()" alt="Menu" />
    </div>
  </div>
</ng-container>
