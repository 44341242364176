import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private _state = new BehaviorSubject<TemplateRef<any> | null>(null);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly contents = this._state.asObservable();

  /**
   * Set content for context menu.
   *
   * @param ref
   */
  public setContents(ref: TemplateRef<any>): void {
    this._state.next(ref);
  }

  /**
   * Clear context menu.
   */
  public clearContents(): void {
    this._state.next(null);
  }
}
