/**
 * Enum with all test modes.
 *
 * Modes:
 * - Testing: Teacher is testing a child and result of test is saved into completed tests in the child.
 * - Editing: Teacher is editing already completed test.
 *            If save changes, old result is deleted and new is created.
 *            If NOT save changes, old result is kept and edited data are deleted.
 * - Review: Teacher browses a child results and test data, teacher can not change data.
 * - Preview: Teacher browses a test before he is going to test a child. He can change test data
 *            and view any part of test, exactly as during a testing, but data are NOT saved.
 */
export enum TestMode {
  TESTING = 'testing',
  EDITING = 'editing',
  REVIEW = 'review',
  PREVIEW = 'preview'
}
