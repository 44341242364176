import { InstructionType } from '@app/modules/test/enums/instruction-type.enum';
import { ITestTaskInstructionData } from '@app/modules/test/types';

import { Instruction } from './instruction.model';

export class TextInstruction extends Instruction {
  public constructor(id: number, public text: string) {
    super(id);
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: ITestTaskInstructionData): TextInstruction {
    return new TextInstruction(data.id, data.text);
  }

  /**
   * Return instruction object serialized to basic javascript types.
   */
  public serialize(): ITestTaskInstructionData {
    return {
      id: this.id,
      text: this.text,
      instruction_type: InstructionType.TEXT
    };
  }
}
