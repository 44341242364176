import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LicenseDatesContract } from 'isophi-core';

@Component({
  selector: 'app-expire-license-alert',
  templateUrl: './expire-license-alert.component.html',
  styleUrls: ['./expire-license-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpireLicenseAlertComponent implements OnInit {
  @Input() licenses: Record<string, Record<string, LicenseDatesContract>>;

  daysToExpire: number;

  expirationDate: string;

  ngOnInit(): void {
    this.checkExpirationDate(this.licenses);
  }

  checkExpirationDate(licenses: Record<string, Record<string, LicenseDatesContract>>) {
    const daysToExpireTable = [];
    const expirationDateDic = {};

    for (const license in licenses) {
      if (license.includes('test')) {
        for (const subLicense in licenses[license]) {
          if (licenses[license][subLicense].valid_to !== null) {
            const validTo = new Date(licenses[license][subLicense].valid_to);
            const now = new Date();
            const daysToExpire = this.dateDiffInDays(validTo, now);

            daysToExpireTable.push(daysToExpire);
            expirationDateDic[daysToExpire] = validTo;
          }
        }
      }
    }

    this.daysToExpire = Math.min(...daysToExpireTable);
    this.expirationDate = expirationDateDic[this.daysToExpire];
  }

  dateDiffInDays(a: Date, b: Date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getUTCFullYear(), a.getUTCMonth(), a.getUTCDate());
    const utc2 = Date.UTC(b.getUTCFullYear(), b.getUTCMonth(), b.getUTCDate());

    return (utc1 - utc2) / _MS_PER_DAY;
  }
}
