import { Child } from '@app/modules/children/models/child.model';
import { TestMode } from '@app/modules/test/enums/test-mode.enum';
import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { Category } from '@app/modules/test/models/general/category.model';
import { Material } from '@app/modules/test/models/general/material.model';
import { TaskItem } from '@app/modules/test/models/task/task-item.model';
import { TaskTestModule } from '@app/modules/test/models/test/task-test-module.model';
import { TestModule } from '@app/modules/test/models/test/test-module.model';
import { TestPassage } from '@app/modules/test/models/test/test-passage.model';
import { TestEvaluation } from '@app/modules/test/models/test-evaluation/test-evaluation.model';
import { IAnyModuleData, ITestAllData, ITestData } from '@app/modules/test/types';

export class Test {
  public modules: TestModule[] = [];

  public taskModule: TaskTestModule = null;

  public running = false;

  public testPassage: TestPassage = null;

  public activeCategory: Category | null = null;

  public testingChild: Child = null;

  public mode: TestMode = null;

  /**
   * Number of steps during test filling.
   */
  public stepCount = 0;

  public constructor(
    public id: number,
    public uuid: string,
    public title: string,
    public shortTitle: string,
    public productCodename: string,
    public minAge: number,
    public maxAge: number,
    public age: number,
    public version: string,
    public etag: string,
    public color: string,
    public testEvaluation: TestEvaluation,
    public categories: Array<Category>,
    public materials: Array<Material>
  ) {}

  /**
   * Return array of categories used in task module.
   */
  public get taskCategories(): Category[] {
    return this.taskModule.items.map((item) => item.category).filter((item, index, arr) => arr.indexOf(item) === index);
  }

  /**
   * Return test min age in years.
   */
  public get minAgeYear(): number {
    return Math.floor(this.minAge / 365.24);
  }

  /**
   * Return test max age in years.
   */
  public get maxAgeYear(): number {
    return Math.floor(this.maxAge / 365.24);
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param testEvaluation
   * @param categories
   * @param materials
   */
  public static deserialize(
    data: ITestData,
    testEvaluation: TestEvaluation,
    categories: Array<Category>,
    materials: Array<Material>
  ): Test {
    const codename = data.product_codename === undefined ? 'INVALID_CODENAME' : data.product_codename;
    const color = data.color === undefined ? '#bae7e7' : data.color;
    let shortTitle = data.short_title;
    if (shortTitle === undefined) {
      const fromYears = Math.round(data.min_age / 366);
      const toYears = Math.round(data.max_age / 366);
      shortTitle = `D${fromYears}-${toYears}`;
    }

    return new Test(
      data.id,
      data.uuid,
      data.title,
      shortTitle,
      codename,
      data.min_age,
      data.max_age,
      data.age,
      data.version,
      data.etag,
      color,
      testEvaluation,
      categories,
      materials
    );
  }

  /**
   * Return user object serialized to basic javascript types.
   */
  public serialize(): ITestAllData {
    return {
      test: {
        id: this.id,
        uuid: this.uuid,
        title: this.title,
        short_title: this.shortTitle,
        product_codename: this.productCodename,
        min_age: this.minAge,
        max_age: this.maxAge,
        age: this.age,
        version: this.version,
        etag: this.etag,
        color: this.color,
        modules: this.modules.map((m) => m.serialize()) as IAnyModuleData[],
        test_evaluation: this.testEvaluation.serialize()
      },
      materials: this.materials.map((m) => m?.id),
      categories: this.categories.map((c) => c.id)
    };
  }

  /**
   * It updates test data with data of other test.
   *
   * @param other
   */
  public updateData(other: Test): void {
    this.id = other.id;
    this.uuid = other.uuid;
    this.title = other.title;
    this.productCodename = other.productCodename;
    this.minAge = other.minAge;
    this.maxAge = other.maxAge;
    this.age = other.age;
    this.version = other.version;
    this.etag = other.etag;
    this.testEvaluation = other.testEvaluation;
    this.categories = other.categories;

    this.modules = other.modules;
    this.taskModule = other.taskModule;
    this.running = other.running;
    this.testPassage = other.testPassage;
    this.activeCategory = other.activeCategory;
    this.testingChild = other.testingChild;
    this.mode = other.mode;
  }

  /**
   * Add test module into test.
   *
   * @param module TestModule
   */
  public addModule(module: TestModule): void {
    if (module instanceof TaskTestModule) {
      this.taskModule = module;
    }
    this.modules.push(module);
  }

  /**
   * Return test module by ID.
   *
   * @param id
   */
  public getModuleById(id: number): TestModule | null {
    const module = this.modules.find((m) => m.id === id);
    return module === undefined ? null : module;
  }

  /**
   * Return test task items by category.
   */
  public getTaskItemsByCategory(category: Category): Array<TaskItem> {
    return this.taskModule.items.filter((item) => item.category === category);
  }

  /**
   * Start new test, that is prepare test entity data for new test.
   */
  public startTest(mode: TestMode): void {
    this.activeCategory = this.taskCategories[0];
    this.testPassage = new TestPassage(this);
    this.registerTestSteps();
    this.running = true;
    this.mode = mode;
  }

  /**
   * Finish test. It clears whole test and all temporary values.
   */
  public finishTest(): void {
    this.running = false;
    this.testPassage = null;
    this.testingChild = null;
    this.mode = null;
  }

  /**
   * Clear whole test, ie. it removes all results/data from test.
   */
  public clearData(): void {
    for (const module of this.modules) {
      module.clearData();
    }
  }

  /**
   * Restore all test data, ie. it sets all results/data from data storage into test.
   */
  public restoreData(dataStorage: DataStorage): void {
    for (const module of this.modules) {
      module.restoreData(dataStorage);
    }
  }

  /**
   * Return all test data, ie. it returns all results/data from test as data storage.
   */
  public getData(): DataStorage {
    const dataStorage = new DataStorage();

    for (const module of this.modules) {
      module.storeData(dataStorage);
    }

    return dataStorage;
  }

  /**
   * Register all steps of this test.
   */
  public registerTestSteps(): void {
    for (const module of this.modules) {
      module.registerTestSteps(this.testPassage);
    }
  }

  public getSecondTitle(): string | null {
    if (this.mode === TestMode.PREVIEW) {
      return 'test/diagnoticsPreview';
    }
    return null;
  }

  /**
   * Return index of TestStep, where is located Test result page.
   *
   * todo(doubravskytomas): Need improve.
   * For now, result page is always last one.
   * And step indexing is from 0.
   */
  public getResultPageStepIndex(): number {
    return this.stepCount - 1;
  }

  /**
   * Return if test is in mode TESTING.
   */
  public isTestingMode(): boolean {
    return this.mode === TestMode.TESTING;
  }

  /**
   * Return if test is in mode EDITING.
   */
  public isEditingMode(): boolean {
    return this.mode === TestMode.EDITING;
  }

  /**
   * Return if test is in mode REVIEW.
   */
  public isReviewMode(): boolean {
    return this.mode === TestMode.REVIEW;
  }

  /**
   * Return if test is in mode PREVIEW.
   */
  public isPreviewMode(): boolean {
    return this.mode === TestMode.PREVIEW;
  }

  /**
   * Return if test has more than one categories.
   */
  public isMultiCategoriesTest(): boolean {
    return this.taskCategories.length > 1;
  }
}
