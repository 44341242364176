import { Component, Input } from '@angular/core';
import { Test } from '@app/modules/test/models/test/test.model';

/**
 * A component used to display a test badge next to some other information/action.
 * For other uses the "SimpleBadge" component might be more suitable.
 */
@Component({
  selector: 'app-test-badge',
  templateUrl: './test-badge.component.html',
  styleUrls: ['./test-badge.component.scss']
})
export class TestBadgeComponent {
  @Input() public test: Test;

  @Input() public fontSize?: number;
}
