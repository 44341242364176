import { STORE_NAME } from '@app/app.module';
import { IStorage } from '@app/modules/application/models/istorage.model';
import LZString from 'lz-string';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { map, Observable, of } from 'rxjs';

const COMPRESS_VAR = 'compressed-true-';
const STORAGE_KEY = 'dad-application';
const DATA_ID = 1;
export const DB_STORE_NAME = 'application';

export class LocalStorage implements IStorage {
  private appData = '';

  constructor(private dbService: NgxIndexedDBService) {}

  /**
   * Save item into storage.
   *
   * @param key
   * @param value
   */
  public setItem(key: string, value: string): void {
    if (key === STORAGE_KEY) {
      this.dbService.getAll(STORE_NAME).subscribe({
        next: (val: any) => {
          this.appData = value;
          if (val.length > 0 && val[0].id === DATA_ID) {
            this.dbService.update(STORE_NAME, { id: DATA_ID, dadApplication: value }).subscribe();
          } else {
            this.dbService.add(STORE_NAME, { id: DATA_ID, dadApplication: value }).subscribe();
          }
        }
      });
    } else {
      window.localStorage.setItem(key, COMPRESS_VAR + LZString.compress(value));
    }
  }

  /**
   * Load item from storage.
   * It returns null, if key does not exist in the storage.
   *
   * @param key
   */
  public getItem(key: string): string | null {
    if (key === STORAGE_KEY && this.appData) {
      return this.appData;
    } else {
      const value = window.localStorage.getItem(key);
      if (value && value.includes(COMPRESS_VAR)) {
        return LZString.decompress(value.substr(COMPRESS_VAR.length));
      } else {
        return value;
      }
    }
  }

  /**
   * Check if storage has item with key.
   *
   * @param key
   */
  public hasItem(key: string): Observable<boolean> {
    if (key === STORAGE_KEY) {
      return this.dbService.getAll(STORE_NAME).pipe(
        map((val: any) => {
          if (val.length > 0 && val[0].id === DATA_ID) {
            this.appData = val[0].dadApplication;
            return true;
          } else {
            return false;
          }
        })
      );
    } else {
      return of(window.localStorage.getItem(key) !== null);
    }
  }

  /**
   * Clear whole storage.
   */
  public clear(): void {
    this.dbService.clear(STORE_NAME).subscribe();
    window.localStorage.clear();
  }

  /**
   * Return storage identifier.
   */
  public getIdentifier(): string {
    return 'indexDB';
  }
}
