<ng-container *transloco="let t">
  <div id="menu-container">
    <div class="toolbar">
      <img src="assets/img/iconsDefault/menu.svg" class="menu cursor-pointer mr-3" (click)="toggleSidenav()" alt="Menu" />
      <h4 class="light">{{ t('shared/menu') }}</h4>
    </div>

    <div id="menu-content">
      <div class="menu-main">
        <h4 class="uppercase" (click)="goToDashboard()">{{ t('shared/dashboard') }}</h4>
        <h4 class="uppercase" (click)="goToChildList()">{{ t('shared/childList') }}</h4>
        <h4 class="uppercase" (click)="goToTestList()">{{ t('shared/diagnosticMaterials') }}</h4>
      </div>
      <ng-container #vcr></ng-container>
    </div>

    <div class="menu-version">
      <span class="app-version" (click)="debugPrintApp()"> {{ t('shared/version') }}: {{ appVersion }} {{ envShortcut }} </span>
    </div>
  </div>
</ng-container>
