import { Directive, OnInit, TemplateRef } from '@angular/core';
import { MenuService } from '@app/shared/services/menu.service';

@Directive({
  selector: '[appContextMenu]'
})
export class ContextMenuDirective implements OnInit {
  public constructor(private menuService: MenuService, private ref: TemplateRef<any>) {}

  public ngOnInit(): void {
    this.menuService.setContents(this.ref);
  }
}
