/**
 * Class to represent one item in data storage.
 */
import { DataItemValueConverter } from '@app/modules/test/converters/data-item-value.converter';
import { IDataItemData } from '@app/modules/test/types';

export class DataItem {
  protected valueConverter: DataItemValueConverter;

  public constructor(public dataType: string, public uuid: string, public value: string | boolean | number) {
    this.valueConverter = new DataItemValueConverter();
  }

  /**
   * Serialize typescript object into basic javascript types.
   */
  public serialize(): IDataItemData {
    return {
      data_type: this.dataType,
      source_uuid: this.uuid,
      value: this.valueConverter.fromValue(this.dataType, this.value)
    };
  }
}
