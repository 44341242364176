import { Component, Input, OnInit } from '@angular/core';
import { NavigateService } from '@app/core';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { ApplicationRepository } from '@app/modules/application/services/application-repository.service';
import { SyncService } from '@app/modules/sync/services/sync.service';
import { UploadService } from '@app/modules/sync/services/upload.service';
import { PopUpComponent } from '@app/shared/components/pop-up/pop-up.component';
import { AlertType } from '@app/shared/enums/alert-type.enum';
import { Kindergarten } from 'isophi-core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss']
})
export class SimpleHeaderComponent implements OnInit {
  /**
   * Optional callback called, when user click on back arrow.
   * Default action is redirect to dashboard.
   */
  @Input() public backCallback = null;

  /**
   * If show user (kindergarten) menu.
   */
  @Input() public showUserMenu = false;

  /**
   * If show button to toggle menu.
   */
  @Input() public showToggleMenuButton = true;

  public kindergarten: Kindergarten | null = null;

  public constructor(
    private navigateService: NavigateService,
    private appService: ApplicationService,
    private spinner: NgxSpinnerService,
    private syncService: SyncService,
    private popUp: PopUpComponent,
    private appRepository: ApplicationRepository,
    private uploadService: UploadService
  ) {}

  public ngOnInit(): void {
    this.kindergarten = this.appService.application.loggedKindergarten;
  }

  /**
   * Logout user from app, but let kindergarten logged in.
   */
  public userLogout(): void {
    this.navigateService.goToUserLogout();
  }

  /**
   * Upload raw application data to server.
   */
  public sendRawData(): void {
    const header = 'Odeslat chybové hlášení';
    const successMsg =
      'Děkujeme za odeslání upozornění o interních chybách aplikace. ' +
      'Pokud chcete doplnit jakékoliv informace, můžete vývojáře kontaktovat na info@isophi.cz';
    const errorMsg =
      'Akce se nezdařila. Pro tuto funkci musí být zařízení online. ' +
      'Zkontrolujte, prosím, připojení k internetu a zkuste akci zopakovat.';

    this.spinner
      .show()
      .then(() => this.uploadService.uploadRawData())
      .finally(() => this.spinner.hide())
      .then(() => this.popUp.alert(header, successMsg, AlertType.SUCCESS))
      .catch(() => this.popUp.alert(header, errorMsg, AlertType.WARNING));
  }

  /**
   * Action for back arrow button.
   */
  public goBack(): void {
    if (this.backCallback === null) {
      this.navigateService.goToDashboard();
    } else {
      this.backCallback();
    }
  }

  /**
   * Action for menu button.
   */
  public toggleSidenav(): void {
    this.navigateService.toggleSidenav();
  }
}
