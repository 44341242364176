import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DeviceInfoComponent } from '@app/shared/components/device-info/device-info.component';
import { FooterComponent } from '@app/shared/components/footer/footer.component';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import { MailComparisonReportPopUpComponent } from '@app/shared/components/mail-comparison-report-pop-up/mail-comparison-report-pop-up.component';
import { MenuComponent } from '@app/shared/components/menu/menu.component';
import { UserSyncComponent } from '@app/shared/components/user-sync/user-sync.component';
import { SafeHtmlPipe } from '@app/shared/pipes/safehtml.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { IsophiCoreModule } from 'isophi-core';

import { ExpireLicenseAlertComponent } from './components/expire-license-alert/expire-license-alert.component';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { PopUpDialogComponent } from './components/pop-up/components/pop-up-dialog/pop-up-dialog.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { SimpleHeaderComponent } from './components/simple-header/simple-header.component';
import { SyncDataAlertComponent } from './components/sync-data-alert/sync-data-alert.component';
import { TestBadgeComponent } from './components/test-badge/test-badge.component';
import { ContextMenuDirective } from './directives/context-menu.directive';

@NgModule({
  imports: [CommonModule, IsophiCoreModule, RouterModule, NgbModule, FormsModule, TranslocoModule],
  declarations: [
    MenuComponent,
    HeaderComponent,
    DeviceInfoComponent,
    PopUpComponent,
    PopUpDialogComponent,
    ContextMenuDirective,
    SimpleHeaderComponent,
    MailComparisonReportPopUpComponent,
    FooterComponent,
    UserSyncComponent,
    TestBadgeComponent,
    LanguagePickerComponent,
    SafeHtmlPipe,
    ExpireLicenseAlertComponent,
    SyncDataAlertComponent
  ],
  exports: [
    MenuComponent,
    HeaderComponent,
    DeviceInfoComponent,
    PopUpComponent,
    ContextMenuDirective,
    SimpleHeaderComponent,
    FooterComponent,
    UserSyncComponent,
    TestBadgeComponent,
    LanguagePickerComponent,
    TranslocoModule,
    SafeHtmlPipe,
    ExpireLicenseAlertComponent,
    SyncDataAlertComponent
  ]
})
export class SharedModule {
  public static forRoot() {
    return {
      ngModule: SharedModule,
      declarations: [MenuComponent, HeaderComponent, DeviceInfoComponent, ContextMenuDirective],
      exports: [MenuComponent, HeaderComponent, DeviceInfoComponent, ContextMenuDirective]
    };
  }
}
