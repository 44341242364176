import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router, UrlTree } from '@angular/router';
import { NavigateService } from '@app/core';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { ApplicationRepository } from '@app/modules/application/services/application-repository.service';
import { LogoutService } from '@app/modules/login/services/logout.service';
import { SyncService } from '@app/modules/sync/services/sync.service';
import { environment } from '@env/environment';
import { HttpService, User } from 'isophi-core';

@Injectable()
export class AuthGuardService implements CanActivate {
  public constructor(
    private applicationService: ApplicationService,
    private navigateService: NavigateService,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private syncService: SyncService,
    private appRepository: ApplicationRepository,
    private logoutService: LogoutService
  ) {}

  public async canActivate(): Promise<boolean | UrlTree> {
    const timestampSeconds = Math.round(Date.now() / 1000);
    const app = this.applicationService.getApplication();
    const accessToken = app.accessToken;
    const online = await this.applicationService.isOnline();

    // redirect to login when authentication in offline mode failed
    if (!online && !app.loggedUser) {
      return this.router.createUrlTree(['/login']);
    }

    if (!this.checkLastActivity()) {
      this.logoutService.userLogout();
      return this.router.createUrlTree(['/login']);
    }

    if (!app.loggedUser && accessToken) {
      const url = `${environment.authAPI}/introspect/`;
      const postParams = new Map([['token', accessToken]]);

      return this.httpService
        .post(url, postParams, accessToken)
        .toPromise()
        .then(async (res) => {
          if (!res.body.active) {
            throw new Error();
          }

          const loggedUserUrl = `${environment.authAPI}/logged-user/?extra_fields=password_data,group_string&extra_models=teacher`;
          return this.httpService.get(loggedUserUrl, accessToken).toPromise();
        })
        .then(async (userResponse) => {
          app.loggedUser = User.deserialize(userResponse.body);
          const syncDone = await this.syncService.sync();
          if (syncDone) this.appRepository.persistApp(this.applicationService.application);

          localStorage.setItem('last-activity-timestamp', timestampSeconds.toString());

          return true;
        })
        .catch(async () => {
          return this.router.createUrlTree(['/login']);
        });
    }

    if (app && app.loggedUser) {
      localStorage.setItem('last-activity-timestamp', timestampSeconds.toString());
      return true;
    } else {
      return this.router.createUrlTree(['/login']);
    }
  }

  /**
   * Return true if user was active in last 8 hours
   */
  protected checkLastActivity(): boolean {
    const timestampSeconds = Math.round(Date.now() / 1000);
    const lastActivity = localStorage.getItem('last-activity-timestamp');
    if (!lastActivity) {
      // if the object is not present, we should not go to login
      return true;
    }

    return parseInt(lastActivity) + environment.common.loginStatePersistingMaxHours * 60 * 60 > timestampSeconds;
  }
}
