<footer class="pt-4 my-md-5 border-top" *transloco="let t">
  <div class="row">
    <div class="col">
      <div class="text-center">
        <small class="d-block mb-3 text-muted">
          &copy; 2021 iSophi Education, {{ t('shared/version') }} {{ version }} {{ envShortcut }}
        </small>
      </div>
    </div>
  </div>
</footer>
