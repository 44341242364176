<ng-container *transloco="let t">
  <div class="row expire-data-alert justify-content-center" *ngIf="daysToExpire < 31">
    <div *ngIf="daysToExpire <= 31 && daysToExpire >= 0" class="alert alert-warning alert-dismissible" role="alert">
      <span [innerHtml]="t('shared/soonToExpired', { expiration: expirationDate | date })"></span>
    </div>

    <div *ngIf="daysToExpire < 0" class="alert alert-danger alert-dismissible" role="alert">
      <span [innerHtml]="t('shared/expired')"></span>
    </div>
  </div>
</ng-container>
