import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: environment.sentryDSN,
  environment: environment.production ? 'production' : 'development',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
      tracePropagationTargets: [environment.authAPI, environment.dadAPI, environment.materialServer]
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: environment.tracesSampleRate ?? 0,
  // Session Replay
  replaysSessionSampleRate: environment.replaysSampleRate ?? 0,
  replaysOnErrorSampleRate: environment.replaysSampleRate ?? 0
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
