<ng-container *transloco="let t">
  <div class="modal-header">
    <h5 class="modal-title">
      {{ t('shared/printReport') }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">
        <img src="assets/img/closeSmallCropped.svg" alt="Zavřít" />
      </span>
    </button>
  </div>

  <div class="modal-body">
    <ng-container *ngIf="!formValid">
      <ngb-alert type="danger" [dismissible]="false">
        {{ validationMessage }}
      </ngb-alert>
    </ng-container>
    <form name="modal-form">
      <div class="form-group">
        <label for="emailInput">{{ t('shared/enterEmail') }}:</label>
        <div class="input-group">
          <input
            id="emailInput"
            class="form-control"
            placeholder="Váš e-mail"
            name="emailInput"
            [(ngModel)]="inputValue"
            (input)="formValid = true"
            required
          />
        </div>
      </div>
      <div class="form-group">
        <label for="dataSetSelect">{{ t('shared/selectSecondDiagnostics') }}:</label>
        <div class="input-group">
          <select
            id="dataSetSelect"
            class="form-control"
            name="dataSetSelect"
            [(ngModel)]="selectValue"
            (change)="formValid = true"
            required
          >
            <option *ngFor="let option of options" [ngValue]="option">
              {{ option.finishedAt | date }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button class="ibtn ibtn-secondary ibtn-small" (click)="closeModal()" tabindex="0">
      {{ t('shared/cancel') }}
    </button>
    <button class="ibtn ibtn-primary ibtn-small" (click)="okButton()" tabindex="0" ngbAutofocus>
      {{ t('shared/print') }}
    </button>
  </div>
</ng-container>
