<ng-container *transloco="let t">
  <ng-container id="main-container">
    <ng-sidebar-container>
      <ng-sidebar
        *ngIf="renderAppMenu"
        [(opened)]="sidebarService.opened"
        position="right"
        [closeOnClickOutside]="true"
        [closeOnClickBackdrop]="true"
        [showBackdrop]="true"
      >
        <app-menu *ngIf="renderAppMenu"></app-menu>
      </ng-sidebar>

      <div ng-sidebar-content>
        <router-outlet></router-outlet>
      </div>
    </ng-sidebar-container>
  </ng-container>

  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#fff" type="ball-pulse"> {{ t('shared/loading') }} ... </ngx-spinner>
</ng-container>
