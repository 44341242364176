import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SidebarService } from '@app/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { BrowserTabService } from './core/services/browser-tab.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * If render main sliding menu
   */
  public renderAppMenu = false;

  public ngUnsubscribe: Subject<void> = new Subject();

  public constructor(
    private browserTabService: BrowserTabService,
    private router: Router,
    public sidebarService: SidebarService,
    private renderer: Renderer2
  ) {}

  /**
   * What should be done when the browser window is being closed or reloaded.
   *
   * @param event
   */
  @HostListener('window:beforeunload', ['$event'])
  public beforeunloadHandler(_event): void {
    this.browserTabService.onTabBeingClosed();
  }

  public ngOnInit(): void {
    this.renderer.listen('document', 'backbutton', (e) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((navigationEnd: NavigationEnd) => {
        // Pages of login module does not need app-menu, so dont render it
        this.renderAppMenu = !navigationEnd.urlAfterRedirects.startsWith('/login');
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.unsubscribe();
  }
}
