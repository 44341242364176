import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ForcedRestartGuardService implements CanActivate {
  public constructor(private applicationService: ApplicationService, private router: Router) {}

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const app = this.applicationService.getApplication();
    if (!app || !app.loggedUser) {
      // Don't solve auth case, there is AuthGuardService for that.
      return true;
    }

    if (this.shouldForceRestart()) {
      return this.router.createUrlTree(['/login/forced-restart']);
    }

    return true;
  }

  /**
   * Check if app should force kindergarten restart.
   *
   * Add required conditions here, probably when releasing new versions
   * with changes to data sync feed.
   *
   * @private
   */
  private shouldForceRestart(): boolean {
    const app = this.applicationService.application;

    if (app.licensesData === null) return true;
    // Place your other conditions here

    return false;
  }
}
