import { Component } from '@angular/core';

@Component({
  selector: 'app-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss']
})
export class DeviceInfoComponent {
  /**
   * Return array with device info.
   *
   * @returns
   */
  public getDeviceInfo(): string[] {
    const deviceInfo = [];

    deviceInfo.push('window.devicePixelRatio = : ' + window.devicePixelRatio);
    deviceInfo.push('window.innerHeight = ' + window.innerHeight);
    deviceInfo.push('window.outerHeight = ' + window.outerHeight);
    deviceInfo.push('window.innerWidth = ' + window.innerWidth);
    deviceInfo.push('window.outerWidth = ' + window.outerWidth);
    deviceInfo.push('window.screen.availHeight = ' + window.screen.availHeight);
    deviceInfo.push('window.screen.availWidth = ' + window.screen.availWidth);
    deviceInfo.push('window.screen.height = ' + window.screen.height);
    deviceInfo.push('window.screen.width = ' + window.screen.width);
    deviceInfo.push('native width (screen.width * devicePixelRatio) = ' + window.screen.width * window.devicePixelRatio);
    deviceInfo.push('native height (screen.height * devicePixelRatio) = ' + window.screen.height * window.devicePixelRatio);

    return deviceInfo;
  }
}
