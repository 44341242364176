import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { ApplicationRepository } from '@app/modules/application/services/application-repository.service';
import { StorageFactory } from '@app/modules/application/services/storage-factory.service';
import { environment } from '@env/environment';
import { IsophiCoreService, LicenseDataSource, LicenseService, Logger } from 'isophi-core';
import { finalize, map, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  public constructor(
    protected applicationService: ApplicationService,
    protected storageFactory: StorageFactory,
    protected appRepository: ApplicationRepository,
    protected isophiCoreService: IsophiCoreService,
    protected licenseService: LicenseService
  ) {}

  /**
   * Init application.
   */
  public initApplication(): Observable<Application> {
    let app = of(this.applicationService.getApplication());
    if (!this.applicationService.getApplication()) {
      app = this.loadApplication();
    }

    return app.pipe(
      tap(() => {
        // init isophi-core library
        this.isophiCoreService.setUpRequiredFields(
          environment.common.version + ' ' + environment.envShortcut,
          environment.common.appKeyword,
          environment.client_id,
          environment.dadAPI
        );
        this.isophiCoreService.setField('auth-api', environment.authAPI);
        this.isophiCoreService.setField('auth-extra-models', 'teacher');
        this.isophiCoreService.setField('user-repository', this.applicationService.application);

        // init setting for licence service - use the data saved to local storage
        this.licenseService.licenseDataSource = LicenseDataSource.LOCAL_DATA;
      }),
      finalize(() => this.applicationService.appInitialized$.next(true))
    );
  }

  /**
   * Load application from storage or create new.
   */
  protected loadApplication(): Observable<Application> {
    const storage = this.storageFactory.getStorage();

    Logger.debug('InitService :: Application created.');
    const app = new Application();
    this.applicationService.application = app;

    const hasAppData = storage.hasItem(Application.STORAGE_KEY);

    return hasAppData.pipe(
      tap((hasData) => {
        if (hasData) {
          Logger.debug('InitService :: Application data loaded from storage - ' + storage.getIdentifier() + '.');
          this.appRepository.restoreApp(app);
        } else {
          // todo(doubravskytomas): edit this, when sync+upload is joined.
          // Now is synced used for upload, so in new APP there is nothing to upload => set to true.
          app.synced = true;
          app.syncedChanged.next(app.synced);
        }
      }),
      map(() => {
        return this.applicationService.application;
      })
    );
  }
}
