import { Category } from '@app/modules/test/models/general/category.model';
import { BasicEvaluationRange } from '@app/modules/test/models/test-evaluation/basic/basic-evaluation-range.model';
import { IEvaluationRuleData } from '@app/modules/test/types';

export class BasicEvaluationCategoryRule {
  public ranges: Array<BasicEvaluationRange> = [];

  public constructor(public id: number, public category: Category) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param category
   */
  public static deserialize(data: IEvaluationRuleData, category: Category): BasicEvaluationCategoryRule {
    return new BasicEvaluationCategoryRule(data.id, category);
  }

  /**
   * Return user object serialized to basic javascript types.
   */
  public serialize(): IEvaluationRuleData {
    return {
      id: this.id,
      category_id: this.category.id,
      ranges: this.ranges.map((r) => r.serialize())
    };
  }

  /**
   * Add points range into evaluation rule.
   *
   * @param range
   */
  public addRange(range: BasicEvaluationRange): void {
    this.ranges.push(range);
  }
}
