import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  /**
   * Version of the application.
   */
  public version = '';

  /**
   * Shortcut of environment type (prod/dev/techsophia/...)
   */
  public envShortcut = '';

  public ngOnInit(): void {
    this.version = environment.common.version;
    this.envShortcut = environment.envShortcut;
  }
}
