import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { Category } from '@app/modules/test/models/general/category.model';
import { NoteTestModule } from '@app/modules/test/models/test/note-test-module.model';
import { TestPassage } from '@app/modules/test/models/test/test-passage.model';
import { TestStep } from '@app/modules/test/models/test/test-step.interface';
import { INoteSectionWrapperData } from '@app/modules/test/types';

/**
 * Class represent one "abstract" section in the test notes.
 */
export abstract class NoteSection implements TestStep {
  protected constructor(
    public id: number,
    public testSectionId: number,
    public uuid: string,
    public noteTestModule: NoteTestModule,
    public title: string,
    public category: Category,
    public order: number,
    public textInfo?: string,
    public placeholder?: string,
    public maxTextLen?: number
  ) {}

  /**
   * Return note section object serialized to basic javascript types.
   */
  public abstract serialize(): INoteSectionWrapperData;

  /**
   * Register test steps.
   *
   * @param testPassage
   */
  public abstract registerTestSteps(testPassage: TestPassage): void;

  /**
   * This method is call every time step is visited. (It can be visited multiple times)
   */
  public abstract activateStep(testPassage: TestPassage): void;

  /**
   * If step is displayable it should return route to redirect into, when step is active.
   * If step is not displayable it should return null.
   *
   * @return string
   */
  public abstract getRoute(uuid: string, mode: string, childUuid: string, step: number): string;

  /**
   * Return string uniquely identifying test step.
   *
   * @return string
   */
  public abstract getStepId(): string;

  /**
   * This method is call, when step is visited for first time.
   *
   * Note, that step can be visited multiple times (we have next/previous buttons).
   */
  public abstract initStep(testPassage: TestPassage): void;

  /**
   * Return true/false if step is displayable, i.e. it should be displayed to user
   * or it only do some stuff and we can continue to next step.
   *
   * @return boolean
   */
  public abstract isDisplayable(): boolean;

  /**
   * Clear section data, i.e. it removes all results/data from section.
   *
   * Override in sub section, if there is any logic to clear data !
   */
  public abstract clearData(): void;

  /**
   * Restore all section data, i.e. it sets all results/data from data storage into section.
   *
   * Override in sub section, if there is any logic to restore data !
   */
  public abstract restoreData(dataStorage: DataStorage): void;

  /**
   * Save all section data into data storage.
   *
   * Override in sub section, if there is any logic to get data !
   */
  public abstract storeData(dataStorage: DataStorage): void;
}
