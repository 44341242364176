import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { Instruction } from '@app/modules/test/models/general/instruction.model';
import { Material } from '@app/modules/test/models/general/material.model';
import { BasicSubTask } from '@app/modules/test/models/task/basic-subtask.model';
import { BasicTaskItem } from '@app/modules/test/models/task/basic-task-item.model';
import { Point } from '@app/modules/test/models/task/point.model';
import { TestPassage } from '@app/modules/test/models/test/test-passage.model';
import { TestStep } from '@app/modules/test/models/test/test-step.interface';
import { ITestSubTaskGroupData } from '@app/modules/test/types';

export class BasicSubTaskGroup implements TestStep {
  public points: Array<Point> = [];

  public maxPoint: Point = null;

  public subTasks: Array<BasicSubTask> = [];

  public constructor(
    public id: number,
    public uuid: string,
    public basicTaskItem: BasicTaskItem,
    protected _instruction: Instruction | null,
    protected _materials: Material[] | null,
    public subTaskWithText: boolean,
    protected _question: string | null,
    public timer: number | null,
    public order: number,
    public showSubTasksNumber: boolean | null,
    public infoBlock: string | null
  ) {}

  /**
   * Return child question for this basic sub task group.
   */
  public get question(): string {
    return this._question === null || this._question === '' ? this.basicTaskItem.question : this._question;
  }

  /**
   * Return materials for this basic sub task group.
   */
  public get materials(): Material[] {
    return this._materials === null || this._materials.length === 0 ? this.basicTaskItem.materials : this._materials;
  }

  /**
   * Return instruction for this basic sub task group.
   */
  public get instruction(): Instruction {
    return this._instruction === null ? this.basicTaskItem.instruction : this._instruction;
  }

  /**
   * Return obtained score of this sub task group.
   */
  public get score(): number {
    return this.subTasks.reduce((acc, item) => acc + item.score, 0);
  }

  /**
   * Return maximum possible score for all sub tasks.
   *
   * @return number
   */
  public get maxScore(): number {
    if (this.maxPoint === null) return 0;
    return this.subTasks.filter((subTask) => subTask.evaluate).length * this.maxPoint.value;
  }

  /**
   * Return true/false if sub-task group is finished.
   */
  public get finished(): boolean {
    for (const subTask of this.subTasks) {
      if (!subTask.finished) return false;
    }
    return true;
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param basicTaskItem
   * @param instruction
   * @param materials
   */
  public static deserialize(
    data: ITestSubTaskGroupData,
    basicTaskItem: BasicTaskItem,
    instruction: Instruction | null,
    materials: Material[] | null
  ): BasicSubTaskGroup {
    return new BasicSubTaskGroup(
      data.id,
      data.uuid,
      basicTaskItem,
      instruction,
      materials,
      data.sub_tasks_with_text,
      data.question,
      data.timer,
      data.order,
      data.show_sub_tasks_number,
      data.info_block
    );
  }

  /**
   * Return basic subtask group object serialized to basic javascript types.
   */
  public serialize(): ITestSubTaskGroupData {
    return {
      id: this.id,
      uuid: this.uuid,
      question: this._question ? this._question : null,
      instruction: this._instruction ? this._instruction.serialize() : null,
      timer: this.timer,
      material_ids: this._materials ? this._materials.map((m) => m.id) : [],
      sub_tasks_with_text: this.subTaskWithText,
      points: this.points.map((p) => p.serialize()),
      sub_tasks: this.subTasks.map((st) => st.serialize()),
      order: this.order,
      show_sub_tasks_number: this.showSubTasksNumber,
      info_block: this.infoBlock
    };
  }

  /**
   * Add point into sub-task group.
   *
   * @param point
   */
  public addPoint(point: Point): void {
    this.points.push(point);
    if (this.maxPoint === null || this.maxPoint.value < point.value) this.maxPoint = point;
  }

  /**
   * Add sub-task into sub-task group.
   *
   * @param subTask
   */
  public addSubTask(subTask: BasicSubTask): void {
    this.subTasks.push(subTask);
  }

  /**
   * Init sub task group during test passage.
   */
  public initStep(_testPassage: TestPassage): void {
    // nothing to do.
  }

  /**
   * Activate correct category, when this basic sub task group is activated.
   */
  public activateStep(testPassage: TestPassage): void {
    testPassage.test.activeCategory = this.basicTaskItem.category;
  }

  /**
   * Return route to display sub task group.
   */
  public getRoute(uuid: string, mode: string, childUuid: string, step: number): string {
    return `/test/${mode}/${uuid}/${childUuid}/${step}/basic-task`;
  }

  /**
   * Sub task group is displayable.
   */
  public isDisplayable(): boolean {
    return true;
  }

  /**
   * Return string uniquely identifying test step.
   *
   * @return string
   */
  public getStepId(): string {
    return 'test#basic-task#basic-sub-task-group#' + this.id;
  }

  /**
   * Clear sub task group data, ie. it removes all results/data from sub task group.
   */
  public clearData(): void {
    for (const subTask of this.subTasks) {
      subTask.clearData();
    }
  }

  /**
   * Restore all sub task group data, ie. it sets all results/data from data storage into sub task group.
   */
  public restoreData(dataStorage: DataStorage): void {
    for (const subTask of this.subTasks) {
      subTask.restoreData(dataStorage);
    }
  }

  /**
   * Save all sub task group data (results, notes, ...) into data storage.
   */
  public storeData(dataStorage: DataStorage): void {
    for (const subTask of this.subTasks) {
      subTask.storeData(dataStorage);
    }
  }
}
