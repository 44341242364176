import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigateService } from '@app/core';
import { BrowserTabService } from '@app/core/services/browser-tab.service';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { LoginService } from '@app/modules/login/services/login.service';
import { LogoutService } from '@app/modules/login/services/logout.service';
import { DownloadService } from '@app/modules/sync/services/download.service';
import { UploadService } from '@app/modules/sync/services/upload.service';
import { ISync } from '@app/modules/sync/types';
import { PopUpComponent } from '@app/shared/components/pop-up/pop-up.component';
import { AlertType } from '@app/shared/enums/alert-type.enum';
import { environment } from '@env/environment';
import { AuthService, HttpService, Logger, SystemService } from 'isophi-core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  protected localLastSyncDate: Date | null = null;

  public constructor(
    private downloadService: DownloadService,
    private uploadService: UploadService,
    private spinner: NgxSpinnerService,
    private navigateService: NavigateService,
    private appService: ApplicationService,
    private httpService: HttpService,
    private systemService: SystemService,
    private applicationService: ApplicationService,
    private loginService: LoginService,
    private authService: AuthService,
    private popUp: PopUpComponent,
    private logoutService: LogoutService,
    private browserTabService: BrowserTabService
  ) {}

  /**
   * Sync data with server.
   *
   * For multiple call of this method, sync is done only one
   * in ${environment.constructor.nextSyncTimeDelta} seconds,
   * if there are no changes in app.
   *
   * Sync is done in order: download, upload.
   * So now we risk to lose local changes, it should be improve in future.
   *
   * @param forceSync If true, sync with server is done for every call of this method.
   * @return true/false if sync to server was really done.
   */
  public sync(forceSync: boolean = false): Promise<boolean> {
    // Don't preform sync in multiple tabs (only in the main one)
    if (!this.browserTabService.isThisAppInstanceInMainTab()) return Promise.resolve(false);

    // Sync again only if there is any change in application
    // OR ${environment.constructor.nextSyncTimeDelta} seconds already elapsed
    // OR forceSync attribute is set to true
    if (this.localLastSyncDate && this.appService.application.synced && !forceSync) {
      const now = new Date();
      const secondsElapsed = Math.round((+now - +this.localLastSyncDate) / 1000);
      if (secondsElapsed <= environment.common.nextSyncTimeDelta) {
        return Promise.resolve(false);
      }
    }

    const app = this.appService.application;

    return this.getSyncUuid()
      .then((syncUuid) => this.startSyncEvent(syncUuid).then(() => syncUuid))
      .then((syncUuid) => this.uploadService.upload(syncUuid).then(() => syncUuid))
      .then((syncUuid) => this.downloadService.download(syncUuid).then(() => syncUuid))
      .then((syncUuid) => this.finishSyncEvent(syncUuid))
      .then(() => {
        this.localLastSyncDate = new Date();
        app.setSynced();
        return true;
      })
      .catch(() => {
        Logger.debug('SyncService :: Sync failed.');
        throw Error('Sync failed.');
      });
  }

  /**
   * Update user list in app.
   */
  public updateUsers(): Promise<void> {
    return this.getSyncUuid()
      .then((syncUuid) => this.startSyncEvent(syncUuid).then(() => syncUuid))
      .then((syncUuid) => this.downloadService.downloadUsers(syncUuid).then(() => syncUuid))
      .then((syncUuid) => this.finishSyncEvent(syncUuid));
  }

  /**
   * Sync done by user action.
   *
   * This method checks validity of security token
   * and allow to user to login again to refresh security token (if invalid)
   *
   * So this sync must be called with user focus because it pop ups modal windows.
   */
  public userSync(
    modalHeadline: string,
    modalSuccessMsg: string,
    modalErrorMsg: string,
    successCallback: () => void | null = null,
    errorCallback: () => void | null = null
  ): Promise<void> {
    // Don't preform sync in multiple tabs (only in the main one)
    if (!this.browserTabService.isThisAppInstanceInMainTab()) {
      const browserTabErrorMsg = 'Data nebylo možné synchronizovat z důvodů aplikace otevřené ve více záložkách najednou.';
      this.popUp.alert(modalHeadline, browserTabErrorMsg, AlertType.WARNING);
      return Promise.resolve();
    }

    this.spinner.show();
    const app = this.applicationService.application;
    return this.applicationService
      .isOnline()
      .then((isOnline) => {
        if (!isOnline) throw Error('Device is offline.');
        this.authService.accessToken = app.accessToken;
        return this.authService.isAccessTokenValid();
      })
      .then((isValid) => {
        if (isValid) return;
        return this.loginService.showInAppLogin();
      })
      .then(() => this.sync(true))
      .then(() => {
        this.spinner.hide();
        this.popUp.alert(modalHeadline, modalSuccessMsg, AlertType.SUCCESS);
        if (successCallback) successCallback();
      })
      .catch(() => {
        this.spinner.hide();
        this.popUp.alert(modalHeadline, modalErrorMsg, AlertType.WARNING);
        if (errorCallback) errorCallback();
      });
  }

  /**
   * Get unique sync UUID for client.
   *
   * @protected
   */
  protected async getSyncUuid(): Promise<string> {
    const app = this.appService.application;

    if (app.syncUuid && (await this.isSyncUuidActive(app.syncUuid))) {
      return Promise.resolve(app.syncUuid);
    }

    const syncUrl = `${environment.dadAPI}/sync/`;
    const postParams = new Map([['device_uuid', this.systemService.getDeviceUuid()]]);

    return this.httpService
      .post(syncUrl, postParams, app.accessToken)
      .toPromise()
      .then((response: HttpResponse<ISync>) => {
        app.syncUuid = response.body.uuid;
        return app.syncUuid;
      })
      .catch(() => {
        Logger.debug('SyncService :: Sync failed.');
        throw Error('Sync failed.');
      });
  }

  /**
   * Check if syncUuid is still active on server.
   *
   * @param syncUuid
   * @protected
   */
  protected isSyncUuidActive(syncUuid: string): Promise<boolean> {
    const app = this.appService.application;
    const syncDetailUrl = `${environment.dadAPI}/sync/${syncUuid}/`;

    return this.httpService
      .get(syncDetailUrl, app.accessToken)
      .toPromise()
      .then(() => true)
      .catch((err) => {
        if (err.status === 404) return false;
        else throw Error('Sync failed.');
      });
  }

  /**
   * Star new sync event.
   *
   * @protected
   */
  protected startSyncEvent(syncUuid: string): Promise<void> {
    const app = this.appService.application;
    const url = `${environment.dadAPI}/sync/${syncUuid}/start/`;
    const params = new Map<string, any>();
    return this.httpService
      .post(url, params, app.accessToken)
      .toPromise()
      .then(() => null);
  }

  /**
   * Finish currently running sync.
   *
   * @param syncUuid
   * @protected
   */
  protected finishSyncEvent(syncUuid: string): Promise<void> {
    const app = this.appService.application;
    const url = `${environment.dadAPI}/sync/${syncUuid}/finish/`;
    const params = new Map<string, any>();
    return this.httpService
      .post(url, params, app.accessToken)
      .toPromise()
      .then(() => null);
  }
}
