import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { SystemService } from 'isophi-core';
import { Observable } from 'rxjs';

import { BrowserTabService } from './browser-tab.service';

@Injectable({
  providedIn: 'root'
})
export class BrowserTabGuardService implements CanActivate {
  public constructor(protected sysService: SystemService, protected router: Router, protected browserTabService: BrowserTabService) {}

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.sysService.isCordova()) return true; // There is always only one tab in the mobile version

    if (this.browserTabService.isThisAppInstanceInMainTab()) {
      return true;
    } else return this.router.createUrlTree(['/sync/not-main-tab']);
  }
}
