import { DataItem } from '@app/modules/test/models/data/data-item.model';
import { IDataItemData } from '@app/modules/test/types';

/**
 * Class to store test data.
 */
export class DataStorage {
  public data: Map<string, DataItem> = new Map<string, DataItem>();

  /**
   * Serialize typescript object into basic javascript types.
   *
   * It returns array of all items saved in data storage.
   */
  public serialize(): IDataItemData[] {
    const items = [];
    this.data.forEach((value) => items.push(value.serialize()));
    return items;
  }

  /**
   * Set data into data storage.
   *
   * @param dataType
   * @param uuid
   * @param value
   */
  public setData(dataType: string, uuid: string, value: string | number | boolean): void {
    this.data.set(uuid, new DataItem(dataType, uuid, value));
  }

  /**
   * Find data item in data storage and return only value.
   *
   * @param uuid
   * @return any
   */
  public getDataValue(uuid: string): null | number | boolean | string {
    const dataItem = this.data.get(uuid);
    return dataItem === undefined ? null : dataItem.value;
  }
}
