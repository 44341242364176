import { BasicEvaluationRange } from '@app/modules/test/models/test-evaluation/basic/basic-evaluation-range.model';
import { IEvaluationRuleData } from '@app/modules/test/types';

export class BasicEvaluationRule {
  public ranges: Array<BasicEvaluationRange> = [];

  public constructor(public id: number) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: IEvaluationRuleData): BasicEvaluationRule {
    return new BasicEvaluationRule(data.id);
  }

  /**
   * Return basic evaluation rule object serialized to basic javascript types.
   */
  public serialize(): IEvaluationRuleData {
    return {
      id: this.id,
      ranges: this.ranges.map((r) => r.serialize())
    };
  }

  /**
   * Add points range into evaluation rule.
   *
   * @param range
   */
  public addRange(range: BasicEvaluationRange): void {
    this.ranges.push(range);
  }
}
