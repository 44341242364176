import { Injectable } from '@angular/core';
import { IStorage } from '@app/modules/application/models/istorage.model';
import { StorageFactory } from '@app/modules/application/services/storage-factory.service';
import { SessionStorage, SystemService } from 'isophi-core';
import { v4 as uuid } from 'uuid';

/**
 * This service makes sure that the app runs only in one browser tab.
 * (Running in multiple tabs may cause issues with synchronisation.)
 */
@Injectable({
  providedIn: 'root'
})
export class BrowserTabService {
  public static readonly MAIN_TAB_KEY = 'main-browser-tab-uuid';

  public static readonly SESSION_TAB_KEY = 'session-browser-tab-uuid';

  private localStorage: IStorage;

  private sessionStorage: SessionStorage;

  public constructor(protected sysService: SystemService, protected storageFactory: StorageFactory) {
    this.sessionStorage = new SessionStorage();
    this.localStorage = this.storageFactory.getStorage();
  }

  /**
   * Checks if an app instance is running in the main tab or is opened in another window.
   *
   * @returns true if the app instance is running in the main tab
   */
  public isThisAppInstanceInMainTab(): boolean {
    if (this.sysService.isCordova()) return true;

    let sessionTabUUID = this.sessionStorage.getItem(BrowserTabService.SESSION_TAB_KEY);
    const mainTabUUID = this.localStorage.getItem(BrowserTabService.MAIN_TAB_KEY);

    if (!sessionTabUUID) {
      sessionTabUUID = uuid();
      this.sessionStorage.setItem(BrowserTabService.SESSION_TAB_KEY, sessionTabUUID);
    }

    if (!mainTabUUID) {
      // No UUID set yet means the instance is running in the main tab
      this.localStorage.setItem(BrowserTabService.MAIN_TAB_KEY, sessionTabUUID);
      return true;
    } else if (sessionTabUUID === mainTabUUID) {
      // Update the UUIDs on each check to prevent false negative check after tab duplication
      const newUUID = uuid();

      this.sessionStorage.setItem(BrowserTabService.SESSION_TAB_KEY, newUUID);
      this.localStorage.setItem(BrowserTabService.MAIN_TAB_KEY, newUUID);

      return true;
    } else return false;
  }

  /**
   * Marks the browser tab in which the app instance is running as the main one.
   */
  public makeCurrentTabTheMainOne(): void {
    let sessionTabUUID = this.sessionStorage.getItem(BrowserTabService.SESSION_TAB_KEY);

    if (!sessionTabUUID) {
      sessionTabUUID = uuid();
      sessionStorage.setItem(BrowserTabService.SESSION_TAB_KEY, sessionTabUUID);
    }

    this.localStorage.setItem(BrowserTabService.MAIN_TAB_KEY, sessionTabUUID);
  }

  /**
   * Clearing the record in the local storage on tab being closed.
   */
  public onTabBeingClosed(): void {
    const sessionTabUUID = this.sessionStorage.getItem(BrowserTabService.SESSION_TAB_KEY);
    const mainTabUUID = this.localStorage.getItem(BrowserTabService.MAIN_TAB_KEY);

    if (mainTabUUID === sessionTabUUID) {
      // If the main tab is getting closed clear the mainTabUUID
      this.localStorage.setItem(BrowserTabService.MAIN_TAB_KEY, '');
    }
  }
}
