import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sync-data-alert',
  templateUrl: './sync-data-alert.component.html',
  styleUrls: ['./sync-data-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SyncDataAlertComponent implements OnDestroy {
  synced: boolean;

  private _subs: Subscription[] = [];

  constructor(private applicationService: ApplicationService) {
    this._subs.push(
      this.applicationService.getApplication()?.syncedChanged.subscribe((state) => {
        this.synced = state;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((_subs) => _subs.unsubscribe());
  }
}
