import { NoteSectionType } from '@app/modules/test/enums/note-section-type.enum';
import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { Category } from '@app/modules/test/models/general/category.model';
import { NoteSection } from '@app/modules/test/models/note/note-section.model';
import { NoteTestModule } from '@app/modules/test/models/test/note-test-module.model';
import { TestPassage } from '@app/modules/test/models/test/test-passage.model';
import { TestStep } from '@app/modules/test/models/test/test-step.interface';
import { INoteSectionData, INoteSectionWrapperData } from '@app/modules/test/types';

/**
 * Class represent one section with text input in the test notes.
 */
export class TextNoteSection extends NoteSection implements TestStep {
  public static readonly DATA_TYPE = 'note$text_note_section';

  public constructor(
    id: number,
    testSectionId: number,
    uuid: string,
    noteTestModule: NoteTestModule,
    title: string,
    category: Category,
    order: number,
    textInfo?: string,
    placeholder?: string,
    maxTextLen?: number,
    public value: string | null = null
  ) {
    super(id, testSectionId, uuid, noteTestModule, title, category, order, textInfo, placeholder, maxTextLen);
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param noteTestModule
   * @param category
   * @param testSectionId
   * @param order
   */
  public static deserialize(
    data: INoteSectionData,
    noteTestModule: NoteTestModule,
    category: Category,
    order: number,
    testSectionId: number
  ): TextNoteSection {
    return new TextNoteSection(
      data.id,
      testSectionId,
      data.uuid,
      noteTestModule,
      data.title,
      category,
      order,
      data.text_info,
      data.placeholder,
      data.max_text_len
    );
  }

  /**
   * Return text note section object serialized to basic javascript types.
   */
  public serialize(): INoteSectionWrapperData {
    return {
      id: this.testSectionId,
      order: this.order,
      section: {
        id: this.id,
        uuid: this.uuid,
        title: this.title,
        category_id: this.category ? this.category.id : null,
        section_type: NoteSectionType.TEXT,
        text_info: this.textInfo,
        placeholder: this.placeholder,
        max_text_len: this.maxTextLen
      }
    };
  }

  /**
   * Register test steps.
   *
   * @param testPassage
   */
  public registerTestSteps(testPassage: TestPassage): void {
    testPassage.registerStep(this);
  }

  /**
   * Activate this note section.
   *
   * @param testPassage
   */
  public activateStep(testPassage: TestPassage): void {
    // disable active category, test note does not have category.
    testPassage.test.activeCategory = null;
  }

  /**
   * Return route to component displaying this note section.
   *
   * @return string
   */
  public getRoute(uuid: string, mode: string, childUuid: string, step: number): string {
    return `/test/${mode}/${uuid}/${childUuid}/${step}/text-note`;
  }

  /**
   * Return ID representing this step.
   *
   * @return string
   */
  public getStepId(): string {
    return 'test#text-note#' + this.id;
  }

  /**
   * Init this step.
   *
   * @param testPassage
   */
  public initStep(_testPassage: TestPassage): void {
    // nothing to do
  }

  /**
   * Return if this step is visible in test passage.
   *
   * @return boolean
   */
  public isDisplayable(): boolean {
    return true;
  }

  /**
   * Clear section data, i.e. it removes all results/data from section.
   *
   * Override in sub section, if there is any logic to clear data !
   */
  public clearData(): void {
    this.value = null;
  }

  /**
   * Restore all section data, i.e. it sets all results/data from data storage into section.
   *
   * Override in sub section, if there is any logic to restore data !
   */
  public restoreData(dataStorage: DataStorage): void {
    this.value = dataStorage.getDataValue(this.uuid) as string | null;
  }

  /**
   * Save all section data into data storage.
   *
   * Override in sub section, if there is any logic to get data !
   */
  public storeData(dataStorage: DataStorage): void {
    // it is unnecessary to save null into storage.
    if (this.value !== null) dataStorage.setData(TextNoteSection.DATA_TYPE, this.uuid, this.value);
  }
}
