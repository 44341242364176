import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { ApplicationRepository } from '@app/modules/application/services/application-repository.service';
import { Child } from '@app/modules/children/models/child.model';
import { DataSetStatus } from '@app/modules/test/enums/data-set-status.enum';
import { NavigationActions } from '@app/modules/test/enums/navigation-actions.enum';
import { TestMode } from '@app/modules/test/enums/test-mode.enum';
import { DataSet } from '@app/modules/test/models/test/data-set.model';
import { Test } from '@app/modules/test/models/test/test.model';
import { environment } from '@env/environment';
import { HttpService, LicenseTestFeature } from 'isophi-core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class TestService {
  public dataSet: DataSet | null = null;

  public testRunningSubscription: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected test: Test | null = null;

  protected runningTest: Test | null = null;

  protected startedAt: Date | null = null;

  protected finishedAt: Date | null = null;

  public constructor(
    protected appService: ApplicationService,
    protected appRepository: ApplicationRepository,
    protected httpService: HttpService
  ) {}

  /**
   * Return instance of running test.
   *
   * That means:
   * - During testing it returns running test.
   * - Out of testing it returns null.
   */
  public getRunningTest(): Test | null {
    return this.runningTest;
  }

  /**
   * It starts a new testing of a child.
   *
   * @param test
   * @param child
   */
  public startTest(test: Test, child: Child): void {
    test.startTest(TestMode.TESTING);
    test.testingChild = child;
    this.runningTest = test;
    this.startedAt = new Date();
    this.dataSet = null;
    this.testRunningSubscription.next(true);
  }

  /**
   * Continue in uncompleted test.
   *
   * @param dataSet
   * @param child
   */
  public continueTest(dataSet: DataSet, child: Child): void {
    const test = dataSet.test;

    if (test === null || test === undefined) {
      // todo(doubravskytomas): show some alert.
      return;
    }

    test.startTest(TestMode.TESTING);
    test.restoreData(dataSet.dataStorage);
    test.testingChild = child;
    this.dataSet = dataSet;
    this.runningTest = test;
    this.startedAt = dataSet.startedAt;
    this.testRunningSubscription.next(true);
  }

  /**
   * Edit already completed test.
   *
   * @param dataSet
   * @param child
   */
  public editTest(dataSet: DataSet, child: Child): void {
    const test = dataSet.test;

    if (test === null || test === undefined) {
      // todo(doubravskytomas): show some alert.
      return;
    }

    test.startTest(TestMode.EDITING);
    test.restoreData(dataSet.dataStorage);
    test.testingChild = child;
    this.dataSet = dataSet;
    this.runningTest = test;
    this.startedAt = dataSet.startedAt;
    this.finishedAt = dataSet.finishedAt;
    this.testRunningSubscription.next(true);
  }

  /**
   * Delete test.
   *
   * @param dataSet
   * @param child
   */
  public deleteTest(dataSet: DataSet, _child: Child): void {
    this.appService.getApplication().removeDataSet(dataSet.uuid);
  }

  /**
   * It starts test in preview mode.
   *
   * @param test
   */
  public previewTest(test: Test): void {
    test.startTest(TestMode.PREVIEW);
    this.runningTest = test;
    this.testRunningSubscription.next(true);
  }

  /**
   * It load data into test and start test in review mode.
   *
   * @param dataSet
   * @param child
   */
  public reviewTest(dataSet: DataSet, child: Child): void {
    const test = dataSet?.test;

    if (test === null || test === undefined) {
      // todo(doubravskytomas): show some alert.
      return;
    }

    test.startTest(TestMode.REVIEW);
    test.restoreData(dataSet.dataStorage);
    test.testingChild = child;
    test.testPassage.activateStepById('test#result');
    this.dataSet = dataSet;
    this.runningTest = test;
    this.testRunningSubscription.next(true);
  }

  /**
   * It finish currently running (editing) test.
   *
   * That means:
   * - Save test data (if test is in TESTING mode) to child as completedTest.
   * - Remove old data (if test is in EDITING mode) from child and application.
   * - Clear test.
   */
  public finishTest(finsihAt?: Date): null | DataSet {
    const test = this.getRunningTest();
    let dataSet = null;
    // Remove old dataset if exist (continue/edit test)
    if (this.dataSet !== null) {
      this.appService.getApplication().removeDataSet(this.dataSet.uuid);
    }
    dataSet = this.saveDataSet(test, DataSetStatus.COMPLETED, finsihAt);
    test.finishTest();
    test.clearData();
    this.runningTest = null;
    this.dataSet = null;
    this.finishedAt = null;
    this.startedAt = null;
    this.testRunningSubscription.next(false);
    return dataSet;
  }

  /**
   * It save currently running test.
   *
   * That means:
   * - Remove old data, if any
   * - Save new test data (if test is in TESTING mode) to child as uncompletedTest.
   */
  public saveTest(): void {
    const test = this.getRunningTest();
    if (test.mode === TestMode.TESTING) {
      if (this.dataSet !== null) {
        this.appService.getApplication().removeDataSet(this.dataSet.uuid);
      }
      this.dataSet = this.saveDataSet(test, DataSetStatus.UNCOMPLETED);
    }
  }

  /**
   * It pauses currently running test.
   *
   * That means:
   * - Remove old data, if any
   * - Save new test data (if test is in TESTING mode) to child as uncompletedTest.
   * - Clear test.
   */
  public pauseTest(): void {
    const test = this.getRunningTest();
    if (test.mode === TestMode.TESTING) {
      if (this.dataSet !== null) {
        this.appService.getApplication().removeDataSet(this.dataSet.uuid);
      }
      this.saveDataSet(test, DataSetStatus.UNCOMPLETED);
    }
    test.finishTest();
    test.clearData();
    this.runningTest = null;
    this.dataSet = null;
    this.testRunningSubscription.next(false);
  }

  /**
   * It cancel test.
   *
   * That means:
   * - Stop running test.
   * - Clear test.
   */
  public cancelTest(): void {
    const test = this.getRunningTest();
    if (!test) return;
    // if testing and have dataSet => It is continue of old test, so delete old dataset.
    if (test.mode === TestMode.TESTING && this.dataSet !== null) {
      this.appService.getApplication().removeDataSet(this.dataSet.uuid);
    }
    test.finishTest();
    test.clearData();
    this.runningTest = null;
    this.dataSet = null;
    this.startedAt = null;
    this.finishedAt = null;
    this.testRunningSubscription.next(false);
  }

  /**
   * Send DAD test basic report to mail.
   *
   * @param mail
   * @param dataSet
   */
  public mailBasicReport(mail: string, dataSet: DataSet | null = null): Promise<boolean> {
    if (dataSet === null) dataSet = this.dataSet;
    if (dataSet === null || dataSet.id === null) return Promise.resolve(false);

    const requestUrl = `${environment.dadAPI}/data-sets/${dataSet.id}/mail-report/`;
    const securityToken = this.appService.application.accessToken;
    const postParams = new Map<string, any>();
    postParams.set('mail', mail);

    return this.httpService
      .post(requestUrl, postParams, securityToken)
      .toPromise()
      .then((response) => {
        return response.status === 200 || response.status === 201;
      })
      .catch(() => {
        return false;
      });
  }

  /**
   * Send DAD test comparison report to mail.
   *
   * @param mail
   * @param firstDataSet
   * @param secondDataSet
   */
  public mailComparisonReport(mail: string, firstDataSet: DataSet, secondDataSet: DataSet): Promise<boolean> {
    if (firstDataSet.id === null || secondDataSet.id === null) return Promise.resolve(false);

    const requestUrl = `${environment.dadAPI}/reports/mail-comparison-report/`;
    const securityToken = this.appService.application.accessToken;
    const postParams = new Map<string, any>();
    postParams.set('email', mail);
    postParams.set('data_set_uuid1', firstDataSet.uuid);
    postParams.set('data_set_uuid2', secondDataSet.uuid);

    return this.httpService
      .post(requestUrl, postParams, securityToken)
      .toPromise()
      .then((response) => {
        return response.status === 200 || response.status === 201;
      })
      .catch(() => {
        return false;
      });
  }

  public callTestActionByUrlSnapshot(snapshot: ActivatedRouteSnapshot): void {
    // If test already running, we don't have to do any action
    if (this.runningTest !== null) return;

    const urlMode: NavigationActions = snapshot.params.mode;
    // uuid URL param can contains DataSet uuid OR test uuid
    const uuidParam = snapshot.params.uuid;
    const childUuid = snapshot.params.childUuid;
    let child: Child;
    let dataSet: DataSet;
    let test: Test;

    if (childUuid !== 'none') {
      child = this.appService.getApplication().getChild(childUuid);
    }

    switch (urlMode) {
      case NavigationActions.REVIEW:
        dataSet = this.appService.getApplication().getDataSet(uuidParam);
        this.reviewTest(dataSet, dataSet?.child);
        break;

      case NavigationActions.PREVIEW:
        test = this.appService.getApplication().getTestByUuid(uuidParam);
        this.previewTest(test);
        break;

      case NavigationActions.EDIT:
        dataSet = this.appService.getApplication().getDataSet(uuidParam);
        this.editTest(dataSet, dataSet?.child);
        break;

      case NavigationActions.START:
        test = this.appService.getApplication().getTestByUuid(uuidParam);
        this.startTest(test, child);
        break;

      case NavigationActions.CONTINUE:
        dataSet = this.appService.getApplication().getDataSet(uuidParam);
        this.continueTest(dataSet, dataSet?.child);
        break;

      default:
        dataSet = this.appService.getApplication().getDataSet(uuidParam);
        this.reviewTest(dataSet, dataSet?.child);
    }
  }

  /**
   * This method checks several situations in which the navigation through test should be hidden.
   *
   * @param licenses the dictionary keys are expected to match the LicenseTestFeature enum values
   * @returns boolean if there should be navigation for browsing through test
   */
  public hasAnyLicenseToBrowseThroughTest(test: Test, licenses: Record<string, boolean>): boolean {
    return (
      (test.isTestingMode() && licenses[LicenseTestFeature.TEST_RECORD]) ||
      (test.isPreviewMode() && licenses[LicenseTestFeature.TEST_PREVIEW]) ||
      (test.isEditingMode() && licenses[LicenseTestFeature.TEST_EDIT]) ||
      (test.isReviewMode() && licenses[LicenseTestFeature.DISPLAY_FILLED_TEST])
    );
  }

  /**
   * Save test data to tested child.
   *
   * @param test
   * @param dataSetStatus
   * @return DataSet
   */
  protected saveDataSet(test: Test, dataSetStatus: DataSetStatus, finishAt?: Date): DataSet {
    let finishedAt = new Date();

    if (finishAt) {
      finishedAt = finishAt;
    }

    const dataSet = new DataSet(
      null,
      uuid(),
      test.id,
      this.startedAt,
      finishedAt,
      this.appService.getApplication().loggedUser.teacher?.id,
      test.testingChild?.id,
      test.testingChild?.uuid,
      dataSetStatus,
      test.getData()
    );
    dataSet.test = test;
    dataSet.child = test.testingChild;
    test.testingChild.addDataSet(dataSet);
    this.appService.getApplication().addDataSet(dataSet);
    this.appRepository.persistApp(this.appService.application);
    return dataSet;
  }
}
