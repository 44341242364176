import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { ApplicationRepository } from '@app/modules/application/services/application-repository.service';
import { Child } from '@app/modules/children/models/child.model';
import { DataSet } from '@app/modules/test/models/test/data-set.model';
import { ConstantsService } from '@app/shared/services/constants.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mail-comparison-report-pop-up',
  templateUrl: './mail-comparison-report-pop-up.component.html',
  styleUrls: ['./mail-comparison-report-pop-up.component.scss']
})
export class MailComparisonReportPopUpComponent implements OnInit {
  public inputValue = '';

  public selectValue: DataSet | null = null;

  public formValid = true;

  public validationMessage: string | null = null;

  public options: Array<DataSet> = [];

  public child: Child;

  public dataSet: DataSet;

  public modalReference: NgbModalRef;

  public constructor(
    protected constants: ConstantsService,
    public applicationService: ApplicationService,
    private appRepository: ApplicationRepository
  ) {}

  public ngOnInit(): void {
    this.options = this.child.completedTests.filter(
      (ds) => ds.uuid !== this.dataSet.uuid && ds.test?.productCodename === this.dataSet.test.productCodename
    );
    this.selectValue = this.options[0];
    this.inputValue = this.applicationService.getApplication().lastEmailInReport
      ? this.applicationService.getApplication().lastEmailInReport
      : this.applicationService.getApplication().loggedUser.email;
  }

  public closeModal(): void {
    this.modalReference.dismiss();
  }

  public okButton(): void {
    if (this.selectValue === null) {
      this.formValid = false;
      this.validationMessage = 'Musíte vybrat druhou diagnostiku pro srovnání.';
      return;
    }
    if (this.inputValue.match(this.constants.emailRegex) === null) {
      this.formValid = false;
      this.validationMessage = 'Zadaný e-mail není platný.';
      return;
    }

    this.applicationService.getApplication().lastEmailInReport = this.inputValue;
    this.appRepository.persistApp(this.applicationService.application);

    this.modalReference.close({
      email: this.inputValue,
      firstDataSet: this.dataSet,
      secondDataSet: this.selectValue
    });
  }
}
