import { IEvaluationRangeData } from '@app/modules/test/types';

export class BasicEvaluationRange {
  public constructor(
    public id: number,
    public code: number,
    public title: string,
    public textColor: string,
    public backgroundColor: string,
    public minPoint: number | null,
    public maxPoint: number | null,
    public minPercent: number | null,
    public maxPercent: number | null
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: IEvaluationRangeData): BasicEvaluationRange {
    return new BasicEvaluationRange(
      data.id,
      data.code,
      data.title,
      data.text_color,
      data.background_color,
      data.min_point,
      data.max_point,
      data.min_percent,
      data.max_percent
    );
  }

  /**
   * Return basic evaluation range object serialized to basic javascript types.
   */
  public serialize(): IEvaluationRangeData {
    return {
      id: this.id,
      code: this.code,
      title: this.title,
      text_color: this.textColor,
      background_color: this.backgroundColor,
      min_point: this.minPoint,
      max_point: this.maxPoint,
      min_percent: this.minPercent,
      max_percent: this.maxPercent
    };
  }

  /**
   * Test if score is in the range.
   *
   * @param score
   * @param maxScore
   */
  public isInRange(score: number, maxScore: number): boolean {
    if (this.maxPercent !== null && this.minPercent !== null) {
      const percent = Math.round((score / maxScore) * 100);
      return this.minPercent <= percent && percent <= this.maxPercent;
    } else if (this.minPoint !== null && this.maxPoint !== null) {
      return this.minPoint <= score && score <= this.maxPoint;
    } else {
      throw new Error('Invalid BasicEvaluationRange.');
    }
  }
}
