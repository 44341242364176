import { deserializeNewField, serializeNewField } from '@app/core/backward-compatibility/utils';
import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { BasicSubTaskGroup } from '@app/modules/test/models/task/basic-subtask-group.model';
import { ITestSubTaskData } from '@app/modules/test/types';

export class BasicSubTask {
  public static readonly DATA_TYPE = 'task$basic_sub_task';

  public constructor(
    public id: number,
    public uuid: string,
    public order: number,
    public evaluate: boolean,
    public imageUrl: string | null,
    public basicSubTaskGroup: BasicSubTaskGroup,
    public text: string | null = null,
    public score: number | null = null
  ) {}

  /**
   * Return true/false if sub-task is finished.
   *
   * Finished task = has value from user OR is marked as not-evaluate.
   */
  public get finished(): boolean {
    return !this.evaluate || this.score !== null;
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param basicSubTaskGroup
   */
  public static deserialize(data: ITestSubTaskData, basicSubTaskGroup: BasicSubTaskGroup): BasicSubTask {
    return new BasicSubTask(
      data.id,
      data.uuid,
      data.order,
      deserializeNewField(data, 'evaluate', true) as boolean,
      data.image_url,
      basicSubTaskGroup,
      data.text
    );
  }

  /**
   * Return basic sub task object serialized to basic javascript types.
   */
  public serialize(): ITestSubTaskData {
    return {
      id: this.id,
      uuid: this.uuid,
      text: this.text,
      order: this.order,
      evaluate: serializeNewField(this, 'evaluate', true) as boolean,
      image_url: this.imageUrl
    };
  }

  /**
   * Clear sub task data, ie. it removes all results/data from sub task.
   */
  public clearData(): void {
    this.score = null;
  }

  /**
   * Restore all sub task data, ie. it sets all results/data from data storage into sub task.
   */
  public restoreData(dataStorage: DataStorage): void {
    this.score = dataStorage.getDataValue(this.uuid) as number | null;
  }

  /**
   * Save all sub task data (results, notes, ...) into data storage.
   */
  public storeData(dataStorage: DataStorage): void {
    // it is unnecessary to save null into storage.
    if (this.score !== null) dataStorage.setData(BasicSubTask.DATA_TYPE, this.uuid, this.score);
  }
}
