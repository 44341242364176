import { Injectable } from '@angular/core';
import { ApplicationDeserializer } from '@app/modules/application/deserializers/application-deserializer';
import { Application } from '@app/modules/application/models/application.model';
import { ApplicationSerializer } from '@app/modules/application/serializers/application-serializer';
import { StorageFactory } from '@app/modules/application/services/storage-factory.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationRepository {
  public constructor(
    protected storageFactory: StorageFactory,
    protected appSerializer: ApplicationSerializer,
    protected appDeserializer: ApplicationDeserializer
  ) {}

  /**
   * Restore application state from storage.
   *
   * @param app
   */
  public restoreApp(app: Application): void {
    const storage = this.storageFactory.getStorage();
    const serializedApp = storage.getItem(Application.STORAGE_KEY);
    this.appDeserializer.deserialize(app, JSON.parse(serializedApp));
  }

  /**
   * Save application state into storage.
   *
   * @param app
   */
  public persistApp(app: Application): void {
    const storage = this.storageFactory.getStorage();
    const serializedApp = this.appSerializer.serialize(app);
    storage.setItem(Application.STORAGE_KEY, serializedApp);
  }
}
