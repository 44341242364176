import { TaskItemType } from '@app/modules/test/enums/task-item-type.enum';
import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { Instruction } from '@app/modules/test/models/general/instruction.model';
import { BasicSubTaskGroup } from '@app/modules/test/models/task/basic-subtask-group.model';
import { TaskItem } from '@app/modules/test/models/task/task-item.model';
import { TaskTestModule } from '@app/modules/test/models/test/task-test-module.model';
import { TestPassage } from '@app/modules/test/models/test/test-passage.model';
import { TestStep } from '@app/modules/test/models/test/test-step.interface';
import { ITestItemData, ITestItemWrapperData } from '@app/modules/test/types';

import { Category } from '../general/category.model';
import { Material } from '../general/material.model';

export class BasicTaskItem extends TaskItem {
  public subTaskGroups: Array<BasicSubTaskGroup> = [];

  public constructor(
    id: number,
    testItemId: number,
    uuid: string,
    taskTestModule: TaskTestModule,
    title: string,
    category: Category,
    public instruction: Instruction | null,
    public materials: Material[] | null,
    public question: string | null,
    order: number,
    public infoBlock: string | null
  ) {
    super(id, testItemId, uuid, taskTestModule, title, category, order);
  }

  /**
   * Return obtained score of this task.
   */
  public get score(): number {
    return this.subTaskGroups.reduce((acc, item) => acc + item.score, 0);
  }

  /**
   * Return maximum possible score of this task.
   */
  public get maxScore(): number {
    return this.subTaskGroups.reduce((acc, item) => acc + item.maxScore, 0);
  }

  /**
   * Return true/false if task is finished.
   */
  public get finished(): boolean {
    for (const group of this.subTaskGroups) {
      if (!group.finished) return false;
    }
    return true;
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param testItemId
   * @param taskTestModule
   * @param category
   * @param instruction
   * @param materials
   * @param order
   */
  public static deserialize(
    data: ITestItemData,
    testItemId: number,
    taskTestModule: TaskTestModule,
    category: Category,
    instruction: Instruction | null,
    materials: Material[] | null,
    order: number
  ): BasicTaskItem {
    return new BasicTaskItem(
      data.id,
      testItemId,
      data.uuid,
      taskTestModule,
      data.title,
      category,
      instruction,
      materials,
      data.question,
      order,
      data.info_block
    );
  }

  /**
   * Return basic task item object serialized to basic javascript types.
   */
  public serialize(): ITestItemWrapperData {
    return {
      id: this.testItemId,
      category_id: this.category.id,
      order: this.order,
      item: {
        id: this.id,
        uuid: this.uuid,
        item_type: TaskItemType.BASIC_TASK,
        title: this.title,
        question: this.question,
        instruction: this.instruction ? this.instruction.serialize() : null,
        material_ids: this.materials.map((m) => m.id),
        sub_task_groups: this.subTaskGroups.map((g) => g.serialize()),
        info_block: this.infoBlock
      }
    };
  }

  /**
   * Add basic sub-task group into task item.
   *
   * @param subTaskGroup
   */
  public addSubTaskGroup(subTaskGroup: BasicSubTaskGroup): void {
    this.subTaskGroups.push(subTaskGroup);
  }

  /**
   * Return basic sub-task group by index.
   *
   * @param index
   */
  public getSubTaskGroupByIndex(index: number): BasicSubTaskGroup | null {
    const group = this.subTaskGroups[index];
    return group === undefined ? null : group;
  }

  /**
   * Return basic sub-task group by id.
   *
   * @param id
   */
  public getSubTaskGroupById(id: number): BasicSubTaskGroup | null {
    const group = this.subTaskGroups.find((g) => g.id === id);
    return group === undefined ? null : group;
  }

  /**
   * Register test steps.
   *
   * @param testPassage
   */
  public registerTestSteps(testPassage: TestPassage): void {
    for (const subTaskGroup of this.subTaskGroups) {
      testPassage.registerStep(subTaskGroup);
    }
  }

  /**
   * Return first test step for the task item.
   */
  public getTestStep(): TestStep {
    return this.subTaskGroups[0];
  }

  /**
   * Clear task item data, ie. it removes all results/data from task item.
   */
  public clearData(): void {
    for (const subTaskGroup of this.subTaskGroups) {
      subTaskGroup.clearData();
    }
  }

  /**
   * Restore all task item data, ie. it sets all results/data from data storage into task item.
   */
  public restoreData(dataStorage: DataStorage): void {
    for (const subTaskGroup of this.subTaskGroups) {
      subTaskGroup.restoreData(dataStorage);
    }
  }

  /**
   * Save all task item data (results, notes, ...) into data storage.
   */
  public storeData(dataStorage: DataStorage): void {
    for (const subTaskGroup of this.subTaskGroups) {
      subTaskGroup.storeData(dataStorage);
    }
  }
}
