import { SelectQuestion } from '@app/modules/test/models/note/select-question.model';
import { INoteOptionData } from '@app/modules/test/types';

/**
 * Class represent one option of select question.
 */
export class SelectOption {
  public constructor(
    public id: number,
    public title: string | null,
    public imageUrl: string | null,
    public order: number,
    selectQuestion: SelectQuestion
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param selectQuestion
   */
  public static deserialize(data: INoteOptionData, selectQuestion: SelectQuestion): SelectOption {
    return new SelectOption(data.id, data.title, data.image_url, data.order, selectQuestion);
  }

  /**
   * Return select option object serialized to basic javascript types.
   */
  public serialize(): INoteOptionData {
    return {
      id: this.id,
      title: this.title,
      image_url: this.imageUrl,
      order: this.order
    };
  }
}
