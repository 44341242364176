/* eslint @typescript-eslint/no-empty-function: 0 */

import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { Test } from '@app/modules/test/models/test/test.model';
import { TestPassage } from '@app/modules/test/models/test/test-passage.model';
import { ITestModuleData } from '@app/modules/test/types';

export abstract class TestModule {
  protected constructor(public id: number, public uuid: string, public test: Test, public order: number) {}

  /**
   * Clear module data, ie. it removes all results/data from module.
   *
   * Override in subclass, if there is any logic to clear data !
   */
  public clearData(): void {}

  /**
   * Restore all module data, ie. it sets all results/data from data storage into module.
   *
   * Override in subclass, if there is any logic to restore data !
   */
  public restoreData(_dataStorage: DataStorage): void {}

  /**
   * Save all module data (results, notes, ...) into data storage.
   *
   * Override in subclass, if there is any logic to get data !
   */
  public storeData(_dataStorage: DataStorage): void {}

  /**
   * Return test module object serialized to basic javascript types.
   */
  public abstract serialize(): ITestModuleData;

  /**
   * Register test steps.
   *
   * @param testPassage
   */
  public abstract registerTestSteps(testPassage: TestPassage): void;
}
