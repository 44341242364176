<div class="modal-header">
  <h5 class="modal-title">
    {{ data.title }}
  </h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <ng-container *ngIf="!inputValid">
    <ngb-alert type="danger" [dismissible]="false">
      {{ data.validationMessage }}
    </ngb-alert>
  </ng-container>
  <ng-container *ngIf="data.popUpType !== popUpType.PROMPT && data.popUpType !== popUpType.SELECT">
    <ng-container *ngIf="data.alertType !== null">
      <ngb-alert type="{{ data.alertType }}" [dismissible]="false">
        <p [innerHTML]="data.text"></p>
      </ngb-alert>
    </ng-container>
    <ng-container *ngIf="data.alertType === null">
      <p [innerHTML]="data.text"></p>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="data.popUpType === popUpType.PROMPT">
    <form name="modal-form">
      <div class="form-group">
        <label for="inputValue">{{ data.text }}</label>
        <div class="input-group">
          <input
            id="inputValue"
            class="form-control"
            [placeholder]="data.placeholder"
            name="inputName"
            [(ngModel)]="inputValue"
            (input)="inputValid = true"
            required
          />
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="data.popUpType === popUpType.SELECT">
    <form name="modal-form">
      <div class="form-group">
        <label for="inputValue">{{ data.text }}</label>
        <div class="input-group">
          <select id="inputValueSelect" class="form-control" name="selectname" [(ngModel)]="selectValue" required>
            <option *ngFor="let option of data.options" [value]="option[0]">
              {{ option[1] }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<div class="modal-footer">
  <button
    *ngIf="data.popUpType === popUpType.PROMPT || data.popUpType === popUpType.SELECT"
    class="ibtn {{ data.cancelButtonClass }} ibtn-small"
    (click)="closeModal(false)"
    tabindex="0"
  >
    {{ data.cancelText }}
  </button>
  <button
    *ngIf="data.popUpType === popUpType.PROMPT || data.popUpType === popUpType.SELECT"
    class="ibtn {{ data.okButtonClass }} ibtn-small"
    (click)="okPromptButton()"
    tabindex="0"
    [autofocus]="data.popUpType === popUpType.PROMPT || data.popUpType === popUpType.SELECT"
    ngbAutofocus
  >
    {{ data.okText }}
  </button>
  <button
    *ngIf="data.popUpType === popUpType.ALERT"
    class="ibtn ibtn-primary ibtn-small"
    (click)="closeModal(true)"
    tabindex="0"
    [autofocus]="data.popUpType === popUpType.ALERT"
    ngbAutofocus
  >
    {{ data.okText }}
  </button>
  <button
    *ngIf="data.popUpType === popUpType.CONFIRM"
    class="ibtn {{ data.noButtonClass }} ibtn-small"
    (click)="closeModal(false)"
    tabindex="0"
  >
    {{ data.noText }}
  </button>
  <button
    *ngIf="data.popUpType === popUpType.CONFIRM"
    class="ibtn {{ data.yesButtonClass }} ibtn-small"
    (click)="closeModal(true)"
    tabindex="0"
    [autofocus]="data.popUpType === popUpType.CONFIRM"
    ngbAutofocus
  >
    {{ data.yesText }}
  </button>
</div>
