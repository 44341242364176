import { TestEvaluationType } from '@app/modules/test/enums/test-evaluation-type.enum';
import { Category } from '@app/modules/test/models/general/category.model';
import { BasicEvaluationCategoryRule } from '@app/modules/test/models/test-evaluation/basic/basic-evaluation-category-rule.model';
import { BasicEvaluationRule } from '@app/modules/test/models/test-evaluation/basic/basic-evaluation-rule.model';
import { TestEvaluation } from '@app/modules/test/models/test-evaluation/test-evaluation.model';
import { Evaluation } from '@app/modules/test/models/test-result/evaluation.model';
import { TestResult } from '@app/modules/test/models/test-result/test-result.model';
import { ITestEvaluationData } from '@app/modules/test/types';

export class BasicTestEvaluation extends TestEvaluation {
  public categoryRules: Map<number, BasicEvaluationCategoryRule> = new Map<number, BasicEvaluationCategoryRule>();

  public constructor(public id: number, public testRule: BasicEvaluationRule | null, public generalRule: BasicEvaluationRule | null) {
    super();
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param testRule
   * @param generalRule
   */
  public static deserialize(
    data: ITestEvaluationData,
    testRule: BasicEvaluationRule | null,
    generalRule: BasicEvaluationRule | null
  ): BasicTestEvaluation {
    return new BasicTestEvaluation(data.id, testRule, generalRule);
  }

  /**
   * Return user object serialized to basic javascript types.
   */
  public serialize(): ITestEvaluationData {
    const categoryRules = [];
    this.categoryRules.forEach((value) => {
      categoryRules.push(value.serialize());
    });

    return {
      id: this.id,
      evaluation_type: TestEvaluationType.BASIC,
      test_rule: this.testRule ? this.testRule.serialize() : null,
      general_rule: this.generalRule ? this.generalRule.serialize() : null,
      category_rules: categoryRules
    };
  }

  /**
   * Add category evaluation rule into test evaluation.
   *
   * @param rule
   */
  public addCategoryRule(rule: BasicEvaluationCategoryRule): void {
    this.categoryRules.set(rule.category.id, rule);
  }

  /**
   * Evaluate test.
   *
   * @param testResult
   */
  public evaluate(testResult: TestResult): void {
    this.evaluateTest(testResult);
    this.evaluateCategories(testResult);
  }

  /**
   * Evaluate test result.
   *
   * @param testResult
   */
  protected evaluateTest(testResult: TestResult): void {
    const rule = this.getTestRule();
    if (rule === null) return;
    testResult.evaluation = this.evaluateRule(rule, testResult.score, testResult.maxScore);
  }

  /**
   * Evaluate all categories in the test result.
   *
   * @param testResult
   */
  protected evaluateCategories(testResult: TestResult): void {
    for (const categoryResult of testResult.categoryResults) {
      const rule = this.getCategoryRule(categoryResult.category);
      categoryResult.evaluation = this.evaluateRule(rule, categoryResult.score, categoryResult.maxScore);
    }
  }

  /**
   * Evaluate one score.
   *
   * @param rule
   * @param score
   * @param maxScore
   */
  protected evaluateRule(rule: BasicEvaluationRule, score: number, maxScore: number): Evaluation {
    for (const range of rule.ranges) {
      if (range.isInRange(score, maxScore)) {
        return new Evaluation(range.title, range.textColor, range.backgroundColor);
      }
    }
  }

  /**
   * Return rule to evaluate test.
   *
   * @return BasicEvaluationRule
   */
  protected getTestRule(): BasicEvaluationRule | null {
    return this.testRule;
  }

  /**
   * Return rule to evaluate category.
   *
   * @param category
   * @return BasicEvaluationRule
   */
  protected getCategoryRule(category: Category): BasicEvaluationCategoryRule | BasicEvaluationRule {
    const rule = this.categoryRules.get(category.id);
    return rule === undefined ? this.generalRule : rule;
  }
}
