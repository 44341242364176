/* eslint @typescript-eslint/no-var-requires: 0 */

/**
 * @field loginStatePersistingMaxHours is max hours of inactivity,
 *        when user is logout and is required to do new login
 * @field nextSyncTimeDelta is time in seconds to wait to do next auto sync,
 *        if there is no new data in app.
 * @field nextOnlineCheckDelta is time in seconds to wait to do next online check to server.
 */
export const commonEnvironment = {
  version: require('../../package.json').version,
  appKeyword: 'dad',
  loginStatePersistingMaxHours: 8,
  nextSyncTimeDelta: 15,
  nextOnlineCheckDelta: 15
};
