<ng-container *transloco="let t">
  <div class="row sync-data-alert justify-content-center" *ngIf="!synced">
    <div class="alert alert-info alert-dismissible" role="alert">
      <span [innerHtml]="t('dashboard/dataNotSynced')"></span>
      <app-user-sync
        ><button class="btn btn-link">{{ t('dashboard/send') }}</button></app-user-sync
      >.
    </div>
  </div>
</ng-container>
