import { ITestTaskPointData } from '@app/modules/test/types';

export class Point {
  public constructor(public title: string, public value: number, public color: string | null) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: ITestTaskPointData): Point {
    return new Point(data.title, data.value, data.color);
  }

  /**
   * Return point object serialized to basic javascript types.
   */
  public serialize(): ITestTaskPointData {
    return {
      title: this.title,
      value: this.value,
      color: this.color
    };
  }
}
