import { Component, OnInit } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { ApplicationRepository } from '@app/modules/application/services/application-repository.service';
import { PopUpComponent } from '@app/shared/components/pop-up/pop-up.component';
import { AlertType } from '@app/shared/enums/alert-type.enum';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'isophi-core';

@Component({
  selector: 'app-in-app-login',
  templateUrl: './in-app-login.component.html',
  styleUrls: ['./in-app-login.component.scss']
})
export class InAppLoginComponent implements OnInit {
  public modalHeadline = 'Ověření uživatele';

  public username = '';

  public password = '';

  public app: Application;

  public modalReference: NgbModalRef;

  public successMsg = 'Ověření proběhlo úspěšně.';

  public errorMsg = 'Ověření se nezdařilo. Prosím, zkontrolujte Vaše údaje, připojení na internet a zkuste to znovu.';

  public constructor(
    private appService: ApplicationService,
    private authService: AuthService,
    private popUp: PopUpComponent,
    private appRepository: ApplicationRepository
  ) {}

  public ngOnInit(): void {
    this.app = this.appService.getApplication();
    this.username = this.app.loggedUser.username;
  }

  public closeModal(): void {
    this.modalReference.dismiss();
  }

  /**
   * Authenticate user in auth server and save new access token.
   */
  public authenticate(): void {
    this.authService
      .authenticate(this.username, this.password, true)
      .then(() => {
        this.app.accessToken = this.authService.accessToken;
        this.appRepository.persistApp(this.appService.application);
        this.popUp.alert(this.modalHeadline, this.successMsg, AlertType.SUCCESS).then(() => {
          this.modalReference.close(true);
        });
      })
      .catch(() => {
        this.popUp.alert(this.modalHeadline, this.errorMsg, AlertType.WARNING);
      });
  }
}
