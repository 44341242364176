import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { Group } from '@app/modules/children/models/group.model';
import { IGroupData } from '@app/modules/children/types';

@Injectable({
  providedIn: 'root'
})
export class ChildrenGroupDeserializer {
  /**
   * Deserializer data into children Group class.
   *
   * It also associate group with children.
   *
   * @param app
   * @param groupData
   */
  public deserialize(app: Application, groupData: IGroupData): Group {
    const group = Group.deserialize(groupData);
    group.synced = Object.prototype.hasOwnProperty.call(groupData, 'synced') ? groupData.synced : true;
    const appGroup = app.getChildrenGroup(group.uuid);
    if (appGroup) {
      // We need to keep childrenSynced from app Group
      group.childrenSynced = appGroup.childrenSynced;
    }
    return group;
  }
}
