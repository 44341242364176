import { TaskItem } from '@app/modules/test/models/task/task-item.model';
import { ICategoryData } from 'isophi-core';

export class Category {
  public tasks: Array<TaskItem> = [];

  public constructor(
    public id: number,
    public uuid: string,
    public name: string,
    public activeIconUrl: string,
    public inactiveIconUrl: string,
    public color: string
  ) {}

  /**
   * Return number of task in the category.
   */
  public get taskCount(): number {
    return this.tasks.length;
  }

  /**
   * Return obtained score of all tasks in the group.
   */
  public get score(): number {
    return this.tasks.reduce((acc, item) => acc + item.score, 0);
  }

  /**
   * Return maximum possible score of this category.
   */
  public get maxScore(): number {
    return this.tasks.reduce((acc, item) => acc + item.maxScore, 0);
  }

  /**
   * Return obtained score (in percent) of all tasks in the group.
   */
  public get scorePercent(): number {
    return Math.round((this.score / this.maxScore) * 100);
  }

  /**
   * Return number of finished task in the category.
   */
  public get finishedTaskCount(): number {
    let count = 0;

    for (const task of this.tasks) {
      if (task.finished) count += 1;
    }

    return count;
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: ICategoryData): Category {
    return new Category(data.id, data.uuid, data.name, data.active_icon_url, data.inactive_icon_url, data.color);
  }

  /**
   * Return category object serialized to basic javascript types.
   */
  public serialize(): ICategoryData {
    return {
      id: this.id,
      uuid: this.uuid,
      name: this.name,
      active_icon_url: this.activeIconUrl,
      inactive_icon_url: this.inactiveIconUrl,
      color: this.color
    };
  }

  /**
   * Add task item into category.
   *
   * @param taskItem
   */
  public addTask(taskItem: TaskItem): void {
    this.tasks.push(taskItem);
  }

  /**
   * It updates category data with data of other category.
   *
   * @param other
   */
  public updateData(other: Category): void {
    this.id = other.id;
    this.uuid = other.uuid;
    this.name = other.name;
    this.activeIconUrl = other.activeIconUrl;
    this.inactiveIconUrl = other.inactiveIconUrl;
    this.color = other.color;
    this.tasks = other.tasks;
  }
}
