import { Child } from '@app/modules/children/models/child.model';
import { Group } from '@app/modules/children/models/group.model';
import { DataSetStatus } from '@app/modules/test/enums/data-set-status.enum';
import { Category } from '@app/modules/test/models/general/category.model';
import { Material } from '@app/modules/test/models/general/material.model';
import { DataSet } from '@app/modules/test/models/test/data-set.model';
import { Test } from '@app/modules/test/models/test/test.model';
import { Kindergarten, Logger, SubjectLicensesContract, User, UserRepository } from 'isophi-core';
import { BehaviorSubject, Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';

export class Application implements UserRepository {
  public static readonly STORAGE_KEY = 'dad-application';

  public childList: Array<Child> = [];

  public childrenGroupList: Array<Group> = [];

  public testList: Array<Test> = [];

  public userList: Array<User> = [];

  public categoryList: Array<Category> = [];

  public fileList: Set<string> = new Set<string>();

  public materialList: Array<Material> = [];

  public dataSets: Array<DataSet> = [];

  public childListChanged: Subject<null> = new Subject();

  public childrenGroupListChanged: Subject<null> = new Subject();

  public childArchiveList: Array<Child> = [];

  public dataSetArchive: Array<DataSet> = [];

  public childrenGroupArchive: Array<Group> = [];

  public loggedUser: User | null = null;

  public loggedKindergarten: Kindergarten | null = null;

  public licensesData: SubjectLicensesContract | null = null;

  public synced = false;

  public syncedChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public syncUuid: string | null = null;

  public accessToken: string | null = null;

  public lastEmailInReport: string | null = null;

  public source: string | null = null;

  /**
   * It will clear whole Application data.
   */
  public clear(): void {
    Logger.debug('Application :: clearing data.');

    this.childList.length = 0;
    this.childrenGroupList.length = 0;
    this.testList.length = 0;
    this.userList.length = 0;
    this.categoryList.length = 0;
    this.fileList.clear();
    this.materialList.length = 0;
    this.dataSets.length = 0;
    this.licensesData = null;
    this.childArchiveList.length = 0;
    this.childrenGroupArchive.length = 0;
    this.dataSetArchive.length = 0;
    this.loggedKindergarten = null;
    this.loggedUser = null;
    this.synced = false;
    this.syncedChanged.next(this.synced);
    this.syncUuid = null;
    this.accessToken = null;
    this.lastEmailInReport = null;
    this.source = null;
  }

  /**
   * It will clear Application data associated with users.
   *
   * Use this method BEFORE you update users from server. (This method is useful during update users for offline login.)
   */
  public clearUsers(): void {
    this.userList.length = 0;
  }

  /**
   * It will clear Application data associated with logged user.
   * Use it for user log out.
   */
  public clearLoggedUser(): void {
    localStorage.removeItem('last-activity-timestamp');
    this.loggedUser = null;
  }

  /**
   * Set application as synced.
   */
  public setSynced(): void {
    this.synced = true;
    this.syncedChanged.next(this.synced);
  }

  /**
   * Add new child to the application. Use this method when CREATING new child in the DAD app.
   *
   * Method DOES NOT check for duplication, you should call getSimilarChildren()
   * if you want to prevent duplicated children.
   *
   * @param child
   * @param generateUUID
   * @return boolean
   */
  public addNewChild(child: Child, generateUUID: boolean = false): boolean {
    if (generateUUID) {
      child.uuid = uuid();
    }

    this.childList.push(child);
    this.childChanged();
    this.synced = false;
    this.syncedChanged.next(this.synced);

    return true;
  }

  /**
   * It updates child data if child already in the Application, or insert new child.
   * Use this method when syncing with server.
   *
   * @param child
   */
  public updateChild(child: Child): void {
    const oldChild = this.getChild(child.uuid);
    if (oldChild) {
      oldChild.replaceData(child, false);
    } else {
      this.childList.push(child);
    }
  }

  /**
   * It updates children group data if group already in the Application, or insert new group.
   * Use this method when syncing with server.
   *
   * @param group
   */
  public updateChildrenGroup(group: Group): void {
    const oldGroup = this.getChildrenGroup(group.uuid);
    if (oldGroup) {
      oldGroup.replaceData(group, false);
    } else {
      this.childrenGroupList.push(group);
    }
  }

  /**
   * It updates user in the Application or add new one.
   *
   * @param user
   */
  public updateUser(user: User): void {
    const oldUser = this.getUser(user.id);
    if (oldUser) {
      oldUser.updateData(user, true);
    } else {
      this.userList.push(user);
    }
  }

  /**
   * It updates test in the Application or add new on.
   *
   * @param test
   */
  public updateTest(test: Test): void {
    const oldTest = this.getTest(test.id);
    if (oldTest) {
      oldTest.updateData(test);
    } else {
      // Keep the array sorted DESC by max age
      let i = 0;
      for (; i < this.testList.length; i++) {
        if (this.testList[i].maxAge < test.maxAge) break;
      }

      // Keep multicategory tests in front of specific tests (but still ordered by age)
      if (i > 0 && test.isMultiCategoriesTest()) {
        while (i > 0 && this.testList[i - 1].maxAge === test.maxAge && !this.testList[i - 1].isMultiCategoriesTest()) {
          i--;
        }
      }

      this.testList.splice(i, 0, test);
    }
  }

  /**
   * It updates data set in the Application or add new on.
   *
   * @param dataSet
   */
  public updateDataSet(dataSet: DataSet): void {
    const oldDataSet = this.getDataSet(dataSet.uuid);
    if (oldDataSet) {
      oldDataSet.updateData(dataSet);
    } else {
      this.dataSets.push(dataSet);
    }
  }

  /**
   * It updates material in the Application or add new on.
   *
   * @param material
   */
  public updateMaterial(material: Material): void {
    const oldMaterial = this.getMaterial(material.id);
    if (oldMaterial) {
      oldMaterial.updateData(material);
    } else {
      this.materialList.push(material);
    }
  }

  /**
   * It updates category in the Application or add new on.
   *
   * @param category
   */
  public updateCategory(category: Category): void {
    const oldCategory = this.getCategory(category.id);
    if (oldCategory) {
      oldCategory.updateData(category);
    } else {
      this.categoryList.push(category);
    }
  }

  /**
   * Call this method, when any Child in the Application was changed.
   *
   * It sends next value in childListChanged to notify all observers,
   * and sort group list.
   */
  public childChanged(setNotSynced: boolean = true): void {
    this.sortChildList();
    this.childListChanged.next(null);
    if (setNotSynced) {
      this.synced = false;
      this.syncedChanged.next(this.synced);
    }
  }

  /**
   * Call this method, when any children group in the Application was changed.
   *
   * It sends next value in childrenGroupListChanged to notify all observers,
   * and sort group list.
   */
  public childrenGroupChanged(): void {
    this.sortChildrenGroupList();
    this.childrenGroupListChanged.next(null);
    this.synced = false;
    this.syncedChanged.next(this.synced);
  }

  /**
   *  Return similar children.
   *
   *  Skip itself if child already in childList.
   *
   * @param child
   */
  public getSimilarChildren(child: Child): Array<Child> {
    return this.childList.filter((x) => x.uuid !== child.uuid && x.compare(child));
  }

  /**
   * Set new child list.
   *
   * This method should be called only during app initialization.
   *
   * @param childList
   */
  public setChildList(childList: Array<Child>): void {
    this.childList = childList;
    this.sortChildList();
  }

  /**
   * Return test or undefined if test not found.
   *
   * @param id
   */
  public getTest(id: number): Test | undefined {
    return this.testList.find((x) => x.id === id);
  }

  /**
   * Return test or undefined if test not found.
   *
   * @param uuid
   */
  public getTestByUuid(testUuid: string): Test | undefined {
    return this.testList.find((x) => x.uuid === testUuid);
  }

  /**
   * Return category or undefined if category not found.
   *
   * @param id
   * @param clone   True to return clone of original Category class.
   */
  public getCategory(id: number, clone: boolean = false): Category | undefined {
    let category: Category | undefined = this.categoryList.find((x) => x.id === id);
    if (category !== undefined && clone) {
      category = new Category(category.id, category.uuid, category.name, category.activeIconUrl, category.inactiveIconUrl, category.color);
    }
    return category;
  }

  /**
   * Return material or undefined if material is not found.
   *
   * @param id
   */
  public getMaterial(id: number): Material | undefined {
    return this.materialList.find((x) => x.id === id);
  }

  /**
   * Return user or undefined if user is not found.
   *
   * @param id
   */
  public getUser(id: number): User | undefined {
    return this.userList.find((x) => x.id === id);
  }

  /**
   * Return user or undefined if user is not found.
   *
   * @param username
   * @param caseSensitive
   */
  public getUserByUsername(username: string, caseSensitive?: boolean): User | undefined {
    if (caseSensitive === false) {
      username = username.toLowerCase();
      return this.userList.find((x) => x.username.toLowerCase() === username);
    } else {
      return this.userList.find((x) => x.username === username);
    }
  }

  /**
   * Return child or undefined if child is not found.
   *
   * @param childUuid
   */
  public getChild(childUuid: string): Child | undefined {
    return this.childList.find((x) => x.uuid === childUuid);
  }

  /**
   * Return children group or undefined if group is not found.
   *
   * @param childrenGroupUuid
   */
  public getChildrenGroup(childrenGroupUuid: string): Group | undefined {
    return this.childrenGroupList.find((x) => x.uuid === childrenGroupUuid);
  }

  /**
   * Return data set or undefined if data set is not found.
   *
   * @param dataSetUuid
   */
  public getDataSet(dataSetUuid: string): DataSet | undefined {
    return this.dataSets.find((x) => x.uuid === dataSetUuid);
  }

  /**
   * Add data set into application and set application as not synced.
   *
   * @param dataSet
   */
  public addDataSet(dataSet: DataSet): void {
    this.dataSets.push(dataSet);
    this.synced = false;
    this.syncedChanged.next(this.synced);
  }

  /**
   * Remove child from application.
   * Safe to call when child not in the application.
   *
   * @param childUuid
   * @param skipSync      True/False if skip sync of removed child.
   * @param keepDataSets  True/False if keep data sets (test results) of removed child.
   * @return boolean      Return true/false if removed child was already synced to the server.
   */
  public removeChild(childUuid: string, skipSync: boolean = false, keepDataSets: boolean = false): boolean {
    const index: number = this.childList.findIndex((ch) => ch.uuid === childUuid);
    if (index === -1) return false;

    const child = this.childList[index];
    this.childList.splice(index, 1);
    child.groups.forEach((group) => group.removeChild(child));
    if (keepDataSets) {
      child.dataSets.forEach((dataSet) => (dataSet.child = null));
    } else {
      child.dataSets.forEach((dataSet) => this.removeDataSet(dataSet.uuid, true));
    }
    this.childChanged();

    if (child.isSyncedToServer() && !skipSync) {
      this.childArchiveList.push(child);
      this.synced = false;
      this.syncedChanged.next(this.synced);
    }

    return child.isSyncedToServer();
  }

  /**
   * Remove children group from application.
   * Safe to call when children group not in the application.
   *
   * @param groupUuid
   * @param skipSync    True/False if skip sync of removed child.
   * @return boolean    Return true/false if removed children group was already synced to the server.
   */
  public removeChildrenGroup(groupUuid: string, skipSync: boolean = false): boolean {
    const index: number = this.childrenGroupList.findIndex((g) => g.uuid === groupUuid);
    if (index === -1) return false;

    const group = this.childrenGroupList[index];
    this.childrenGroupList.splice(index, 1);
    group.children.forEach((child) => child.removeGroup(group));
    this.childrenGroupChanged();

    if (group.isSyncedToServer() && !skipSync) {
      this.childrenGroupArchive.push(group);
      this.synced = false;
      this.syncedChanged.next(this.synced);
    }

    return group.isSyncedToServer();
  }

  /**
   * Remove data set from application.
   * Safe to call when dataSet not in the application.
   *
   * @param dataSetUuid
   * @param skipSync    True/False if skip sync of removed child.
   * @return boolean    Return true/false if removed data set was already synced to the server.
   */
  public removeDataSet(dataSetUuid: string, skipSync: boolean = false): boolean {
    const index: number = this.dataSets.findIndex((ds) => ds.uuid === dataSetUuid);
    if (index === -1) return false;

    const dataSet = this.dataSets[index];
    this.dataSets.splice(index, 1);
    dataSet.child.removeDataSet(dataSet);

    if (dataSet.isSyncedToServer() && !skipSync) {
      dataSet.status = DataSetStatus.ARCHIVED;
      this.dataSetArchive.push(dataSet);
      this.synced = false;
      this.syncedChanged.next(this.synced);
    }

    return dataSet.isSyncedToServer();
  }

  /**
   * Remove all data sets without child.
   */
  public removeDataSetsWithoutChild(): void {
    this.dataSets = this.dataSets.filter((dataSet) => dataSet.child !== null);
  }

  /**
   * Remove test from application.
   * Save to call when test not in the application.
   *
   * @param testId
   */
  protected removeTest(testId: number): void {
    const index: number = this.testList.findIndex((t) => t.id === testId);
    if (index !== -1) this.testList.splice(index, 1);
  }

  /**
   * Remove user from application.
   * Save to call when user not in the application.
   *
   * @param userId
   */
  protected removeUser(userId: number): void {
    const index: number = this.userList.findIndex((u) => u.id === userId);
    if (index !== -1) this.userList.splice(index, 1);
  }

  /**
   * Remove material from application.
   * Save to call when material not in the application.
   *
   * @param materialId
   */
  protected removeMaterial(materialId: number): void {
    const index: number = this.materialList.findIndex((m) => m.id === materialId);
    if (index !== -1) this.materialList.splice(index, 1);
  }

  /**
   * Remove category from application.
   * Save to call when category not in the application.
   *
   * @param categoryId
   */
  protected removeCategory(categoryId: number): void {
    const index: number = this.categoryList.findIndex((c) => c.id === categoryId);
    if (index !== -1) this.categoryList.splice(index, 1);
  }

  /**
   * Sort childList by full name.
   */
  private sortChildList() {
    this.childList.sort((a, b) => {
      const nameA = a.surname.toLowerCase() + a.name.toLowerCase();
      const nameB = b.surname.toLowerCase() + b.name.toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }

  /**
   * Sort list with children groups.
   */
  private sortChildrenGroupList() {
    this.childrenGroupList.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  }
}
