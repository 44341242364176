import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { DownloadWorkerService } from '@app/modules/sync/services/download-worker.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationDeserializer {
  public constructor(protected downloadWorker: DownloadWorkerService) {}

  public deserialize(app: Application, data: any): void {
    this.downloadWorker.processAllData(app, data);
    app.synced = data.synced;
    app.syncedChanged.next(app.synced);
    app.syncUuid = Object.prototype.hasOwnProperty.call(data, 'sync_uuid') ? data.sync_uuid : null;
    app.accessToken = data.access_token;
    app.lastEmailInReport = data.last_email_in_report;
    this.downloadWorker.processDataSetsArchiveData(app, data.data_sets_archive);
    this.downloadWorker.processChildArchiveData(app, data.child_archive);
    this.downloadWorker.processChildrenGroupArchiveData(app, data.children_group_archive);
  }
}
