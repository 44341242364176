import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Child } from '@app/modules/children/models/child.model';
import { Logger } from 'isophi-core';

import { SidebarService } from './sidebar.service';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {
  public constructor(private router: Router, private location: Location, private sidebarService: SidebarService) {}

  /**
   * Close main menu (sidenav)
   */
  public closeSidenav(): void {
    this.sidebarService.close();
  }

  /**
   * Toggle main menu (sidenav)
   */
  public toggleSidenav(): void {
    this.sidebarService.toggle();
  }

  /**
   * It navigate application to path.
   *
   * @param path
   */
  public goTo(path: string): void {
    this.closeSidenav();
    Logger.debug(`NavigateService :: navigating to ${path}.`);
    this.router.navigate([path]);
  }

  /**
   * Navigate app to test module.
   */
  public goToTest(action: string, uuid: string, childUuid: string, step: number = 0): void {
    this.closeSidenav();
    Logger.debug('NavigateService :: navigating to /test.');
    this.router.navigate([`/test/${action}/${uuid}/${childUuid}/${step}`]);
  }

  /**
   * Navigate app to help module.
   */
  public goToHelp(): void {
    this.closeSidenav();
    Logger.debug('NavigateService :: navigating to /help.');
    this.router.navigate(['/help']);
  }

  public goToDashboard(): void {
    this.closeSidenav();
    Logger.debug('NavigateService :: navigating to /dashboard.');
    this.router.navigate(['/dashboard']);
  }

  /**
   * Go to page for syncing data.
   */
  public goToSync(): void {
    this.closeSidenav();
    Logger.debug('NavigateService :: navigating to /sync.');
    this.router.navigate(['/sync']);
  }

  /**
   * Go to page for auto-syncing data.
   */
  public goToAutoSync(): void {
    this.closeSidenav();
    Logger.debug('NavigateService :: navigating to /sync/auto.');
    this.router.navigate(['/sync/auto']);
  }

  public goToLogin(): void {
    this.closeSidenav();
    Logger.debug('NavigateService :: navigating to /login.');
    this.router.navigate(['/login']);
  }

  /**
   * Navigate to component, which do kindergarten logout and redirect to login.
   */
  public goToKindergartenLogout(): void {
    this.closeSidenav();
    Logger.debug('NavigateService :: navigating to /login/kindergarten-logout.');
    this.router.navigate(['/login/kindergarten-logout']);
  }

  /**
   * Navigate to component, which do user logout and redirect to login.
   */
  public goToUserLogout(): void {
    this.closeSidenav();
    Logger.debug('NavigateService :: navigating to /login/user-logout.');
    this.router.navigate(['/login/user-logout']);
  }

  public goToChildList(child?: Child, reload = true): void {
    this.closeSidenav();
    Logger.debug('NavigateService :: navigating to /children.');

    if (child && reload) {
      this.router.navigate([`/children/${child.uuid}`]);
    } else if (child && !reload) {
      this.location.replaceState(`/children/${child.uuid}`);
    } else {
      this.router.navigate([`/children`]);
    }
  }

  public goToTestList(): void {
    this.closeSidenav();
    Logger.debug('NavigateService :: navigating to /test/list.');
    this.router.navigate(['/test/list']);
  }

  public goToForgottenPassword(): void {
    this.closeSidenav();
    Logger.debug('NavigateService :: navigating to /login/forgotten-password.');
    this.router.navigate(['/login/forgotten-password']);
  }
}
