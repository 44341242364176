import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { IMaterialData } from '@app/modules/sync/types';
import { Material } from '@app/modules/test/models/general/material.model';

@Injectable({
  providedIn: 'root'
})
export class MaterialDeserializer {
  public deserialize(app: Application, data: IMaterialData): Material {
    const category = app.getCategory(data.category_id);
    return Material.deserialize(data, category);
  }
}
