import { Test } from '@app/modules/test/models/test/test.model';
import { TestStep } from '@app/modules/test/models/test/test-step.interface';

/**
 * Class represent passage in the test.
 */
export class TestPassage {
  public steps: Array<TestStep> = [];

  public activeStepIndex = 0;

  public initialisedSteps: Array<TestStep> = [];

  public constructor(public test: Test) {}

  /**
   * Return active step.
   */
  public get activeStep(): TestStep {
    return this.steps[this.activeStepIndex];
  }

  /**
   * Register new step into test passage.
   *
   * @param step
   */
  public registerStep(step: TestStep): void {
    this.steps.push(step);
  }

  /**
   * Activate step by index.
   *
   * @param index
   */
  public activateStepByIndex(index: number): void {
    this.activeStepIndex = index;
    this.initStep(this.activeStep);
    this.activeStep.activateStep(this);
  }

  /**
   * Activate step by ID.
   *
   * @param stepId
   */
  public activateStepById(stepId: string): void {
    const step = this.steps.find((x) => x.getStepId() === stepId);
    if (step === undefined) return;

    this.activeStepIndex = this.steps.indexOf(step);
    this.initStep(this.activeStep);
    this.activeStep.activateStep(this);
  }

  /**
   * Return true/false if test has next step.
   */
  public hasNextStep(): boolean {
    const lastIndex = this.steps.length - 1;
    return this.activeStepIndex < lastIndex;
  }

  /**
   * Return true/false if test has previous step.
   */
  public hasPreviousStep(): boolean {
    return this.activeStepIndex > 0;
  }

  /**
   * Move test state to next step. Return true/false if activation was ok, i.e. there was next step.
   */
  public nextStep(): boolean {
    const nextIndex = this.activeStepIndex + 1;
    if (nextIndex >= this.steps.length) return false;
    this.activeStepIndex = nextIndex;
    return true;
  }

  /**
   * Move test state to previous step. Return true/false if activation was ok, i.e. there was previous step.
   */
  public previousStep(): boolean {
    const previousIndex = this.activeStepIndex - 1;
    if (previousIndex < 0) return false;
    this.activeStepIndex = previousIndex;
    return true;
  }

  public getStepIndex(stepId: string): number {
    const step = this.steps.find((x) => x.getStepId() === stepId);
    return this.steps.indexOf(step);
  }

  /**
   * If initialise test step, if visited for the first time.
   *
   * @param testStep
   */
  protected initStep(testStep: TestStep): void {
    const alreadyInitialised = this.initialisedSteps.find((x) => x === testStep);
    if (alreadyInitialised === undefined) {
      testStep.initStep(this);
      this.initialisedSteps.push(testStep);
    }
  }
}
