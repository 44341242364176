import { Injectable } from '@angular/core';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { DownloadWorkerService } from '@app/modules/sync/services/download-worker.service';
import { environment } from '@env/environment';
import { HttpService } from 'isophi-core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  public constructor(
    private httpService: HttpService,
    protected appService: ApplicationService,
    protected downloadWorker: DownloadWorkerService
  ) {}

  /**
   * Download and update users data in Application.
   */
  public downloadUsers(syncUuid: string): Promise<void> {
    const app = this.appService.application;
    const usersUrl = this.getSyncUrl(syncUuid, 'users');

    return this.httpService
      .get(usersUrl, app.accessToken)
      .toPromise()
      .then((response) => {
        app.clearUsers();
        this.downloadWorker.processUsersData(app, response.body);
      });
  }

  /**
   * Download data from server.
   */
  public download(syncUuid: string): Promise<void> {
    const app = this.appService.application;
    const url = this.getSyncUrl(syncUuid, 'full-download');

    return this.httpService
      .get(url, app.accessToken)
      .toPromise()
      .then((response) => this.downloadWorker.processAllData(app, response.body));
  }

  /**
   * Return DAD portal URL for sync endpoint.
   *
   * @param syncUuid
   * @param endpoint
   */
  protected getSyncUrl(syncUuid: string, endpoint: string): string {
    return `${environment.dadAPI}/sync/${syncUuid}/${endpoint}/`;
  }
}
