import { AfterViewInit, Component, EmbeddedViewRef, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { NavigateService } from '@app/core';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { AutoSyncService } from '@app/modules/sync/services/auto-sync.service';
import { TestService } from '@app/modules/test/services/test.service';
import { PopUpComponent } from '@app/shared/components/pop-up/pop-up.component';
import { MenuService } from '@app/shared/services/menu.service';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnDestroy, AfterViewInit {
  @ViewChild('vcr', { read: ViewContainerRef }) public vcr: ViewContainerRef;

  public appVersion = '';

  public envShortcut = '';

  public _current: EmbeddedViewRef<any> | null = null;

  public subscriptions: Subscription = new Subscription();

  public constructor(
    protected testService: TestService,
    private navigateService: NavigateService,
    private router: Router,
    protected menuService: MenuService,
    protected popUp: PopUpComponent,
    protected appService: ApplicationService,
    protected autoSyncService: AutoSyncService
  ) {
    this.appVersion = environment.common.version;
    this.envShortcut = environment.envShortcut;
  }

  public ngAfterViewInit(): void {
    const menuSubscription = this.menuService.contents.subscribe((ref) => {
      if (this._current !== null) {
        this._current.destroy();
        this._current = null;
      }
      if (ref === null) {
        return;
      }
      this._current = this.vcr.createEmbeddedView(ref);
    });
    this.subscriptions.add(menuSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public toggleSidenav(): void {
    this.navigateService.toggleSidenav();
  }

  /**
   * If there is running test for child, ask for saving test state.
   *
   * @return true if continue in navigation, false if if stay in test.
   */
  public saveTestConfirm(): Promise<boolean> {
    if (!this.isTestRunning()) return Promise.resolve(true);

    if (!this.isTestingChild() && !this.isEditingChild()) {
      this.testService.cancelTest();
      return Promise.resolve(true);
    }

    if (this.isEditingChild()) {
      return this.popUp
        .confirm('Uložit změny?', 'Odcházíte z úprav diagnostiky, přejete si změny uložit?', null, 'Uložit změny', 'Zahodit změny')
        .then((value) => {
          if (value === true) {
            this.testService.finishTest();
            return true;
          }
          if (value === false) {
            this.testService.cancelTest();
            return true;
          }
          return false;
        });
    } else {
      return this.popUp
        .confirm(
          'Uložit diagnostiku?',
          'Odcházíte z rozpracované diagnostiky, přejete si ji uložit?',
          null,
          'Uložit rozpracované',
          'Zahodit'
        )
        .then((value) => {
          if (value === true) {
            this.testService.pauseTest();
            return true;
          }
          if (value === false) {
            this.testService.cancelTest();
            return true;
          }
          return false;
        });
    }
  }

  /**
   * Redirect to dashboard.
   */
  public goToDashboard(): void {
    this.saveTestConfirm().then((value) => {
      if (value) {
        this.autoSyncService.destinationPath = '/dashboard';
        this.navigateService.goToAutoSync();
      }
    });
  }

  /**
   * Redirect do children list.
   */
  public goToChildList(): void {
    let testedChild = null;
    if (this.testService.getRunningTest()) {
      testedChild = this.testService.getRunningTest().testingChild;
    }

    this.saveTestConfirm().then((value) => {
      if (value) {
        if (testedChild) {
          this.autoSyncService.destinationPath = `/children/${testedChild.uuid}`;
        } else {
          this.autoSyncService.destinationPath = '/children';
        }
        this.navigateService.goToAutoSync();
      }
    });
  }

  /**
   * Redirect to test list.
   */
  public goToTestList(): void {
    this.saveTestConfirm().then((value) => {
      if (value) {
        this.autoSyncService.destinationPath = '/test/list';
        this.navigateService.goToAutoSync();
      }
    });
  }

  /**
   * Function which print Application class into console for debugging.
   *
   * Work only if production = False in environment.
   * In production do nothing.
   */
  public debugPrintApp(): void {
    if (environment.production) return;
    console.log(this.appService.application);
  }

  /**
   * Check if application is in test module and testing mode.
   */
  protected isTestingChild(): boolean {
    const test = this.testService.getRunningTest();
    const currentUrl = this.router.url;
    return currentUrl.startsWith('/test') && test !== null && test.isTestingMode();
  }

  /**
   * Check if application is in test module and editing mode.
   */
  protected isEditingChild(): boolean {
    const test = this.testService.getRunningTest();
    const currentUrl = this.router.url;
    return currentUrl.startsWith('/test') && test !== null && test.isEditingMode();
  }

  /**
   * Check if application is in test module.
   */
  protected isTestRunning(): boolean {
    const test = this.testService.getRunningTest();
    const currentUrl = this.router.url;
    return currentUrl.startsWith('/test') && test !== null && test.running;
  }
}
