import { Test } from '@app/modules/test/models/test/test.model';
import { TestModule } from '@app/modules/test/models/test/test-module.model';
import { TestPassage } from '@app/modules/test/models/test/test-passage.model';
import { TestStep } from '@app/modules/test/models/test/test-step.interface';
import { IResultModuleData } from '@app/modules/test/types';

export class ResultTestModule extends TestModule implements TestStep {
  public constructor(id: number, uuid: string, test: Test, public type: string, public text: string | null, order: number) {
    super(id, uuid, test, order);
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param test
   */
  public static deserialize(data: IResultModuleData, test: Test): ResultTestModule {
    return new ResultTestModule(data.id, data.uuid, test, data.type, data.text, data.order);
  }

  /**
   * Return test module object serialized to basic javascript types.
   */
  public serialize(): IResultModuleData {
    return {
      id: this.id,
      uuid: this.uuid,
      type: this.type,
      text: this.text,
      order: this.order,
      module_type: 'result'
    };
  }

  /**
   * Register result step.
   *
   * @param testPassage
   */
  public registerTestSteps(testPassage: TestPassage): void {
    testPassage.registerStep(this);
  }

  /**
   * Init test module step.
   */
  public initStep(_testPassage: TestPassage): void {
    // Nothing to do.
  }

  /**
   * Activate test module step.
   */
  public activateStep(testPassage: TestPassage): void {
    // disable active category, test result does not have category.
    testPassage.test.activeCategory = null;
  }

  /**
   * Return route to display test result.
   */
  public getRoute(uuid: string, mode: string, childUuid: string, step: number): string {
    return `/test/${mode}/${uuid}/${childUuid}/${step}/result`;
  }

  /**
   * Return that results are displayable.
   */
  public isDisplayable(): boolean {
    return true;
  }

  /**
   * Return string uniquely identifying test step.
   *
   * @return string
   */
  public getStepId(): string {
    return 'test#result';
  }
}
