import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpService } from 'isophi-core';
import { BehaviorSubject } from 'rxjs';

import { Application } from '../models/application.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  application: Application = null;

  lastOnlineCheckDate: Date | null = null;

  lastOnlineCheckValue: boolean | null = null;

  appInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(protected httpService: HttpService) {}

  /**
   * Return application instance.
   */
  public getApplication(): Application {
    return this.application;
  }

  /**
   * Check if app is online and server is available.
   *
   * Result is cached for ${environment.constructor.nextOnlineCheckDelta} seconds.
   */
  public isOnline(notCached: boolean = false): Promise<boolean> {
    // cache result for last ${environment.constructor.nextOnlineCheckDelta} seconds
    if (!notCached && this.lastOnlineCheckDate) {
      const now = new Date();
      const secondsElapsed = Math.round((+now - +this.lastOnlineCheckDate) / 1000);
      if (secondsElapsed <= environment.common.nextOnlineCheckDelta) {
        return Promise.resolve(this.lastOnlineCheckValue);
      }
    }

    const url = environment.dadAPI + '/general/check-server/';

    return this.httpService
      .get(url)
      .toPromise()
      .then((response) => response.status === 200 && response.body.status === 'ok')
      .catch(() => false)
      .then((isOnline) => {
        this.lastOnlineCheckValue = isOnline;
        this.lastOnlineCheckDate = new Date();
        return this.lastOnlineCheckValue;
      });
  }
}
