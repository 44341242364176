<div class="dropdown header-flags">
  <a class="nav-link icon" data-toggle="dropdown" aria-expanded="false">
    <img src="../assets/img/flags/{{ i18n.getActiveLang() }}.svg" class="h-24" alt="img" />
  </a>
  <div class="dropdown-menu dropdown-menu-end">
    <a href="javascript:void(0);" (click)="i18n.setActiveLang('cs')" class="dropdown-item d-flex">
      <span class="avatar me-3 align-self-center bg-transparent"><img src="../assets/img/flags/cs.svg" alt="img" class="h-24" /></span>
      <div class="d-flex"><span class="my-auto">Česky</span></div>
    </a>
    <a href="javascript:void(0);" (click)="i18n.setActiveLang('en')" class="dropdown-item d-flex">
      <span class="avatar me-3 align-self-center bg-transparent"><img src="../assets/img/flags/en.svg" alt="img" class="h-24" /></span>
      <div class="d-flex"><span class="my-auto">English</span></div>
    </a>
  </div>
</div>
