import { Injectable } from '@angular/core';
import { InAppLoginComponent } from '@app/modules/login/components/in-app-login/in-app-login.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public constructor(private spinner: NgxSpinnerService, private modalService: NgbModal) {}

  /**
   * Show modal with in-app login to refresh access token.
   *
   * On success new access token is saved in the Application instance.
   *
   * @protected
   * @returns
   */
  public showInAppLogin(): Promise<void> {
    this.spinner.hide();
    const modal: NgbModalRef = this.modalService.open(InAppLoginComponent);
    modal.componentInstance.modalReference = modal;
    return modal.result
      .then(() => {
        this.spinner.show();
      })
      .catch(() => {
        throw Error('Invalid in-app login.');
      });
  }
}
