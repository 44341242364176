// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commonEnvironment } from '@env/environment.common';

import { LoggingLevel } from './log-level.enum';

export const environment = {
  envShortcut: 'D',
  production: false,
  logLevel: LoggingLevel.DEBUG,
  common: commonEnvironment,
  client_id: 'PsC2jzIZDgiYqOIB8O6E88yanbmpvk43PDiclAys',
  grant_type: 'password',
  ga_measurement_id: '',
  replaysSampleRate: 0,
  tracesSampleRate: 0,

  authAPI: 'https://api.isophi-auth.doc.endevel.cz/api/v1',
  dadAPI: 'https://api.isophi-dad.doc.endevel.cz/api/v1',

  materialServer: 'https://api.isophi-material.doc.endevel.cz',

  sentryDSN: 'https://8e30e36202d049a7a542ae850b315502@o1002766.ingest.sentry.io/4505051930165248'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
