import { Component, Input } from '@angular/core';
import { NavigateService } from '@app/core';
import { Child } from '@app/modules/children/models/child.model';
import { FileService } from '@app/modules/file/services/file.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() public icon: string = null;

  @Input() public corner: string = null;

  @Input() public color: string = null;

  @Input() public title: string = null;

  @Input() public subtitle: string = null;

  @Input() public menu = true;

  @Input() public testingChild: Child | null = null;

  @Input() public secondTitle: string | null = null;

  public constructor(protected navigateService: NavigateService, protected fileService: FileService) {}

  public toggleSidenav(): void {
    this.navigateService.toggleSidenav();
  }

  /**
   * Convert URL for use in HTML.
   *
   * @param url
   */
  public convertURL(url: string): string {
    return this.fileService.convertURL(url);
  }
}
