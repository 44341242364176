import { Injectable } from '@angular/core';
import { IStorage } from '@app/modules/application/models/istorage.model';
import { LocalStorage } from '@app/modules/application/models/storages/local-storage.model';
import { NullStorage } from '@app/modules/application/models/storages/null-storage.model';
import { SystemService } from 'isophi-core';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root'
})
export class StorageFactory {
  protected storage: IStorage | null = null;

  public constructor(protected sysService: SystemService, private dbService: NgxIndexedDBService) {}

  /**
   * Return storage.
   */
  public getStorage(): IStorage {
    if (this.storage === null) {
      this.storage = this.createStorage();
    }

    return this.storage;
  }

  /**
   * Create new instance of storage.
   */
  protected createStorage(): IStorage {
    // todo(doubravskytomas): For testing, there is always used local storage.
    return new LocalStorage(this.dbService);

    if (this.sysService.isCordova()) {
      return new LocalStorage(this.dbService);
    } else {
      return new NullStorage();
    }
  }
}
