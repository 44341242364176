import { IMaterialData } from '@app/modules/sync/types';

import { MaterialType } from '../../enums/material-type.enum';
import { Category } from './category.model';

export class Material {
  public constructor(
    public id: number,
    public uuid: string,
    public title: string,
    public category: Category,
    public types: MaterialType[],
    public imagesUrl: string[]
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param category
   */
  public static deserialize(data: IMaterialData, category: Category): Material {
    return new Material(data.id, data.uuid, data.name, category, data.types, data.image_urls);
  }

  /**
   * Return materials object serialized to basic javascript types.
   */
  public serialize(): IMaterialData {
    return {
      id: this.id,
      uuid: this.uuid,
      name: this.title,
      category_id: this.category.id,
      types: this.types,
      image_urls: this.imagesUrl
    };
  }

  /**
   * It updates material data with data of other material.
   *
   * @param other
   */
  public updateData(other: Material): void {
    this.id = other.id;
    this.uuid = other.uuid;
    this.title = other.title;
    this.category = other.category;
    this.types = other.types;
    this.imagesUrl = other.imagesUrl;
  }
}
