import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { IChildrenInGroupData, IGroupData } from '@app/modules/children/types';
import { IAppUploadData, IAppUploadResponseData, IIdentityData, IVersionedIdentityData } from '@app/modules/sync/types';
import { IDataSetData } from '@app/modules/test/types';
import { IChildData } from 'isophi-core';

@Injectable({
  providedIn: 'root'
})
export class UploadWorkerService {
  /**
   * Prepare data for uploading new children.
   *
   * @param app
   */
  public prepareNewChildrenData(app: Application): IChildData[] | null {
    const children = [];

    for (const child of app.childList) {
      // we want only new children
      if (child.synced || child.id !== null) continue;

      children.push(child.serialize());
    }

    if (children.length === 0) return null;
    return children;
  }

  /**
   * Prepare data for uploading new children groups.
   *
   * @param app
   */
  public prepareNewChildrenGroupsData(app: Application): IGroupData[] | null {
    const groups = [];

    for (const group of app.childrenGroupList) {
      // we want only new children groups
      if (group.synced || group.id !== null) continue;

      groups.push(group.serialize());
    }

    if (groups.length === 0) return null;
    return groups;
  }

  /**
   * Prepare data for deleting children.
   *
   * @param app
   */
  public prepareDeletedChildrenData(app: Application): number[] | null {
    const ids = [];

    for (const child of app.childArchiveList) {
      // we want only children with id = persisted children
      if (child.id === null) continue;

      ids.push(child.id);
    }

    if (ids.length === 0) return null;
    return ids;
  }

  /**
   * Prepare data for deleting children groups.
   *
   * @param app
   */
  public prepareDeletedChildrenGroupsData(app: Application): number[] | null {
    const ids = [];

    for (const group of app.childrenGroupArchive) {
      // we want only children groups with id = persisted groups
      if (group.id === null) continue;

      ids.push(group.id);
    }

    if (ids.length === 0) return null;
    return ids;
  }

  /**
   * Prepare data for uploading new children.
   *
   * @param app
   */
  public prepareUpdatedChildrenData(app: Application): IChildData[] | null {
    const children = [];

    for (const child of app.childList) {
      // we want only children for update
      if (child.synced || child.id === null) continue;

      children.push(child.serialize());
    }

    if (children.length === 0) return null;
    return children;
  }

  /**
   * Prepare data for updating children groups.
   *
   * @param app
   */
  public prepareUpdatedChildrenGroupsData(app: Application): IGroupData[] | null {
    const groups = [];

    for (const group of app.childrenGroupList) {
      // we want only group for update
      if (group.synced || group.id === null) continue;

      groups.push(group.serialize());
    }

    if (groups.length === 0) return null;
    return null;
  }

  /**
   * Prepare data for updating children in groups.
   *
   * @param app
   */
  public prepareChildrenInGroupsData(app: Application): IChildrenInGroupData[] | null {
    const data = [];

    for (const group of app.childrenGroupList) {
      // we want only group without synced children
      if (group.childrenSynced) continue;

      data.push({
        group_uuid: group.uuid,
        children: group.children.map((ch) => ch.uuid)
      });
    }

    if (data.length === 0) return null;
    return data;
  }

  /**
   * Prepare data for deleting completed tests data.
   *
   * @param app
   */
  public prepareDeletedTestData(app: Application): number[] | null {
    const ids = [];

    for (const dataSet of app.dataSetArchive) {
      ids.push(dataSet.id);
    }

    if (ids.length === 0) return null;
    return ids;
  }

  /**
   * Prepare data for uploading new data sets (=test results).
   *
   * @param app
   */
  public prepareNewTestData(app: Application): IDataSetData[] | null {
    const dataSets = [];

    for (const dataSet of app.dataSets) {
      if (dataSet.synced) continue;
      dataSets.push(dataSet.serialize());
    }

    if (dataSets.length === 0) return null;
    return dataSets;
  }

  /**
   * Process response of uploaded new children.
   *
   * @param app
   * @param data
   */
  public processNewChildrenResponse(app: Application, data: IVersionedIdentityData[] | undefined): void {
    if (!data) return;

    for (const item of data) {
      const child = app.getChild(item.uuid);
      if (child) {
        child.setId(item.id);
        child.revision = item.revision;
        child.synced = true;
      }
    }
  }

  /**
   * Process response of uploaded new children groups.
   *
   * @param app
   * @param data
   */
  public processNewChildrenGroupsResponse(app: Application, data: IVersionedIdentityData[] | undefined): void {
    if (!data) return;

    for (const item of data) {
      const group = app.getChildrenGroup(item.uuid);
      if (group) {
        group.setId(item.id);
        group.synced = true;
        group.revision = item.revision;
      }
    }
  }

  /**
   * Process response of updated children.
   *
   * @param app
   * @param data
   */
  public processUpdatedChildrenResponse(app: Application, data: IVersionedIdentityData[] | undefined): void {
    if (!data) return;

    for (const item of data) {
      const child = app.getChild(item.uuid);
      if (child) {
        child.synced = true;
        child.revision = item.revision;
      }
    }
  }

  /**
   * Process response of updated children groups.
   *
   * @param app
   * @param data
   */
  public processUpdatedChildrenGroupsResponse(app: Application, data: IVersionedIdentityData[] | undefined): void {
    if (!data) return;

    for (const item of data) {
      const group = app.getChildrenGroup(item.uuid);
      if (group) {
        group.synced = true;
        group.revision = item.revision;
      }
    }
  }

  /**
   * Process response of deleted test data.
   *
   * @param app
   */
  public processDeletedTestsResponse(app: Application): void {
    // clear archive, all data was deleted in the server
    app.dataSetArchive.length = 0;
  }

  /**
   * Process response of deleted children.
   *
   * @param app
   */
  public processDeletedChildrenResponse(app: Application): void {
    // clear archive, all data was deleted in the server
    app.childArchiveList.length = 0;
  }

  /**
   * Process response of deleted children groups.
   *
   * @param app
   */
  public processDeletedChildrenGroupsResponse(app: Application): void {
    // clear archive, all data was deleted in the server
    app.childrenGroupArchive.length = 0;
  }

  /**
   * Process response of uploaded test data.
   *
   * @param app
   * @param data
   */
  public processNewTestDataResponse(app: Application, data: IIdentityData[] | undefined): void {
    if (data == null) return;

    for (const item of data) {
      const dataSet = app.getDataSet(item.uuid);
      if (dataSet) {
        dataSet.id = item.id;
        dataSet.synced = true;
      }
    }
  }

  /**
   * Prepare all changed data for upload to server.
   *
   * @param app
   */
  public prepareAllData(app: Application): IAppUploadData {
    const appData: IAppUploadData = {
      new_children: this.prepareNewChildrenData(app),
      children: this.prepareUpdatedChildrenData(app),
      delete_children: this.prepareDeletedChildrenData(app),
      new_children_groups: this.prepareNewChildrenGroupsData(app),
      children_groups: this.prepareUpdatedChildrenGroupsData(app),
      delete_children_groups: this.prepareDeletedChildrenGroupsData(app),
      children_in_groups: this.prepareChildrenInGroupsData(app),
      data_sets: this.prepareNewTestData(app),
      delete_data_sets: this.prepareDeletedTestData(app)
    };

    for (const dataKey of Object.keys(appData)) {
      if (appData[dataKey] === null) delete appData[dataKey];
    }

    if (Object.keys(appData).length === 0) return null;
    return appData;
  }

  /**
   * Process server response of uploaded data.
   *
   * @param app
   * @param data
   */
  public processUploadResponse(app: Application, data: IAppUploadResponseData): void {
    if (data == null) return;

    this.processNewChildrenResponse(app, data.new_children);
    this.processUpdatedChildrenResponse(app, data.updated_children);
    this.processNewChildrenGroupsResponse(app, data.new_children_groups);
    this.processUpdatedChildrenGroupsResponse(app, data.updated_children_groups);
    this.processNewTestDataResponse(app, data.data_sets);
    this.processDeletedChildrenResponse(app);
    this.processDeletedChildrenGroupsResponse(app);
    this.processDeletedTestsResponse(app);
  }
}
