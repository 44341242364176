import { Component, HostListener } from '@angular/core';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { ApplicationRepository } from '@app/modules/application/services/application-repository.service';
import { SyncService } from '@app/modules/sync/services/sync.service';

@Component({
  selector: 'app-user-sync',
  templateUrl: './user-sync.component.html'
})
export class UserSyncComponent {
  public modalHeadline = 'Synchronizovat data';

  public modalSuccessMsg = 'Data byla úspěšně odeslána.';

  public modalErrorMsg = 'Data se nepodařilo odeslat na server. Prosím, zkontrolujte připojení na internet a zkuste to znovu.';

  public constructor(
    private syncService: SyncService,
    private appRepository: ApplicationRepository,
    private applicationService: ApplicationService
  ) {}

  /**
   * Upload application data to server.
   * If access token is missing or invalid, it tries to refresh it.
   */
  @HostListener('click')
  public sync(): void {
    const successCallback = () => {
      this.appRepository.persistApp(this.applicationService.getApplication());
    };
    this.syncService.userSync(this.modalHeadline, this.modalSuccessMsg, this.modalErrorMsg, successCallback);
  }
}
