import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { InstructionType } from '@app/modules/test/enums/instruction-type.enum';
import { NoteQuestionType } from '@app/modules/test/enums/note-question-type.enum';
import { NoteSectionType } from '@app/modules/test/enums/note-section-type.enum';
import { TaskItemType } from '@app/modules/test/enums/task-item-type.enum';
import { TestEvaluationType } from '@app/modules/test/enums/test-evaluation-type.enum';
import { TestModuleType } from '@app/modules/test/enums/test-module-type.enum';
import { Category } from '@app/modules/test/models/general/category.model';
import { Instruction } from '@app/modules/test/models/general/instruction.model';
import { Material } from '@app/modules/test/models/general/material.model';
import { TextInstruction } from '@app/modules/test/models/general/text-instruction.model';
import { BoolQuestion } from '@app/modules/test/models/note/bool-question.model';
import { NoteSection } from '@app/modules/test/models/note/note-section.model';
import { Question } from '@app/modules/test/models/note/question.model';
import { QuestionNoteSection } from '@app/modules/test/models/note/question-note-section.model';
import { QuestionSubSection } from '@app/modules/test/models/note/question-sub-section.model';
import { SelectOption } from '@app/modules/test/models/note/select-option.model';
import { SelectQuestion } from '@app/modules/test/models/note/select-question.model';
import { TextNoteSection } from '@app/modules/test/models/note/text-note-section.model';
import { TextQuestion } from '@app/modules/test/models/note/text-question.model';
import { BasicSubTask } from '@app/modules/test/models/task/basic-subtask.model';
import { BasicSubTaskGroup } from '@app/modules/test/models/task/basic-subtask-group.model';
import { BasicTaskItem } from '@app/modules/test/models/task/basic-task-item.model';
import { Point } from '@app/modules/test/models/task/point.model';
import { TaskItem } from '@app/modules/test/models/task/task-item.model';
import { NoteTestModule } from '@app/modules/test/models/test/note-test-module.model';
import { ResultTestModule } from '@app/modules/test/models/test/result-test-module.model';
import { TaskTestModule } from '@app/modules/test/models/test/task-test-module.model';
import { Test } from '@app/modules/test/models/test/test.model';
import { TestModule } from '@app/modules/test/models/test/test-module.model';
import { BasicEvaluationCategoryRule } from '@app/modules/test/models/test-evaluation/basic/basic-evaluation-category-rule.model';
import { BasicEvaluationRange } from '@app/modules/test/models/test-evaluation/basic/basic-evaluation-range.model';
import { BasicEvaluationRule } from '@app/modules/test/models/test-evaluation/basic/basic-evaluation-rule.model';
import { BasicTestEvaluation } from '@app/modules/test/models/test-evaluation/basic/basic-test-evaluation.model';
import { TestEvaluation } from '@app/modules/test/models/test-evaluation/test-evaluation.model';
import {
  IAnyModuleData,
  IEvaluationRuleData,
  INoteModuleData,
  INoteQuestionData,
  INoteSectionWrapperData,
  IResultModuleData,
  ITaskModuleData,
  ITestAllData,
  ITestData,
  ITestEvaluationData,
  ITestItemWrapperData,
  ITestTaskInstructionData
} from '@app/modules/test/types';

/**
 * Deserialize whole Test entity and all dependencies.
 */
@Injectable({
  providedIn: 'root'
})
export class TestDeserializer {
  protected categories: Map<number, Category> = new Map<number, Category>();

  protected materials: Map<number, Material> = new Map<number, Material>();

  protected test: Test = null;

  /**
   * It will deserialize Test entity.
   */
  public deserialize(app: Application, data: ITestAllData): Test {
    this.clear();

    this.loadCategories(app, data.categories);
    this.loadMaterials(app, data.materials);
    this.deserializeTest(data.test);
    return this.test;
  }

  /**
   * Deserialize categories.
   *
   * @param app
   * @param data
   */
  protected loadCategories(app: Application, data: number[]): void {
    for (const categoryId of data) {
      this.categories.set(categoryId, app.getCategory(categoryId, true));
    }
  }

  /**
   * Deserialize materials.
   *
   * @param app
   * @param data
   */
  protected loadMaterials(app: Application, data: number[]): void {
    for (const materialId of data) {
      this.materials.set(materialId, app.getMaterial(materialId));
    }
  }

  /**
   * Deserialize test.
   *
   * @param data
   */
  protected deserializeTest(data: ITestData): void {
    const testEvaluation = this.deserializeTestEvaluation(data.test_evaluation);
    this.test = Test.deserialize(data, testEvaluation, Array.from(this.categories.values()), Array.from(this.materials.values()));
    this.deserializeTestModules(data.modules);
  }

  /**
   * Deserialize test evaluation.
   *
   * @return TestEvaluation
   */
  protected deserializeTestEvaluation(evaluationData: ITestEvaluationData): TestEvaluation {
    if (evaluationData.evaluation_type === TestEvaluationType.BASIC) {
      return this.deserializeBasicTestEvaluation(evaluationData);
    } else {
      throw new Error('Unknown test evaluation "' + evaluationData.evaluation_type + '".');
    }
  }

  /**
   * Deserialize basic test evaluation.
   *
   * @param evaluationData
   * @return BasicTestEvaluation
   */
  protected deserializeBasicTestEvaluation(evaluationData: ITestEvaluationData): BasicTestEvaluation {
    const generalRule = this.deserializeBasicEvaluationRule(evaluationData.general_rule);
    const testRule = this.deserializeBasicEvaluationRule(evaluationData.test_rule);
    const evaluation = BasicTestEvaluation.deserialize(evaluationData, testRule, generalRule);

    for (const categoryRuleData of evaluationData.category_rules) {
      const categoryRule = this.deserializeBasicEvaluationCategoryRule(categoryRuleData);
      if (categoryRule === null) continue;
      evaluation.addCategoryRule(categoryRule);
    }

    return evaluation;
  }

  /**
   * Deserialize basic evaluation category rule.
   *
   * @param ruleData
   * @return BasicEvaluationCategoryRule
   */
  protected deserializeBasicEvaluationCategoryRule(ruleData: IEvaluationRuleData): BasicEvaluationCategoryRule | null {
    if (ruleData === null) return null;

    const rule = BasicEvaluationCategoryRule.deserialize(ruleData, this.getCategory(ruleData.category_id));

    for (const rangeData of ruleData.ranges) {
      const range = BasicEvaluationRange.deserialize(rangeData);
      rule.addRange(range);
    }

    return rule;
  }

  /**
   * Deserialize basic evaluation rule.
   *
   * @param ruleData
   * @return BasicEvaluationRule
   */
  protected deserializeBasicEvaluationRule(ruleData: IEvaluationRuleData): BasicEvaluationRule | null {
    if (ruleData === null) return null;

    const rule = BasicEvaluationRule.deserialize(ruleData);

    for (const rangeData of ruleData.ranges) {
      const range = BasicEvaluationRange.deserialize(rangeData);
      rule.addRange(range);
    }

    return rule;
  }

  /**
   * Deserialize all test modules.
   *
   * @param modulesData
   */
  protected deserializeTestModules(modulesData: IAnyModuleData[]): void {
    for (const moduleData of modulesData) {
      const module = this.deserializeTestModule(moduleData);
      this.test.addModule(module);
    }
  }

  /**
   * Deserialize one test module.
   *
   * @param moduleData
   */
  protected deserializeTestModule(moduleData: IAnyModuleData): TestModule {
    if (moduleData.module_type === TestModuleType.TASK) {
      return this.deserializeTaskTestModule(moduleData as ITaskModuleData);
    } else if (moduleData.module_type === TestModuleType.RESULT) {
      return this.deserializeResultTestModule(moduleData as IResultModuleData);
    } else if (moduleData.module_type === TestModuleType.NOTE) {
      return this.deserializeNoteTestModule(moduleData as INoteModuleData);
    } else {
      throw new Error('Unknown test module "' + moduleData.module_type + '".');
    }
  }

  /**
   * Deserialize test module of task type.
   *
   * @param moduleData
   */
  protected deserializeTaskTestModule(moduleData: ITaskModuleData): TaskTestModule {
    const taskTestModule = TaskTestModule.deserialize(moduleData, this.test);

    for (const testItem of moduleData.test_items) {
      const taskItem = this.deserializeTaskItem(testItem, taskTestModule);
      taskTestModule.addItem(taskItem);
    }

    return taskTestModule;
  }

  /**
   * Deserialize test module of note type.
   *
   * @param moduleData
   */
  protected deserializeNoteTestModule(moduleData: INoteModuleData): NoteTestModule {
    const noteTestModule = NoteTestModule.deserialize(moduleData, this.test);

    for (const testSection of moduleData.test_sections) {
      const noteSection = this.deserializeNoteSection(testSection, noteTestModule);
      noteTestModule.addSection(noteSection);
    }

    return noteTestModule;
  }

  /**
   * Deserialize test note section.
   *
   * @param testSectionData
   * @param noteTestModule
   */
  protected deserializeNoteSection(testSectionData: INoteSectionWrapperData, noteTestModule: NoteTestModule): NoteSection {
    if (testSectionData.section.section_type === NoteSectionType.QUESTION) {
      return this.deserializeQuestionNoteSection(testSectionData, noteTestModule);
    } else if (testSectionData.section.section_type === NoteSectionType.TEXT) {
      return this.deserializeTextNoteSection(testSectionData, noteTestModule);
    } else {
      throw new Error('Unknown test note section "' + testSectionData.section.section_type + '".');
    }
  }

  /**
   * Deserialize test note section of type text.
   *
   * @param testSectionData
   * @param noteTestModule
   */
  protected deserializeTextNoteSection(testSectionData: INoteSectionWrapperData, noteTestModule: NoteTestModule): TextNoteSection {
    // Every TextNoteSection is TestStep
    this.test.stepCount += 1;
    return TextNoteSection.deserialize(
      testSectionData.section,
      noteTestModule,
      this.getCategory(testSectionData.section.category_id),
      +testSectionData.order,
      testSectionData.id
    );
  }

  /**
   * Deserialize test note section of type question.
   *
   * @param testSectionData
   * @param noteTestModule
   */
  protected deserializeQuestionNoteSection(testSectionData: INoteSectionWrapperData, noteTestModule: NoteTestModule): QuestionNoteSection {
    const sectionCategory = this.getCategory(testSectionData.section.category_id);
    const sectionData = testSectionData.section;
    const questionNoteSection = QuestionNoteSection.deserialize(
      sectionData,
      noteTestModule,
      sectionCategory,
      +testSectionData.order,
      testSectionData.id
    );
    // Every QuestionNoteSection is TestStep
    this.test.stepCount += 1;

    for (const subSectionData of testSectionData.section.sub_sections) {
      const subSection = QuestionSubSection.deserialize(subSectionData, questionNoteSection);
      questionNoteSection.addSubSection(subSection);

      for (const questionData of subSectionData.questions) {
        subSection.addQuestion(this.deserializeNoteQuestion(questionData, subSection));
      }
    }

    return questionNoteSection;
  }

  /**
   * Deserialize note question.
   *
   * @param questionData
   * @param subSection
   */
  protected deserializeNoteQuestion(questionData: INoteQuestionData, subSection: QuestionSubSection): Question {
    if (questionData.question_type === NoteQuestionType.BOOL) {
      return BoolQuestion.deserialize(questionData, subSection);
    } else if (questionData.question_type === NoteQuestionType.SELECT) {
      const selectQuestion = SelectQuestion.deserialize(questionData, subSection);

      for (const optionData of questionData.options) {
        selectQuestion.addOption(SelectOption.deserialize(optionData, selectQuestion));
      }

      return selectQuestion;
    } else if (questionData.question_type === NoteQuestionType.TEXT) {
      return TextQuestion.deserialize(questionData, subSection);
    } else {
      throw new Error('Unknown note question type "' + questionData.question_type + '".');
    }
  }

  /**
   * Deserialize test module of score type.
   *
   * @param moduleData
   */
  protected deserializeResultTestModule(moduleData: IResultModuleData): ResultTestModule {
    // Every ResultTestModule is TestStep
    this.test.stepCount += 1;
    return ResultTestModule.deserialize(moduleData, this.test);
  }

  /**
   * Deserialize one task item of task test module.
   *
   * @param testItemData
   * @param taskTestModule
   */
  protected deserializeTaskItem(testItemData: ITestItemWrapperData, taskTestModule: TaskTestModule): TaskItem {
    if (testItemData.item.item_type === TaskItemType.BASIC_TASK) {
      return this.deserializeBasicTaskItem(testItemData, taskTestModule);
    } else {
      throw new Error('Unknown task item "' + testItemData.item.item_type + '".');
    }
  }

  /**
   * Deserialize one basic task item of task test module.
   *
   * @param testItemData
   * @param taskTestModule
   */
  protected deserializeBasicTaskItem(testItemData: ITestItemWrapperData, taskTestModule: TaskTestModule): BasicTaskItem {
    const itemData = testItemData.item;
    const taskCategory = this.getCategory(testItemData.category_id);
    const basicTaskItem = BasicTaskItem.deserialize(
      itemData,
      testItemData.id,
      taskTestModule,
      taskCategory,
      this.deserializeInstruction(itemData.instruction),
      this.getMaterialsById(itemData.material_ids),
      +testItemData.order
    );
    taskCategory.addTask(basicTaskItem);

    for (const subTaskGroupData of itemData.sub_task_groups) {
      const subTaskGroup = BasicSubTaskGroup.deserialize(
        subTaskGroupData,
        basicTaskItem,
        this.deserializeInstruction(subTaskGroupData.instruction),
        this.getMaterialsById(subTaskGroupData.material_ids)
      );
      // Every BasicSubTaskGroup is TestStep
      this.test.stepCount += 1;

      // Deserialize points for sub-task group:
      for (const pointData of subTaskGroupData.points) {
        const point = Point.deserialize(pointData);
        subTaskGroup.addPoint(point);
      }

      // Deserialize sub-task for sub-task group:
      for (const subTaskData of subTaskGroupData.sub_tasks) {
        const subTask = BasicSubTask.deserialize(subTaskData, subTaskGroup);
        subTaskGroup.addSubTask(subTask);
      }

      basicTaskItem.addSubTaskGroup(subTaskGroup);
    }

    return basicTaskItem;
  }

  /**
   * Deserialize instruction.
   *
   * @param instructionData
   */
  protected deserializeInstruction(instructionData: ITestTaskInstructionData | null): Instruction | null {
    if (!instructionData) return null;

    if (instructionData.instruction_type === InstructionType.TEXT) {
      return TextInstruction.deserialize(instructionData);
    } else {
      throw new Error('Unknown instruction type "' + instructionData.instruction_type + '"');
    }
  }

  /* === Helper methods: === */

  /**
   * Return materials by ID or null.
   *
   * @param materialIds
   */
  protected getMaterialsById(materialIds: Array<number> | null): Material[] | null {
    if (materialIds === null) return null;

    return materialIds.map((id) => this.getMaterial(id));
  }

  /**
   * Return category by ID or null.
   *
   * @param id
   */
  protected getCategory(id: number | null): Category | null {
    if (id === null) return null;

    const category = this.categories.get(id);
    return category === undefined ? null : category;
  }

  /**
   * Return material by ID or null.
   *
   * @param id
   */
  protected getMaterial(id: number | null): Material | null {
    if (id === null) return null;

    const material = this.materials.get(id);
    return material === undefined ? null : material;
  }

  /**
   * Clear all deserializer data for new deserialization.
   */
  protected clear(): void {
    this.categories.clear();
    this.materials.clear();
    this.test = null;
  }
}
