import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { PopUpType } from '../../../../enums/pop-up-type.enum';

@Component({
  selector: 'app-pop-up-dialog',
  templateUrl: './pop-up-dialog.component.html',
  styleUrls: ['./pop-up-dialog.component.scss']
})
export class PopUpDialogComponent implements OnInit {
  public popUpType: typeof PopUpType;

  public inputValue = '';

  public selectValue: any | null = null;

  public inputValid = true;

  public data;

  public modalReference: NgbModalRef;

  public constructor() {
    this.popUpType = PopUpType;
  }

  public ngOnInit(): void {
    if (Object.prototype.hasOwnProperty.call(this.data, 'defaultValue')) {
      this.inputValue = this.data.defaultValue;
    }

    if (this.data.popUpType === PopUpType.SELECT) {
      this.selectValue = this.data.options[0][0];
    }
  }

  public dismissModal(): void {
    this.modalReference.dismiss();
  }

  public closeModal(result: boolean): void {
    this.modalReference.close(result);
  }

  public okPromptButton(): void {
    if (this.data.popUpType === PopUpType.PROMPT) {
      this.processInput();
    } else if (this.data.popUpType === PopUpType.SELECT) {
      this.processSelect();
    } else {
      throw new Error(`Unknown pop up type: ${this.popUpType}`);
    }
  }

  protected processInput(): void {
    const validationRegex = this.data.validationRegex;

    if (validationRegex !== null && this.inputValue.match(validationRegex) === null) {
      this.inputValid = false;
      return;
    }

    this.modalReference.close(this.inputValue);
  }

  protected processSelect(): void {
    this.modalReference.close(this.selectValue);
  }
}
