import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { StorageFactory } from '@app/modules/application/services/storage-factory.service';
import { UploadWorkerService } from '@app/modules/sync/services/upload-worker.service';
import { environment } from '@env/environment';
import { HttpService } from 'isophi-core';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  protected application: Application | null = null;

  protected accessToken: null | string = null;

  public constructor(
    protected httpService: HttpService,
    protected uploadWorker: UploadWorkerService,
    protected appService: ApplicationService,
    protected storageFactory: StorageFactory
  ) {}

  /**
   * Upload all not-synced data into server.
   *
   * @param syncUuid
   */
  public upload(syncUuid: string): Promise<void> {
    const app = this.appService.application;
    const appData = this.uploadWorker.prepareAllData(app);
    const url = this.getSyncUrl(syncUuid, 'full-upload');

    if (!appData) return Promise.resolve();
    const postData = new Map<string, any>(Object.entries(appData));

    return this.httpService
      .post(url, postData, app.accessToken)
      .toPromise()
      .then((response) => this.uploadWorker.processUploadResponse(app, response.body))
      .then(() => app.removeDataSetsWithoutChild());
  }

  /**
   * Upload all app data in raw form (serialized as text) into server.
   */
  public uploadRawData(): Promise<void> {
    const url = `${environment.dadAPI}/data-dumps/`;
    const postParams = new Map<string, string | number>();
    const storage = this.storageFactory.getStorage();
    postParams.set('data', storage.getItem(Application.STORAGE_KEY));
    postParams.set('kindergarten_id', this.appService.getApplication().loggedKindergarten.id);

    return this.httpService
      .post(url, postParams)
      .toPromise()
      .then(() => null);
  }

  /**
   * Return DAD portal URL for sync endpoint.
   *
   * @param syncUuid
   * @param endpoint
   */
  protected getSyncUrl(syncUuid: string, endpoint: string): string {
    return `${environment.dadAPI}/sync/${syncUuid}/${endpoint}/`;
  }
}
