import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  /**
   * Return URL converted for specific deployment (web, cordova, ...)
   *
   * @param url
   */
  public convertURL(url: string): string {
    // todo(doubravskytomas): refactor for multiple deployment. Now working only for web.

    // todo(doubravskytomas): TEMP; Load icons from JS app and not from server.
    if (url.startsWith('/media/uploaded/tags/inactive/category/')) {
      const iconName = url.substring(39);
      return `assets/img/iconsDefault/${iconName}`;
    }

    // todo(doubravskytomas): TEMP; Load icons from JS app and not from server.
    if (url.startsWith('/media/uploaded/tags/active/category/')) {
      const iconName = url.substring(37);
      return `assets/img/iconsColor/${iconName}`;
    }

    // todo(doubravskytomas): TEMP; Load previews from JS app and not from server.
    if (url.startsWith('/media/uploaded/materials/preview/')) {
      const previewName = url.substring(34);
      return `assets/img/materials/preview/${previewName}`;
    }

    // todo(doubravskytomas): TEMP; Load previews from JS app and not from server.
    if (url.startsWith('/media/uploaded/tests/notes/options/')) {
      const previewName = url.substring(36);
      return `assets/img/notes/${previewName}`;
    }

    // todo(doubravskytomas): TEMP; Load previews from JS app and not from server.
    if (url.startsWith('/media/uploaded/tests/tasks/')) {
      const previewName = url.substring(28);
      return `assets/img/tests/tasks/${previewName}`;
    }

    const convertedUrl = environment.materialServer + url;
    return convertedUrl;
  }

  /**
   * Reformat URL in image src for text
   *
   * @param text
   */
  public reformatURL(text: string): string {
    const newText = text.replace(/(href|src)=(\\?['"])([^'"]+)(\\?['"])/gi, (_, attrName, preDel, url, postDel) => {
      const newUrl = this.convertURL(url);
      return `${attrName}=${preDel}${newUrl}${postDel}`;
    });
    return newText;
  }
}
