import { Component } from '@angular/core';
import { AlertType } from '@app/shared/enums/alert-type.enum';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { PopUpType } from '../../enums/pop-up-type.enum';
import { PopUpDialogComponent } from './components/pop-up-dialog/pop-up-dialog.component';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent {
  public constructor(private modalService: NgbModal) {}

  /**
   * Open modal component (PopUpDialogComponent).
   *
   * ! Any in returns come from select, need improve.
   *
   * @param title
   * @param text
   * @param popUpType
   * @param alertType
   * @param data
   */
  public openDialog(
    title: string,
    text: string,
    popUpType: string,
    alertType: AlertType | null = null,
    data: Record<string, unknown> | null = null
  ): Promise<boolean | null | string | any> {
    if (data === null) {
      data = {};
    }
    const defaultData = {
      alertType,
      title,
      text,
      popUpType
    };
    data = { ...defaultData, ...data };

    const modal: NgbModalRef = this.modalService.open(PopUpDialogComponent);
    modal.componentInstance.data = data;
    modal.componentInstance.modalReference = modal;

    return modal.result.catch(() => null);
  }

  public confirm(
    title: string,
    text: string,
    alertType: AlertType | null = null,
    yesText: string = 'Ano',
    noText: string = 'Ne',
    yesButtonClass: string = 'ibtn-primary',
    noButtonClass: string = 'ibtn-secondary'
  ): Promise<boolean | null> {
    const data = { yesText, noText, yesButtonClass, noButtonClass };
    return this.openDialog(title, text, PopUpType.CONFIRM, alertType, data) as Promise<boolean | null>;
  }

  public alert(title: string, text: string, alertType: AlertType | null = null, okText: string = 'Ok'): Promise<boolean | null> {
    return this.openDialog(title, text, PopUpType.ALERT, alertType, { okText }) as Promise<boolean | null>;
  }

  public input(
    title: string,
    label: string,
    placeholder: string,
    defaultValue: string | null = null,
    validationRegex: string | null = null,
    validationMessage: string | null = null,
    okText: string = 'Ok',
    cancelText: string = 'Zrušit',
    okButtonClass: string = 'ibtn-primary',
    cancelButtonClass: string = 'ibtn-secondary'
  ): Promise<string | null> {
    const data = {
      okText,
      cancelText,
      validationRegex,
      placeholder,
      validationMessage,
      defaultValue,
      okButtonClass,
      cancelButtonClass
    };
    return this.openDialog(title, label, PopUpType.PROMPT, null, data) as Promise<string | null>;
  }

  /**
   *
   * @param title
   * @param label
   * @param options ... Options to select in format [[key, value], [key2, value2], ...]
   * @param okText
   * @param cancelText
   * @param okButtonClass
   * @param cancelButtonClass
   */
  public select(
    title: string,
    label: string,
    options: Array<Array<any>>,
    okText: string = 'Ok',
    cancelText: string = 'Zrušit',
    okButtonClass: string = 'ibtn-primary',
    cancelButtonClass: string = 'ibtn-secondary'
  ): Promise<any | null> {
    const data = { okText, cancelText, options, okButtonClass, cancelButtonClass };
    return this.openDialog(title, label, PopUpType.SELECT, null, data) as Promise<any | null>;
  }
}
