/**
 * Convert DataItem (items of DateStorage) values.
 */
export class DataItemValueConverter {
  public toValue(dataType: string, value: string): string | number | boolean {
    if (dataType === 'note$bool_question') {
      return value === 'true';
    }
    if (dataType === 'task$basic_sub_task' || dataType === 'note$option_question') {
      return +value;
    }

    return value;
  }

  public fromValue(dataType: string, value: string | number | boolean): string {
    if (dataType === 'note$bool_question') {
      return value ? 'true' : 'false';
    }
    return '' + value; // convert to string
  }
}
