import { Group } from '@app/modules/children/models/group.model';
import { DataSetStatus } from '@app/modules/test/enums/data-set-status.enum';
import { DataSet } from '@app/modules/test/models/test/data-set.model';
import { Child as BaseChild, DateHelper } from 'isophi-core';
import { IChildData } from 'isophi-core';

export class Child extends BaseChild {
  public dataSets: Array<DataSet> = [];

  public groups: Array<Group> = [];

  /**
   * Return data sets for completed tests.
   */
  public get completedTests(): Array<DataSet> {
    return this.dataSets.filter((ds) => ds.status === DataSetStatus.COMPLETED);
  }

  /**
   * Return data sets for uncompleted tests.
   */
  public get uncompletedTests(): Array<DataSet> {
    return this.dataSets.filter((ds) => ds.status === DataSetStatus.UNCOMPLETED && ds.test !== undefined);
  }

  /**
   * Create new Child with ID.
   *
   * Used this method when deserializing data from server.
   *
   * @param id
   * @param uuid
   * @param firstName
   * @param lastName
   * @param gender
   * @param birthDate
   * @param revision
   */
  public static createWithId(
    id: number,
    uuid: string,
    firstName: string,
    lastName: string,
    gender: string,
    birthDate: Date,
    revision = 0
  ): Child {
    const child = Child.create(uuid, firstName, lastName, gender, birthDate, revision);
    child.id = id;
    return child;
  }

  /**
   * Create new Child without ID.
   *
   * Used this method when creating Child in the DAD app.
   *
   * @param uuid
   * @param firstName
   * @param lastName
   * @param gender
   * @param birthDate
   * @param revision
   */
  public static create(uuid: string, firstName: string, lastName: string, gender: string, birthDate: Date, revision = 0): Child {
    const child = new Child();
    child.uuid = uuid;
    child.name = firstName;
    child.surname = lastName;
    child.gender = gender;
    child.birthDate = birthDate;
    child.birthDate.setHours(0, 0, 0, 0); // Ensure time set to zero.
    child.revision = revision;
    return child;
  }

  /**
   * Deserializer child data into new Child.
   *
   * @param data
   */
  public static deserialize(data: IChildData): Child {
    return Child.createWithId(
      data.id,
      data.uuid,
      data.first_name,
      data.last_name,
      data.gender,
      DateHelper.parse(data.birth_date),
      data.revision
    );
  }

  /**
   * Add data set with test data of this child.
   * It prevents to insert duplication.
   *
   * @param dataSet
   */
  public addDataSet(dataSet: DataSet): void {
    const index = this.dataSets.findIndex((x) => x.uuid === dataSet.uuid);
    if (index !== -1) return;

    // Keep the array sorted DESC (the most recent datasets at the start)
    let i = 0;
    for (; i < this.dataSets.length; i++) {
      if (this.dataSets[i].finishedAt < dataSet.finishedAt) break;
    }

    this.dataSets.splice(i, 0, dataSet);
  }

  /**
   * Add group to this child.
   *
   * @param group
   */
  public addGroup(group: Group): void {
    const index: number = this.groups.findIndex((g) => g.uuid === group.uuid);
    if (index === -1) this.groups.push(group);
  }

  /**
   * Remove data set with test data from this child.
   *
   * @param dataSet
   */
  public removeDataSet(dataSet: DataSet): void {
    const index: number = this.dataSets.indexOf(dataSet);
    if (index !== -1) this.dataSets.splice(index, 1);
  }

  /**
   * Remove group from this child.
   *
   * @param group
   */
  public removeGroup(group: Group): void {
    const index: number = this.groups.findIndex((g) => g.uuid === group.uuid);
    if (index !== -1) this.groups.splice(index, 1);
  }

  /**
   * Set new ID to Child and all associated entities (tests).
   *
   * @param id
   */
  public setId(id: number): void {
    this.id = id;

    for (const test of this.completedTests) {
      test.childId = id;
    }
    for (const test of this.uncompletedTests) {
      test.childId = id;
    }
  }

  /**
   * @return True/False if data are already synced to server
   */
  public isSyncedToServer(): boolean {
    return this.id !== null;
  }
}
