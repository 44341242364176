<div id="simple-header-row" class="row" *transloco="let t">
  <div class="space"></div>

  <div class="user-menu" *ngIf="showUserMenu">
    <app-language-picker></app-language-picker>

    <img src="assets/img/iconsDefault/school.svg" class="kindergarten-icon" alt="{{ t('shared/kindergarten') }}" />

    <div ngbDropdown placement="bottom-left" class="d-inline-block kindergarten-name mr-5">
      <h5 ngbDropdownToggle>
        <ng-container *ngIf="kindergarten">{{ kindergarten.name }}</ng-container>
      </h5>
      <div ngbDropdownMenu aria-labelledby="dropdown" display="static">
        <app-user-sync
          ><h5 class="dropdown-item">{{ t('shared/synchronizeData') }}</h5></app-user-sync
        >
        <h5 class="dropdown-item" (click)="sendRawData()">{{ t('shared/reportIssue') }}</h5>
        <h5 class="dropdown-item last-item" (click)="userLogout()">{{ t('shared/signOut') }}</h5>
      </div>
    </div>
  </div>

  <img
    *ngIf="showToggleMenuButton"
    src="assets/img/iconsDefault/menu.svg"
    class="menu"
    (click)="toggleSidenav()"
    alt="{{ t('shared/menu') }}"
  />
</div>
