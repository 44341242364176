import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { IChildrenInGroupData, IGroupData } from '@app/modules/children/types';
import { IMaterialData } from '@app/modules/sync/types';
import { IDataSetData, ITestAllData } from '@app/modules/test/types';
import { environment } from '@env/environment';
import { ICategoryData, IChildData, IUserData } from 'isophi-core';

@Injectable({
  providedIn: 'root'
})
export class ApplicationSerializer {
  protected app: Application = null;

  public serialize(app: Application): string {
    this.app = app;
    const serializedApp = {} as any;

    serializedApp.licenses = this.app.licensesData;
    serializedApp.categories = { results: this.serializeCategories() };
    serializedApp.materials = { results: this.serializeMaterials() };
    serializedApp.tests = { results: this.serializeTests() };
    serializedApp.users = { results: this.serializeUsers() };
    serializedApp.files = { results: this.serializeFiles() };
    serializedApp.children = { results: this.serializeChildren() };
    serializedApp.children_groups = { results: this.serializeChildrenGroups() };
    serializedApp.children_in_groups = { results: this.serializeChildrenInGroups() };
    serializedApp.data_sets = { results: this.serializeDataSets() };
    serializedApp.data_sets_archive = { results: this.serializeDataSetsArchive() };
    serializedApp.child_archive = { results: this.serializeChildArchive() };
    serializedApp.children_group_archive = { results: this.serializeChildrenGroupArchive() };

    // serializedApp['logged_teacher'] = null;
    serializedApp.logged_kindergarten = this.app.loggedKindergarten?.serialize();
    serializedApp.logged_user = this.app.loggedUser ? this.app.loggedUser?.serialize() : null;
    serializedApp.synced = this.app.synced;
    serializedApp.sync_uuid = this.app.syncUuid;
    serializedApp.access_token = this.app.accessToken;
    serializedApp.dad_version = environment.common.version;
    serializedApp.last_email_in_report = this.app.lastEmailInReport;

    return JSON.stringify(serializedApp);
  }

  protected serializeChildren(): IChildData[] {
    const data = [];

    for (const child of this.app.childList) {
      data.push(child.serialize());
    }

    return data;
  }

  protected serializeChildrenGroups(): IGroupData[] {
    const data = [];

    for (const childrenGroup of this.app.childrenGroupList) {
      data.push(childrenGroup.serialize());
    }

    return data;
  }

  protected serializeChildrenInGroups(): IChildrenInGroupData[] {
    const data = [];

    for (const childrenGroup of this.app.childrenGroupList) {
      data.push({
        group_uuid: childrenGroup.uuid,
        children_uuids: childrenGroup.children.map((ch) => ch.uuid),
        synced: childrenGroup.childrenSynced
      });
    }

    return data;
  }

  protected serializeUsers(): IUserData[] {
    const data = [];

    for (const user of this.app.userList) {
      data.push(user.serialize());
    }

    return data;
  }

  protected serializeDataSets(): IDataSetData[] {
    const data = [];

    for (const dataSet of this.app.dataSets) {
      data.push(dataSet.serialize());
    }

    return data;
  }

  protected serializeDataSetsArchive(): IDataSetData[] {
    const data = [];

    for (const dataSet of this.app.dataSetArchive) {
      data.push(dataSet.serialize());
    }

    return data;
  }

  protected serializeChildArchive(): IChildData[] {
    const data = [];

    for (const child of this.app.childArchiveList) {
      data.push(child.serialize());
    }

    return data;
  }

  protected serializeChildrenGroupArchive(): IGroupData[] {
    const data = [];

    for (const group of this.app.childrenGroupArchive) {
      data.push(group.serialize());
    }

    return data;
  }

  protected serializeCategories(): ICategoryData[] {
    const data = [];

    for (const category of this.app.categoryList) {
      data.push(category.serialize());
    }

    return data;
  }

  protected serializeMaterials(): IMaterialData[] {
    const data = [];

    for (const material of this.app.materialList) {
      data.push(material.serialize());
    }

    return data;
  }

  protected serializeTests(): ITestAllData[] {
    const data = [];

    for (const test of this.app.testList) {
      data.push(test.serialize());
    }

    return data;
  }

  protected serializeFiles(): string[] {
    return Array.from(this.app.fileList);
  }
}
