import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationStart, Router } from '@angular/router';
import { CoreModule } from '@app/core/core.module';
import { AuthGuardService } from '@app/core/services/auth-guard.service';
import { InitService } from '@app/modules/application/services/init.service';
import { SidebarModule } from '@app/shared/components/ng-sidebar';
import { PopUpDialogComponent } from '@app/shared/components/pop-up/components/pop-up-dialog/pop-up-dialog.component';
import { PopUpComponent } from '@app/shared/components/pop-up/pop-up.component';
import { MenuService } from '@app/shared/services/menu.service';
import { SharedModule } from '@app/shared/shared.module';
import { environment } from '@env/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular-ivy';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { IsophiCoreModule } from 'isophi-core';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { NgxSpinnerModule } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslocoRootModule } from './transloco-root.module';

registerLocaleData(localeCs, 'cs');

export const initApplication = (initService: InitService) => () => firstValueFrom(initService.initApplication());
export const STORE_NAME = 'application';

const dbConfig: DBConfig = {
  name: 'UserDB',
  version: 1,
  objectStoresMeta: [
    {
      store: STORE_NAME,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [{ name: 'dadApplication', keypath: 'id', options: { unique: true } }]
    }
  ]
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgbModule,
    CoreModule,
    TranslocoRootModule,
    SharedModule.forRoot(),
    NgxIndexedDBModule.forRoot(dbConfig),
    AppRoutingModule,
    HttpClientModule,
    IsophiCoreModule,
    BackButtonDisableModule.forRoot(),
    SidebarModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.ga_measurement_id),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    AuthGuardService,
    { provide: LOCALE_ID, useValue: 'cs' },
    PopUpComponent,
    PopUpDialogComponent,
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      multi: true,
      deps: [InitService, Sentry.TraceService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(router: Router, menuService: MenuService) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        menuService.clearContents();
      }
    });
  }
}
