import { DataStorage } from '@app/modules/test/models/data/data-storage.model';
import { TaskTestModule } from '@app/modules/test/models/test/task-test-module.model';
import { TestPassage } from '@app/modules/test/models/test/test-passage.model';
import { TestStep } from '@app/modules/test/models/test/test-step.interface';

import { Category } from '../general/category.model';

export abstract class TaskItem {
  protected constructor(
    public id: number,
    public testItemId: number,
    public uuid: string,
    public taskTestModule: TaskTestModule,
    public title: string,
    public category: Category,
    public order: number
  ) {}

  /**
   * Return order attribute, but starting from 1.
   */
  public get order1(): number {
    return this.order + 1;
  }

  /**
   * Return obtained score of this task.
   */
  public abstract get score(): number;

  /**
   * Return maximum possible score of this task.
   */
  public abstract get maxScore(): number;

  /**
   * Return true/false if task is finished.
   */
  public abstract get finished(): boolean;

  /**
   * Return task test module object serialized to basic javascript types.
   */
  public abstract serialize(): any;

  /**
   * Register test steps.
   *
   * @param testPassage
   */
  public abstract registerTestSteps(testPassage: TestPassage): void;

  /**
   * Return first test step for the task item.
   */
  public abstract getTestStep(): TestStep;

  /**
   * Clear task item data, ie. it removes all results/data from task item.
   */
  public abstract clearData(): void;

  /**
   * Restore all task item data, ie. it sets all results/data from data storage into task item.
   */
  public abstract restoreData(dataStorage: DataStorage): void;

  /**
   * Save all task item data (results, notes, ...) into data storage.
   */
  public abstract storeData(dataStorage: DataStorage): void;
}
